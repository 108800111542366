import { Button } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { getHasGate } from "shared/store/user/selectors";
import { useAppSelector } from "store";
import SouthernFeb2025MVPEmissionsReport from "./southernMvp";

import { useEffect } from "react";
import { tracker, TrackEventNames } from "shared/utils/tracker";
import './style.css';


const EmissionsReportsPage = () => {

  const hasSoCoMVPEmissionsReportPage = useAppSelector(s => getHasGate(s, 'has_soco_feb_2025_mvp_emissions_report'));

  const mailToSupport = () => {
    window.location.href = "mailto:support@singularity.energy?subject=Emissions reporting&body=Hello, I would like to have emissions reports set up."
  };

  useEffect(() => {
    tracker.track(TrackEventNames.VER, {hasSocoMvp: hasSoCoMVPEmissionsReportPage});
  }, [hasSoCoMVPEmissionsReportPage]);

  if (hasSoCoMVPEmissionsReportPage) {
    return <SouthernFeb2025MVPEmissionsReport />;
  }

  return <div className="emissions-reports-page--missing-container">
    <div className="emissions-reports-page-missing--icon">
      <IconAlertTriangle size={40} color="var(--color-orange-1)" />
    </div>
    <div>
      <h2 className="emissions-reports-page-missing--title">Your emissions reports are not configured.</h2>
      <p className="emissions-reports-page-missing--desc">If you are expecting this page to be configured and visible, please contact your Singularity Energy representative.</p>
      <div className="emissions-reports-page-missing--cta">
        <Button onClick={mailToSupport}>Compose email</Button>
        <div className="emissions-reports-page-missing--link">Why am I seeing this?</div>
      </div>
    </div>
  </div>
}

export default EmissionsReportsPage;