import { sum } from 'ramda';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import { getSubscriptionById } from '../programs/selectors';


// these selectors should be for edited subscription information, as the user is making changes
// we are storing the results of their changes here
const root = (state: RootState) => state.amp__subscriptions;

export const useEditedSubscription = createSelector(
  root,
  state => state.editedSubscription
)

export const useTotalCommitByEditedSubscription = createSelector(
  useEditedSubscription,
  (sub) => {
    const editedSubAccountConfig = sub?.data.configuration.sub_account_to_allocation_percent_ten_thousandths;
    const total = sum(Object.values(editedSubAccountConfig || {}));
    return total;
  }
)

// this is reading ground truth and responding
export const useTotalCommitBySubscription = (state: RootState, subId: string) => {
  const subscription = getSubscriptionById(state, subId);

  // TODO: make selectors for this chain of properties - should be defensive to id's that don't exist
  const subAccountConfig = subscription?.data.configuration.sub_account_to_allocation_percent_ten_thousandths;
  const total = sum(Object.values(subAccountConfig || {}));
  return total;
}