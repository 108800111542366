import { useEffect, useMemo } from "react";

import { getViewingCustomerIds, getViewingOpCoId } from "amp/store/ui/selectors";
import { IListCustomersArgs, useFetchCustomerQuery, useFetchUtilityCustomersQuery, usePaginateCustomersQuery } from "shared/api/customers";
import { Customer, CustomerStatus, ICustomer } from "shared/types/customer";
import { useAppDispatch, useAppSelector } from "store";
import { getCustomerById, getCustomersById } from "./selectors";
import { receiveCustomers } from "./slice";

export const useCustomer = (customerId: string) => {

  const res = useFetchCustomerQuery(customerId, {skip: !customerId});
  const dispatch = useAppDispatch();
  const customer = useAppSelector(s => getCustomerById(s, customerId));

  useEffect(() => {
    if (res.data) {
      dispatch(receiveCustomers([res.data.customer]));
    }
  }, [res.data, dispatch, customerId]);

  return {
    ...res,
    isFirstTimeLoading: res.isLoading,
    isRefreshingData: res.isFetching,
    data: customer as Customer | undefined,
  }
};

// TODO: we only fetch active customers by default but now allow users to update a customer's status. this means customer's
//       that have their status updates won't show up in the list customers page anymore and will be hidden from users.
export const useCustomersPage = ({page, perPage, parentCustomerId, statuses=[CustomerStatus.ACTIVE], customerType}: IListCustomersArgs) => {
  const oci = useAppSelector(getViewingOpCoId);
  const res = usePaginateCustomersQuery({page, perPage, parentCustomerId: parentCustomerId || oci, statuses, customerType});
  const dispatch = useAppDispatch();
  const customerIds = useMemo(() => res.data?.data.map(({id}) => id) || [], [res.data]);
  const customers = useAppSelector(s => getCustomersById(s, customerIds));

  useEffect(() => {
    if (res.data) {
      dispatch(receiveCustomers(res.data.data));
    }
  }, [res.data, dispatch, page, perPage, parentCustomerId]);

  return {
    ...res,
    isFirstTimeLoading: res.isLoading,
    isRefreshingData: res.isFetching,
    data: customers,
    pagination: res.data?.meta.pagination,
  }
};

export const useUtilityCustomers = () => {
  const dispatch = useAppDispatch();
  const customerIds = useAppSelector(getViewingCustomerIds);

  const res = useFetchUtilityCustomersQuery({
    offset: 0,
    limit: 50,
    statuses: [CustomerStatus.ACTIVE],
    customerIds,
  });

  if (res.data) {
    dispatch(receiveCustomers(res.data.data));
  }

  return {
    ...res,
    data: [...(res.data?.data || [])] as ICustomer[],
  };
}