import { Box, Center, Checkbox, Group, Loader, NumberInput, Skeleton, Table, Text, Tooltip } from '@mantine/core';
import { useFetchAssetEventsQuery } from 'amp/api/assetEvents';
import { useProgram } from 'amp/store/programs/hooks';
import { useEditedSubscription, useTotalCommitByEditedSubscription } from 'amp/store/subscriptions/selectors';
import { receiveEditedSubscription } from 'amp/store/subscriptions/slice';
import { getViewingTimeZone } from 'amp/store/ui/selectors';
import { clone, sum } from 'ramda';
import { MouseEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import BasePaper from 'shared/components/Paper/basePaper';
import { useCustomersPage } from 'shared/store/customers/hooks';
import { IEnergyConsumptionData } from 'shared/types/assetEvents';
import { ICustomer, getCustomerUtilityAccountId } from 'shared/types/customer';
import { getThisYearEnd, getThisYearStart } from 'shared/utils/dates';
import { numberToSiFormat } from 'shared/utils/strings';
import { useAppDispatch, useAppSelector } from 'store';

const SubAccountRow = ({ subAccount }: { subAccount: ICustomer }) => {
  const dispatch = useAppDispatch();
  const tzName = useAppSelector(getViewingTimeZone);
  const defaultPercentTenThousandths = subAccount.attributes.find(a => a.name === 'allocation_percent_ten_thousandths');
  const defaultPercent = defaultPercentTenThousandths ? defaultPercentTenThousandths?.value as number : 10_000;

  const editedSubscription = useAppSelector(useEditedSubscription);
  const editedSubAccountConfig = editedSubscription?.data.configuration.sub_account_to_allocation_percent_ten_thousandths || {};

  const consumptionRes = useFetchAssetEventsQuery({
    startDate: getThisYearStart(tzName).toISOString(),
    endDate: getThisYearEnd(tzName).toISOString(),
    resolution: '1M',
    eventType: 'metered_consumption',
    customerId: subAccount.id,
  });
  const consumptionData = consumptionRes.data?.data;
  const ytdConsumptionKWh = sum(consumptionData?.map(ae => (ae.data as IEnergyConsumptionData).sum_consumed_kwh) || []);


  const onRowSelected = (selected: boolean) => {
    if (!editedSubscription) return;

    const newEditedSub = clone(editedSubscription);
    const newSubAccountObject = newEditedSub.data.configuration.sub_account_to_allocation_percent_ten_thousandths || {};
    if (selected) {
      newSubAccountObject[subAccount.id] = defaultPercent;
    } else {
      delete newSubAccountObject[subAccount.id];
    }
    newEditedSub.data.configuration.sub_account_to_allocation_percent_ten_thousandths = newSubAccountObject;
    dispatch(receiveEditedSubscription(newEditedSub));
  }

  const onPercentChanged = (newPercent: number) => {
    const newEditedSub = clone(editedSubscription);
    const newSubAccountObject = newEditedSub?.data.configuration.sub_account_to_allocation_percent_ten_thousandths || {};
    newSubAccountObject[subAccount.id] = newPercent * 10_000;
    dispatch(receiveEditedSubscription(newEditedSub));
  }

  const isSelected = editedSubAccountConfig[subAccount.id] !== undefined;
  const location = subAccount.attributes.find(a => a.name === 'address')?.value as string || 'unknown address';
  const formattedConsumption = numberToSiFormat(ytdConsumptionKWh * 1_000);
  const textColor = isSelected ? 'var(--color-black-1)' : 'var(--color-grey-4)';
  return (
    <Table.Tr className="subscription-table--row" onClick={() => onRowSelected(!isSelected)}>
      <Table.Td>
        <Group wrap="nowrap">
          <Checkbox
            className="subscription-table-row--checkbox"
            size="16px"
            color="var(--color-green-2)"
            checked={isSelected}
            onChange={e => onRowSelected(e.target.checked)}
          />
          <Tooltip label={subAccount.name}>
            <Text className="subscription-sub-account-mgmt--name-container" fz={10} c={textColor} ta="center">
              {subAccount.name}
            </Text>
          </Tooltip>
        </Group>
      </Table.Td>
      <Table.Td>
        <Text fz={10} c={textColor} ta="center">{getCustomerUtilityAccountId(subAccount) || <em>unknown</em>}</Text>
      </Table.Td>
      <Table.Td>
        <Tooltip label={location}>
          <Text className="subscription-sub-account-mgmt--name-container" fz={10} c={textColor} ta="center">
            {location}
          </Text>
        </Tooltip>
      </Table.Td>
      <Table.Td>
        <Skeleton visible={consumptionRes.isLoading} maw={150}>
          <Text fz={10} c={textColor} ta="center">
            {formattedConsumption.value} {formattedConsumption.unitPrefix}Wh
          </Text>
        </Skeleton>
      </Table.Td>
      <Table.Td onClick={(e: MouseEvent) => e.stopPropagation()}>
        <NumberInput
          disabled={!isSelected}
          suffix="%"
          fixedDecimalScale
          decimalScale={4}
          max={100}
          min={0}
          value={editedSubAccountConfig[subAccount.id] !== undefined ? editedSubAccountConfig[subAccount.id] / 10_000 : (defaultPercent / 10_000 || 0)}
          onChange={e => onPercentChanged(+e)} //TODO: do some digging here, why is given event 'string | number'?
          w={150}
        />
      </Table.Td>
    </Table.Tr>
  );
}

const SubAccountManagement = ({ parentCustomerId }: { parentCustomerId: string | null }) => {
  const { programId = '', subscriptionId = '' } = useParams<{ programId: string, subscriptionId: string }>();
  const dispatch = useAppDispatch();

  const programRes = useProgram(programId);
  const subscriptions = programRes.data.subscriptions;
  const subscription = subscriptions.find(s => s.id === subscriptionId);

  const editedSubscription = useAppSelector(useEditedSubscription);
  const totalCommitFromEditedSubscription = useAppSelector(useTotalCommitByEditedSubscription);

  // TODO: this request is huge and should be split up
  //   however this component really needs all the sub-customers in memory to do bulk operations on them so it's
  //   not obvious how this can be broken up into chunks
  const subAccountsRes = useCustomersPage({ page: 1, perPage: 2000, parentCustomerId: parentCustomerId || subscription?.customer_id });
  const subAccounts = subAccountsRes.data;

  useEffect(() => {
    dispatch(receiveEditedSubscription(subscription || null));
  }, [subscription, dispatch]);

  const onSelectAll = (checked: boolean) => {
    if (!editedSubscription) {
      return;
    }

    const newEditedSub = clone(editedSubscription);
    const newSubAccountObject = newEditedSub.data.configuration.sub_account_to_allocation_percent_ten_thousandths || {};
    if (checked) {
      subAccounts.forEach(act => {
        if (!newSubAccountObject[act.id]) {
          const defaultPercentTenThousandths = act.attributes.find(a => a.name === 'allocation_percent_ten_thousandths');
          const defaultPercent = defaultPercentTenThousandths ? defaultPercentTenThousandths?.value as number : 10_000;
          newSubAccountObject[act.id] = defaultPercent;
        }
      });
      newEditedSub.data.configuration.sub_account_to_allocation_percent_ten_thousandths = newSubAccountObject;
    } else {
      newEditedSub.data.configuration.sub_account_to_allocation_percent_ten_thousandths = {};
    }
    dispatch(receiveEditedSubscription(newEditedSub));
  };

  const totalCommitTenThousandths = totalCommitFromEditedSubscription;
  const isTotalPercentExactlyOneHundred = Math.abs(totalCommitFromEditedSubscription - (10_000 * 100)) < 0.0001;
  const isLoading = subAccountsRes.isLoading || subAccountsRes.isFetching;
  return (
    <BasePaper titleContent="Sub-accounts">
      {isLoading &&
        <Box pos="relative" h={300} w="100%">
          <Center>
            <Loader />
          </Center>
        </Box>
      }
      {!isLoading && <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>
              <Group w={260}>
                <Checkbox size="16px" color="var(--color-green-2)" disabled={subAccountsRes.isLoading || !editedSubscription} onChange={e => onSelectAll(e.currentTarget.checked)} />
                <Text pl={32} size="10px" c="var(--color-blue-2)" fw="600">Sub-account Name</Text>
              </Group>
            </Table.Th>
            <Table.Th>
              <Text ta="center" size="10px" c="var(--color-blue-2)" fw="600">Sub-account ID</Text>
            </Table.Th>
            <Table.Th >
              <Text ta="center" size="10px" c="var(--color-blue-2)" fw="600">Location</Text>
            </Table.Th>
            <Table.Th>
              <Text size="10px" ta="center" c="var(--color-blue-2)" fw="600">Consumption YTD</Text>
            </Table.Th>
            <Table.Th>
              <Group gap={4}>
                <Text size="10px" ta="center" c="var(--color-blue-2)" fw="600">Commitment Assigned</Text>
                <Skeleton visible={subAccountsRes.isLoading} maw={100}>
                  <Box className={`subscription-create--total-percent ${isTotalPercentExactlyOneHundred ? '' : 'oversubscribed'}`}>
                    {(totalCommitTenThousandths / 10_000).toFixed(4)}%
                  </Box>
                </Skeleton>
              </Group>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {(parentCustomerId || subscription?.customer_id) && !subAccountsRes.isLoading && subAccounts.map(sub => (
            <SubAccountRow
              key={sub.id}
              subAccount={sub}
            />
          ))}
        </Table.Tbody>
      </Table>}
    </BasePaper>
  );
}

export default SubAccountManagement;