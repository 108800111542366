import { Group, Skeleton, Stack } from '@mantine/core';
import { Outlet, useParams } from 'react-router-dom';

import { usePrefetch } from 'amp/api/sources';
import PageBreadcrumbs from 'amp/components/PageBreadcrumbs/pageBreadcrumbs';
import Tabs from 'amp/components/Tabs';
import { useGenerator } from 'amp/store/generators/hooks';
import { getViewingCustomerIds } from 'amp/store/ui/selectors';
import { useAppSelector } from 'store';
import './style.css';


export default function InventoryOutlet() {
  const { generatorId = '' } = useParams<{ generatorId: string }>();
  const genRes = useGenerator(generatorId);
  const customerIds = useAppSelector(getViewingCustomerIds);
  const prefetchSources = usePrefetch('fetchUtilitySources');
  const prefetchFacets = usePrefetch('fetchUtilitySourceFacets');

  const mouseEnterSources = () => {
    prefetchFacets({customerIds});
    prefetchSources({customerIds, page: 1, perPage: 10});
  }

  if (generatorId) {
    return (
      <Stack pos="fixed" w="calc(100% - var(--amp-nav-bar-width))">
        <Stack m="60px" mt="32px" mb="16px" gap={32}>
          <PageBreadcrumbs />
          <Group align="center">
            {genRes.isLoading && <Skeleton visible={true} w={400} h={40} />}
            {!genRes.isLoading && <div className="inventory-outlet--title">
              {genRes.data?.name || ''}
            </div>}
          </Group>
        </Stack>
        <Outlet />
      </Stack>
    );
  }

  return (
    <Stack pos="fixed" w="calc(100% - var(--amp-nav-bar-width))" gap={0}>
      <Stack ml="60px" mr="60px" mt="60px" mb="20px" gap="12px">
        <div className="inventory-outlet--title">
          Inventory
        </div>
        {<Tabs tabs={[
          { title: 'Generation', path: '/dashboard/inventory' },
          { title: 'Sources', path: '/dashboard/inventory/sources', onMouseEnter: mouseEnterSources },
        ]} />}
      </Stack>
      <Outlet />
    </Stack>
  );
}