import { GeneratorFuelCategories } from "./generator"

export enum AggregationTypes {
  TOTAL_GENERATION = 'total_generation',
  TOTAL_LOAD = 'total_load',
  GENERATION_BY_FUEL = 'generation_by_fuel',
  EMISSIONS_FROM_GENERATION = "emissions_from_generation",
}

export interface ITotalGenerationEventData {
  sum_generated_wh: number
  min_generated_w: number
  max_generated_w: number
  mean_generated_w: number
}

export interface ITotalLoadEventData {
  sum_consumed_kwh: number
  min_consumed_kw: number
  max_consumed_kw: number
  mean_consumed_kw: number
}

export interface IEmissionsFromGenerationData {
  sum_fuel_consumed_mmbtu: number
  min_fuel_consumed_mmbtu: number
  max_fuel_consumed_mmbtu: number
  mean_fuel_consumed_mmbtu: number

  sum_co2_mass_lb: number
  min_co2_mass_lb: number
  max_co2_mass_lb: number
  mean_co2_mass_lb: number

  min_co2_rate_lb_per_mwh: number
  max_co2_rate_lb_per_mwh: number
  mean_co2_rate_lb_per_mwh: number

  sum_ch4_mass_lb: number
  min_ch4_mass_lb: number
  max_ch4_mass_lb: number
  mean_ch4_mass_lb: number

  sum_n2o_mass_lb: number
  min_n2o_mass_lb: number
  max_n2o_mass_lb: number
  mean_n2o_mass_lb: number

  sum_co2e_mass_lb: number
  min_co2e_mass_lb: number
  max_co2e_mass_lb: number
  mean_co2e_mass_lb: number

  min_ch4_rate_lb_per_mwh: number
  max_ch4_rate_lb_per_mwh: number
  mean_ch4_rate_lb_per_mwh: number

  min_n2o_rate_lb_per_mwh: number
  max_n2o_rate_lb_per_mwh: number
  mean_n2o_rate_lb_per_mwh: number

  min_co2e_rate_lb_per_mwh: number
  max_co2e_rate_lb_per_mwh: number
  mean_co2e_rate_lb_per_mwh: number

  sum_generated_wh: number
}

export type GenerationByFuelAggEventData = Record<GeneratorFuelCategories, ITotalGenerationEventData>

export type AggAssetEventData = ITotalGenerationEventData | ITotalLoadEventData | IEmissionsFromGenerationData | GenerationByFuelAggEventData

export interface IAggregatedAssetEvent<T = AggAssetEventData>{
  id: string
  start_date: string
  customer_id: string
  resolution: string
  event_aggregation_type: AggregationTypes
  data: T
  meta: Record<string, any>
}

export interface IListAggregatedAssetEventsResponse<T = AggAssetEventData> {
  data: IAggregatedAssetEvent<T>[]
}

export const dataToDisplayValues = (aggregatedEvent: IAggregatedAssetEvent) => {
  const displayValues = {
    id: aggregatedEvent.id,
    startDate: aggregatedEvent.start_date,
    customerId: aggregatedEvent.customer_id,
    resolution: aggregatedEvent.resolution,
    eventAggregationType: aggregatedEvent.event_aggregation_type,
    data: aggregatedEvent.data, // fields nested in data can be cast as ITotalGenerationEventData | ITotalLoadEventData
    meta: aggregatedEvent.meta,
  };

  return displayValues;
};