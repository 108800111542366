import { IForecast, IForecastData, IForecastWithData } from "amp/api/assetForecasts";
import { getViewingOpCoId } from "amp/store/ui/selectors";
import { AxiosResponse } from "axios";
import { difference } from "ramda";
import { useEffect, useState } from "react";
import { API } from "shared/api";
import { useAppSelector } from "store";

interface IForecastRunOutputData {
  results: IForecastData[]
}

export function useForecastRunOutputData(resultsLocation: string) {
  const [fetchStatus, setFetchStatus] = useState<'not_started' | 'in_flight' | 'completed' | 'error'>('not_started');
  const [outputData, setOutputData] = useState<IForecastRunOutputData | null>(null);
  useEffect(() => {
    setFetchStatus('not_started');
  }, [resultsLocation]);

  useEffect(() => {
    if (fetchStatus === 'not_started' && resultsLocation) {
      setFetchStatus('in_flight');

      fetch(resultsLocation, { credentials: 'include' })
        .then(res => {
          if (res.ok) {
            res.json().then((jsondata: IForecastRunOutputData) => {
              setOutputData(jsondata);
              setFetchStatus('completed');
            });
          } else {
            setFetchStatus('error');
          }
        });
    }
  }, [fetchStatus, resultsLocation]);

  return {
    data: outputData,
    status: fetchStatus,
  };
}


export const useMultipleAssetForecast = (assetIds: string[], year: number) => {
  const oci = useAppSelector(getViewingOpCoId);
  // TODO: perhaps aggregating forecasts like this should be done on the backend, not the frontend...
  const [loadingBatches, setLoadingBatches] = useState(false);
  const [loadingResults, setLoadingResults] = useState(false);
  const [error, setError] = useState(false);
  const [outputData, setOutputData] = useState<IForecastData[]>();
  const [outputResultsPromises, setOutputResultPromises] = useState<Promise<AxiosResponse<{results: IForecastData[]}>>[]>();
  const [fetchedAssetIds, setFetchedAssetIds] = useState<string[]>([]);

  useEffect(() => {
    if (!loadingBatches && !loadingResults && !error && !outputResultsPromises) {
      setFetchedAssetIds(assetIds);
      setLoadingBatches(true);
      const promises = assetIds.map(assetId => API.get<{event_forecast_batch: IForecast}>(`/api/v2/assets/${assetId}/forecast?year=${year}&customer_id=${oci}`,{withCredentials: true}));
      Promise.all(promises).then((results) => {
        if (results) {
          setOutputResultPromises(results.map(res => {
            const forecast = res.data.event_forecast_batch;
            return API.get<{results: IForecastData[]}>(`/${forecast.customer_id}/forecast-data/${forecast.id}/output.json`, {withCredentials: true});
          }));
          setLoadingBatches(false);
        }
      }).catch(() => {
        setError(true);
        setLoadingBatches(false);
        setLoadingResults(false);
      });
    }
  }, [loadingBatches, error, outputResultsPromises, assetIds, year, loadingResults, oci]);

  // wipe the output.json request promises if the generatorIds changes
  useEffect(() => {
    if (difference(assetIds, fetchedAssetIds).length !== 0) {
      setOutputResultPromises(undefined);
      setOutputData(undefined);
    }
  }, [assetIds, fetchedAssetIds]);

  useEffect(() => {
    if (outputResultsPromises?.length && !outputData && !loadingBatches && !loadingResults && !error) {
      setLoadingResults(true);
      const finalDataByStartDate: Record<string, number> = {};
      Promise.all(outputResultsPromises).then((res) => {
        if (res) {
          res.forEach(({data}) => {
            data.results.forEach(({start_date, y_axis_value_wh}) => {
              if (finalDataByStartDate[start_date]) {
                finalDataByStartDate[start_date] += y_axis_value_wh;
              } else {
                finalDataByStartDate[start_date] = y_axis_value_wh;
              }
            });
          });
        }
        const sorted = Object.entries(finalDataByStartDate)
          .sort((x1, x2) => new Date(x1[0]).valueOf() - new Date(x2[0]).valueOf());
        const formatted = sorted.map(([start_date, y_axis_value_wh]) => ({start_date, y_axis_value_wh}));
        setOutputData(formatted);
        setLoadingResults(false);
      }).catch(() => {
        setError(true);
        setLoadingResults(false);
      });
    }
  }, [outputResultsPromises, loadingBatches, loadingResults, error, outputData]);

  return {
    loading: loadingBatches || loadingResults,
    error,
    data: outputData,
  }
};


type OutputResults = {customerId: string, response: IForecastData[]}[];

export const useCustomersForecast = (customerIds: string[], years: number[]) => {
  const oci = useAppSelector(getViewingOpCoId);
  // TODO: perhaps aggregating forecasts like this should be done on the backend, not the frontend...
  const [loadingBatches, setLoadingBatches] = useState(false);
  const [loadingResults, setLoadingResults] = useState(false);
  const [error, setError] = useState(false);
  const [outputData, setOutputData] = useState<IForecastData[]>();
  const [nonGroupedOutputData, setNonGroupedOutputData] = useState<Record<string, IForecastData[]>>();
  const [outputResultsPromises, setOutputResultPromises] = useState<OutputResults>();
  const [fetchedCustIds, setFetchedCustIds] = useState<string[]>([]);

  useEffect(() => {
    if (!loadingBatches && !loadingResults && !error && !outputResultsPromises) {
      setFetchedCustIds(customerIds);
      setLoadingBatches(true);
      const urls: string[] = [];
      customerIds.forEach(custId => {
        years.forEach(year => {
          urls.push(
            `/api/v2/assets/forecast-for-customer/by-customer-id?year=${year}&customer_id=${oci}&forecast_customer_id=${custId}`
          );
        });
      });
      const promises = urls.map(url => API.get<{event_forecast_batch: IForecastWithData}>(url, {withCredentials: true}));
      Promise.all(promises).then((results) => {
        setOutputResultPromises(results.map(res => {
          if (res.status !== 200) {
            // TODO: report the failed requests to Sentry - we should know about the missing forecasts
            return undefined;
          }
          const forecast = res.data.event_forecast_batch;
          const customerId = forecast.customer_id;
          return {
            customerId,
            response: forecast.data?.results,
          } as {customerId: string; response: IForecastData[]};
        }).filter(res => !!res && res.response) as { customerId: string; response: IForecastData[]; }[]);
        setLoadingBatches(false);
      }).catch((err) => {
        setError(true);
        setLoadingBatches(false);
        setLoadingResults(false);
      });
    }
  }, [loadingBatches, error, outputResultsPromises, customerIds, years, loadingResults, oci]);

  // wipe the output.json request promises if the generatorIds changes
  useEffect(() => {
    const diffLtr = difference(customerIds, fetchedCustIds);
    const diffRtl = difference(fetchedCustIds, customerIds);
    if (diffLtr.length !== 0 || diffRtl.length !== 0) {
      setOutputResultPromises(undefined);
      setOutputData(undefined);
      setNonGroupedOutputData(undefined);
      setError(false);
    }
  }, [customerIds, fetchedCustIds]);

  useEffect(() => {
    if (outputResultsPromises?.length && !outputData && !loadingBatches && !loadingResults && !error) {
      setLoadingResults(true);
      const finalDataByStartDate: Record<string, number> = {};
      const finalDataByCustomerId: Record<string, IForecastData[]> = {};
      Promise.all(outputResultsPromises.map(({response}) => response)).then((res) => {
        if (res) {
          res.forEach((data, idx) => {
            const custId = outputResultsPromises[idx].customerId;
            data.forEach(({start_date, y_axis_value_wh}) => {
              if (finalDataByStartDate[start_date]) {
                finalDataByStartDate[start_date] += y_axis_value_wh;
              } else {
                finalDataByStartDate[start_date] = y_axis_value_wh;
              }
            });
            if (!finalDataByCustomerId[custId]) {
              finalDataByCustomerId[custId] = data;
            } else {
              finalDataByCustomerId[custId] = finalDataByCustomerId[custId].concat(data);
            }
          });
        }
        const sorted = Object.entries(finalDataByStartDate)
          .sort((x1, x2) => new Date(x1[0]).valueOf() - new Date(x2[0]).valueOf());
        const formatted = sorted.map(([start_date, y_axis_value_wh]) => ({start_date, y_axis_value_wh}));
        setOutputData(formatted);
        setNonGroupedOutputData(finalDataByCustomerId);
        setLoadingResults(false);
      }).catch(() => {
        setError(true);
        setLoadingResults(false);
      });
    }
  }, [outputResultsPromises, loadingBatches, loadingResults, error, outputData]);


  return {
    loading: loadingBatches || loadingResults,
    error,
    data: outputData,
    nonGroupedData: nonGroupedOutputData,
  }
};

