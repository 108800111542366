import { Button, Modal, Stack, Text, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useState } from 'react';

import { useDeleteSubscriptionMutation } from 'amp/api/programs';
import { tracker, TrackEventNames } from 'shared/utils/tracker';

const DeleteSubscriptionModal = ({
  onClose,
  isOpen,
  subscriptionId,
  programId,
  customerId,
}: { onClose: () => void, isOpen: boolean, subscriptionId: string, programId: string, customerId: string }) => {
  const [confirmText, setConfirmText] = useState<string>('');
  const [deleteSubscription, deleteSubscriptionRes] = useDeleteSubscriptionMutation();

  const onDeleteClicked = async () => {
    try {
      await deleteSubscription({ subscriptionId, programId, customerId }).unwrap();
      notifications.show({
        title: 'Success',
        message: 'Successfully deleted the subscription',
        color: "teal",
        icon: <IconCheck size={20} />,
      });
      tracker.track(TrackEventNames.DS, {subscriptionId, programId, customerId});
    } catch (e) {
      notifications.show({
        title: 'Error',
        message: 'There was an issue deleting the subscription',
        color: 'red',
        icon: <IconX size={20} />,
      });
    }
  }

  return (
    <Modal
      onClose={onClose}
      opened={isOpen}
      title="Delete Subscription"
    >
      <Stack>
        <Text fz={16} c="var(--color-neutral-12)">
          Are you sure you want to delete this program subscription? It will no longer appear in the list of subscriptions and will need to be re-created.
        </Text>
        <TextInput label="Type DELETE below to confirm" onChange={e => setConfirmText(e.target.value)} />
        <Button onClick={onDeleteClicked} disabled={confirmText.toLowerCase() !== 'delete'} maw={200} loading={deleteSubscriptionRes.isLoading}>
          Delete
        </Button>
      </Stack>
    </Modal>
  );
}

export default DeleteSubscriptionModal;