import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { isEmpty } from "ramda";
import { API } from "shared/api/index";
import { userAuthQueries } from "shared/api/queries";
import { IPagination, IPaginationResponse } from "shared/types/api";
import { CustomerPropertyTypes, CustomerType, ICustomer, displayValuesToRequestData } from "shared/types/customer";


export const fetchCustomersPage = (customerId: string, qs: string) => API.get<IPaginationResponse<ICustomer>>(`/u/api/v1/customers/me/${customerId}/paginate?${qs}`);


export interface IListCustomersArgs {
  page: number
  perPage: number
  parentCustomerId?: string | null,
  statuses?: string[],
  customerType?: string,
}

const customerPage: {type: 'Customer', id: string} = {type: 'Customer', id: 'PAGE'}

const customersQueries = userAuthQueries.injectEndpoints({
  endpoints: (build) => ({
    paginateCustomers: build.query<{data: ICustomer[], meta: {pagination: IPagination}}, IListCustomersArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('offset', ((body.page - 1) * body.perPage).toString());
        qs.set('limit', body.perPage.toString());

        body.statuses?.forEach(status => {
          qs.append('statuses', status)
        })

        if (body.customerType) {
          qs.set('customer_type', body.customerType);
        }

        return {
          url: `v1/customers/me/${body.parentCustomerId}/paginate?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [customerPage, ...res.data.map(cust => ({type: 'Customer' as const, id: cust.id}))] : [],
    }),

    fetchCustomer: build.query<{customer: ICustomer}, string>({
      query: (id) => `v1/customers/me/${id}`,
      providesTags: (res) => res ? [{type: 'Customer', id: res.customer.id}] : [],
    }),

    patchCustomer: build.mutation<{customer: ICustomer}, {id: string, body: ReturnType<typeof displayValuesToRequestData>}>({
      query: ({id, body}) => ({
        url: `v1/customers/me/${id}`,
        body: {...body, attributes: []},
        method: 'PATCH'
      }),
      invalidatesTags: (res) => res ? [{type: 'Customer', id: res.customer.id}] : [],
    }),

    upsertCustomerSetting: build.mutation<{customer: ICustomer}, {id: string, name: string, value: string, type: CustomerPropertyTypes}>({
      query: ({id, name, value, type}) => ({
        url: `v1/customers/me/${id}`,
        body: {name: null, status: null, attributes: [{name, value, type}]},
        method: 'PATCH'
      }),
      invalidatesTags: (res) => res ? [{type: 'Customer', id: res.customer.id}] : [],
    }),

    fetchUtilityCustomers: build.query<IPaginationResponse<ICustomer>, {customerIds: string[], limit: number, offset: number, statuses: string[]}>({
      queryFn: async ({customerIds, limit, offset, statuses}, _queryApi, _extra, fetchWithBQ) => {
        const qs = new URLSearchParams();
        qs.append('customer_type', CustomerType.UTILITY_CUSTOMER);
        qs.append('customer_type', CustomerType.UTILITY_PARENT_CUSTOMER);

        qs.set('limit', limit.toString());
        qs.set('offset', offset.toString());
        statuses?.forEach(status => {
          qs.append('statuses', status);
        });

        const customersById: Record<string, ICustomer> = {};
        let pagination: IPagination = {this: 1, last: 1, total_items: 1, first: 1};
        let latestError: FetchBaseQueryError | null = null;
        const responses = await Promise.all(customerIds.map(customerId => fetchWithBQ(`/v1/customers/me/${customerId}/paginate?${qs}`)));
        responses.forEach(async res => {
          if (res.error) {
            // TODO: report to sentry
            console.warn("Failed to request customer page", res.meta?.request.url, res.error)
            latestError = res.error;
            return;
          }
          const data = res.data as IPaginationResponse<ICustomer>;
          pagination = data.meta.pagination;
          data.data.forEach(cust => {
            customersById[cust.id] = cust;
          });
        });

        if (isEmpty(customersById) && customerIds.length !== 0 && latestError) {
          return { error: latestError };
        }

        return {data: {data: Object.values(customersById), meta: {pagination}}};
      },

      providesTags: (res) => res?.data.map(({id}) => ({ type: 'Customer', id})) || [],
    })

  }),
  overrideExisting: false,
});


export const {
  usePrefetch,
  usePaginateCustomersQuery,
  useFetchCustomerQuery,
  usePatchCustomerMutation,
  useFetchUtilityCustomersQuery,
  useUpsertCustomerSettingMutation,
} = customersQueries;