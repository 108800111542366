
export interface ISubscription {
  id: string
  retail_program_id: string
  parent_customer_id: string
  customer_id: string
  created_at: string
  created_by: string
  data: {
    configuration: {
      subscription_start?: string | null,
      subscription_end?: string | null,
      percent_generation_dedicated_ten_thousandths: number | null,
      sub_account_to_allocation_percent_ten_thousandths?: Record<string, number> | null,
      committed_generation_wh: number | null
    },
    commitments?: {}
  },
  meta: Record<string, unknown>
}

export interface Subscription extends ISubscription { }

export class Subscription {
  constructor(data: ISubscription){
    Object.assign(this, data);
  }

  get percentGenerationDedicatedTenThousandths(): number {
    return this.data?.configuration?.percent_generation_dedicated_ten_thousandths || 0
  }

  get committedGenerationWh(): number {
    return this.data?.configuration?.committed_generation_wh || 0
  }

  get subscriptionStart(): Date | null {
    if (this.data?.configuration?.subscription_start) {
      return new Date(this.data.configuration.subscription_start);
    }
    return null
  }

  get subscriptionEnd(): Date | null {
    if (this.data?.configuration?.subscription_end) {
      return new Date(this.data.configuration.subscription_end);
    }
    return null
  }

  get subAccountAllocationPercentTenThousandths(): Record<string, number>[] {
    const subAccountConfig = this.data?.configuration?.sub_account_to_allocation_percent_ten_thousandths;
    if (subAccountConfig) {
        return Object.keys(subAccountConfig || {}).map(sub_customer => ({sub_customer: subAccountConfig[sub_customer]}))
    }
    return []
  }
}

export interface IFetchSubscriptionResponse {
  program_customer: ISubscription
}


export const dataToDisplayValues = (subscription: ISubscription) => {
  return {
    customerId: subscription.customer_id,
    start: subscription.data.configuration.subscription_start,
    end: subscription.data.configuration.subscription_end,
    percentGenerationDedicatedTenThousandths: subscription.data.configuration.percent_generation_dedicated_ten_thousandths,
    subAccountToAllocationPercentTenThousandths: subscription.data.configuration.sub_account_to_allocation_percent_ten_thousandths,
    committed_generation_wh: subscription.data.configuration.committed_generation_wh,
  };
};


export const displayValuesToRequestData = (values: ReturnType<typeof dataToDisplayValues>) => {
  return {
    subscription_start: values.start,
    subscription_end: values.end,
    percent_generation_dedicated_ten_thousandths: values.percentGenerationDedicatedTenThousandths,
    sub_account_to_allocation_percent_ten_thousandths: values.subAccountToAllocationPercentTenThousandths,
    customer_id: values.customerId,
    committed_generation_wh: values.committed_generation_wh,
  };
};


export const errorResponseToDisplayErrors = (errs: Record<string, string[]>) => {
  const displayErrors: Record<string, string> = {};

  const fieldMap: Record<string, string> = {
    percent_generation_dedicated_ten_thousandths: 'percentGenerationDedicatedTenThousandths',
    subscription_end: 'end',
    subscription_start: 'start',
  };

  Object.entries(errs).forEach(([field, errors]) => {
    if (errors.length && fieldMap[field]) {
      displayErrors[fieldMap[field]] = errors[0];
    }
  });

  return displayErrors;
};

