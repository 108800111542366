import { Button, createTheme, Group, MantineProvider, Select, Stack, Switch, TextInput, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useState } from 'react';

import { useCreateProgramMutation } from 'amp/api/programs';
import { AmpLink } from 'amp/components/Link';
import { useAmpNav } from 'amp/hooks';
import { getViewingCustomerType, getViewingOpCoId } from 'amp/store/ui/selectors';
import FeatureGate from 'shared/components/FeatureGate/featureGate';
import BasePaper from 'shared/components/Paper/basePaper';
import { getOpcos } from 'shared/store/user/selectors';
import { CustomerType } from 'shared/types/customer';
import { ProgramCommitmentType, ProgramStatus } from 'shared/types/program';
import { getErrorMessagesFromApiError } from 'shared/utils/data';
import { tracker, TrackEventNames } from 'shared/utils/tracker';
import { useAppSelector } from 'store';
import './style.css';

const statusOptions = [
  { label: 'Active', value: ProgramStatus.ACTIVE },
  { label: 'Inactive', value: ProgramStatus.INACTIVE },
  // { label: 'Archived', value: ProgramStatus.ARCHIVED },
  // { label: 'Deleted', value: ProgramStatus.DELETED },
];

// This is the only way mantine recommends to do this
const cursorPointerTheme = createTheme({ cursorType: 'pointer' });

export default function CreateProgramView() {
  const navigate = useAmpNav();
  const oci = useAppSelector(getViewingOpCoId);
  const opcos = useAppSelector(s => getOpcos(s));
  const viewingCustomerType = useAppSelector(getViewingCustomerType);
  const [create, createRes] = useCreateProgramMutation();

  const [editedName, setEditedName] = useState('');
  const [editedPeriod, setEditedPeriod] = useState<'annual' | 'hourly'>('annual');
  const [editedStatus, setEditedStatus] = useState<ProgramStatus>(ProgramStatus.ACTIVE);
  const [editedCommitType, setEditedCommitType] = useState<ProgramCommitmentType>(ProgramCommitmentType.PERCENT_GENERATION);
  const [editedIsHypothetical, setEditedIsHypothetical] = useState<boolean>(true);
  const [editedOpco, setEditedOpco] = useState<string | null>(null);
  const [errorsByField, setErrorsByField] = useState<Record<string, string[]>>({});

  const createDisabled = (viewingCustomerType === CustomerType.UTILITY && editedOpco === null) || !editedName.trim();
  const onSubmit = async () => {
    // the submit button is disabled in this case, this bail-out is for extra safety.
    if (createDisabled) {
      return;
    }

    setErrorsByField({});
    try {
      await create({
        customerId: editedOpco || oci,
        body: {
          priority: 1, // TODO: this field isn't used but is required by the backend
          name: editedName,
          status: editedStatus,
          is_hypothetical: editedIsHypothetical,
          data: {
            program_config: {
              generation_assignment: 'pooled', // TODO: this field isn't used but is required by the backend
              accounting_period: editedPeriod,
              commitment_type: editedCommitType,
            }
          }
        },
      }).unwrap();
      tracker.track(TrackEventNames.CP, { customerId: oci, programName: editedName });
      notifications.show({
        title: 'Success',
        message: 'Successfully created the program',
        color: 'teal',
        icon: <IconCheck size={20} />,
      });
      navigate('/dashboard/programs');
    } catch (err) {
      const errMsgs = getErrorMessagesFromApiError(err);
      if (errMsgs) {
        setErrorsByField(errMsgs);
      }
      notifications.show({
        title: 'Error',
        message: 'There was an issue creating the program',
        color: 'red',
        icon: <IconX size={20} />,
      });
    }
  };

  const dataErrors = errorsByField.data as unknown as Record<string, Record<string, string[]>>;
  const configErrors = dataErrors?.program_config;
  return <>
    <div className="program-create--title-container">
      <Title size="24px">New Program</Title>
      <Group>
        <AmpLink to="/dashboard/programs">
          <Button className="program-create--button">
            Cancel
          </Button>
        </AmpLink>
        <Button
          className={`program-edit--button ${createDisabled ? 'is-disabled' : ''}`}
          onClick={onSubmit}
          disabled={createDisabled}
          loading={createRes.isLoading}
        >
          Save Changes
        </Button>
      </Group>
    </div>
    <div className="program-create--fields-container">
      <BasePaper>
        <Stack w="calc(50% - 30px)">
          {viewingCustomerType === CustomerType.UTILITY &&
            <Select
              label="Operating Company"
              allowDeselect={false}
              data={opcos.map(opco => ({ label: opco.name, value: opco.id }))}
              value={editedOpco}
              onChange={newValue => setEditedOpco(newValue)}
              required={true}
            />
          }
          <TextInput
            error={errorsByField.name && errorsByField.name[0]}
            placeholder="Program name"
            label="Name"
            value={editedName}
            onChange={e => setEditedName(e.target.value)}
            required={true}
          />
          <Select
            error={errorsByField.status && errorsByField.status[0]}
            placeholder="Program Status"
            label="Status"
            data={statusOptions}
            clearable={false}
            allowDeselect={false}
            value={editedStatus}
            onChange={e => setEditedStatus(e as ProgramStatus)}
          />
          <FeatureGate propertyName="amp_program_configuration_demo_access">
            {/* this property defaults to PERCENT_GENERATION if user doesn't have feature gate */}
            <Select
              label="Commitment Type"
              error={configErrors?.commitment_type}
              data={[
                { label: 'Percentage of Generation', value: ProgramCommitmentType.PERCENT_GENERATION },
                { label: 'Percentage of Consumption', value: ProgramCommitmentType.PERCENT_CONSUMPTION, disabled: true },
                // TODO: add UI tooltip or flag explaining why hourly fixed commitments are disabled
                { label: 'Fixed MWh', value: ProgramCommitmentType.FIXED_COMMITMENT, disabled: editedPeriod === 'hourly' },
              ]}

              clearable={false}
              allowDeselect={false}
              value={editedCommitType}
              onChange={e => {
                setEditedCommitType(e as ProgramCommitmentType)
                setErrorsByField({})
                if (e === ProgramCommitmentType.FIXED_COMMITMENT) {
                  setEditedPeriod('annual')
                }
              }}
            />
          </FeatureGate>
          <Select
            error={configErrors?.accounting_period}
            label="Accounting period"
            data={[
              { label: 'Annual', value: 'annual' },
              // TODO: add UI tooltip or flag explaining why hourly fixed commitments are disabled
              { label: 'Hourly', value: 'hourly', disabled: editedCommitType === ProgramCommitmentType.FIXED_COMMITMENT }
            ]}
            clearable={false}
            allowDeselect={false}
            value={editedPeriod}
            onChange={e => {
              setEditedPeriod(e as ('annual' | 'hourly'))
              setErrorsByField({})
              if (e === 'hourly') {
                setEditedCommitType(ProgramCommitmentType.PERCENT_GENERATION)
              }
            }}
          />
          <MantineProvider theme={cursorPointerTheme}>
            <Switch
              error={errorsByField.is_hypothetical && errorsByField?.is_hypothetical[0]}
              label="Hypothetical Program"
              description="Hypothetical programs cannot be included in settleable allocation runs. Programs not given a status of Hypothetical must be included for an allocation to be settleable."
              checked={editedIsHypothetical === null ? true : editedIsHypothetical}
              onChange={e => setEditedIsHypothetical(e.target.checked)}
              color="var(--color-blue-2)"
            />
          </MantineProvider>
        </Stack>
      </BasePaper>
    </div>
  </>;
}