import { isDefined } from 'shared/utils/data';
import { IPagination } from './api';

export enum CustomerStatus {
  UNVERIFIED = 'unverified',
  INVITED = 'invited',
  ACTIVE = 'active',
  VERIFIED = 'verified',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
}


export enum CustomerPropertyTypes {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  JSON = 'JSON',
  BOOLEAN = 'BOOLEAN',
}


export enum CustomerPropertyNamespace {
  SETTINGS = 'SETTINGS',
  GATES = 'GATES',
  PRIVATE = 'PRIVATE',
  NONE = 'NONE',
}

export enum CustomerType {
  // for example: Southern Company
  UTILITY = 'utility',

  // for example: Georgia Power (child of Southern Company)
  UTILITY_OPCO = 'utility_opco',

  // for example: GSA (Child of Georgia Power), has UTILITY_CUSTOMERS as children
  // cannot be subscribed to programs
  UTILITY_PARENT_CUSTOMER = 'utility_parent_customer',

  // for example: Google (child of Georgia Power)
  UTILITY_CUSTOMER = 'utility_customer',

  // for example: Google Data Center at 123 Main St. (child of Google)
  UTILITY_CUSTOMER_ACCOUNT = 'utility_customer_account',
}
export const ALL_CUSTOMER_TYPES = [
  CustomerType.UTILITY,
  CustomerType.UTILITY_OPCO,
  CustomerType.UTILITY_PARENT_CUSTOMER,
  CustomerType.UTILITY_CUSTOMER,
  CustomerType.UTILITY_CUSTOMER_ACCOUNT,
];


export interface IParsedCustomerProperty {
  name: string
  value: boolean | number | string | Record<string, unknown> | Array<unknown>
  namespace: CustomerPropertyNamespace
  type: CustomerPropertyTypes
}

export interface ICustomer {
  id: string
  name: string
  parent_id?: string | null
  status: CustomerStatus
  created_at: string
  updated_at: string
  customer_type: CustomerType | null
  attributes: IParsedCustomerProperty[]
  api_key_id?: string | null
  api_key_value?: string | null
  usage_plan_id?: string | null
}

export interface Customer extends ICustomer { }
export class Customer {
  constructor(data: ICustomer) {
    Object.assign(this, data);
  }

  get isUtility() {
    return this.customer_type === CustomerType.UTILITY;
  }

  get isOpco() {
    return this.customer_type === CustomerType.UTILITY_OPCO;
  }

  get isCustomerAccount() {
    return this.customer_type === CustomerType.UTILITY_CUSTOMER_ACCOUNT;
  }

  get isParentCustomer() {
    return this.customer_type === CustomerType.UTILITY_PARENT_CUSTOMER;
  }
}

export interface ICustomerParent {
  id: string
  parent_customer_id: string
  parent_customer_name: string | null
  parent_customer_type: string | null
  parent_created_at: string
  parent_updated_at: string
  child_customer_id: string
  child_customer_name: string | null
  child_customer_type: string | null
  child_customer_status: CustomerStatus
  child_created_at: string
  child_updated_at: string
  meta: Record<string, unknown>
}


export enum SSOConfigStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  SUSPENDED = 'suspended',
  DELETED = 'deleted',
}

export interface ICustomerSSOConfig {
  id: string
  customer_id: string
  status: SSOConfigStatus
  data: Record<string, unknown>
  data_schema: string // TODO: enum
  meta: Record<string, unknown>
  created_at: string
  updated_at: string
}

export enum CustomerProperty {
  ADDRESS = "address",
  LOGO_PATH = "logo_path",
  OPCO_LOGO_PATH = "opco_logo_path",
  UTILITY_ACCOUNT_ID = "utility_account_id",
  DEFAULT_ALLOCATION = "allocation_percent_ten_thousandths",
  LAT_LNG = "location_lat_lng",
  TIMEZONE = "official_timezone",
}


export interface IFetchCustomerResponse {
  customer: ICustomer
}

export interface IFetchCustomerPageResponse {
  data: ICustomer[]
  meta: {
    pagination: IPagination
  }
}

export const dataToDisplayValues = (customer?: ICustomer) => {

  return {
    // base fields
    name: customer?.name || '',
    status: customer?.status || CustomerStatus.ACTIVE,
  };
};

export const displayValuesToRequestData = (values: ReturnType<typeof dataToDisplayValues>) => {
  // TODO: stricter typing?
  const data: Record<string, unknown> = {};

  if (isDefined(values.name)) {
    data.name = values.name;
  }

  if (isDefined(values.status)) {
    data.status = values.status;
  }

  return data;
};

export const errorResponseToDisplayErrors = (errs: Record<string, string[]>) => {
  const displayErrors: Record<string, string> = {};

  const fieldMap: Record<string, string> = {
    name: 'name',
    status: 'description',
  };

  Object.entries(errs).forEach(([field, errors]) => {
    if (errors.length && fieldMap[field]) {
      displayErrors[fieldMap[field]] = errors[0];
    }
  });

  return displayErrors;
};


export const getCustomerType = (customer: ICustomer) => {
  if (!customer) {
    return false;
  }
  return customer.customer_type;
};

export const isUtilityCustomerAccount = (customer: ICustomer) => {
  return getCustomerType(customer) === CustomerType.UTILITY_CUSTOMER_ACCOUNT;
};


export const isUtilityParentCustomer = (customer?: ICustomer) => {
  if (!customer) {
    return false;
  }
  return getCustomerType(customer) === CustomerType.UTILITY_PARENT_CUSTOMER;
};

export const isOpcoCustomer = (customer?: ICustomer) => {
  if (!customer) {
    return false;
  }
  return getCustomerType(customer) === CustomerType.UTILITY_OPCO;
};

export const isUtilityCustomer = (customer?: ICustomer) => {
  if (!customer) {
    return false;
  }
  return getCustomerType(customer) === CustomerType.UTILITY_CUSTOMER;
};

function getCustomerProperty<T>(customer: ICustomer, prop: CustomerProperty): T | undefined {
  return customer.attributes.find(attr => attr.name === prop)?.value as T | undefined;
}

export const getCustomerLogoPath = (customer: ICustomer) => getCustomerProperty<string>(customer, CustomerProperty.LOGO_PATH)

export const getCustomerOpcoLogoPath = (customer: ICustomer) => getCustomerProperty<string>(customer, CustomerProperty.OPCO_LOGO_PATH)

export const getCustomerUtilityAccountId = (customer: ICustomer) => getCustomerProperty<string>(customer, CustomerProperty.UTILITY_ACCOUNT_ID)

export const getCustomerDefaultAllocationPctTenThousandths = (customer: ICustomer) => getCustomerProperty<number>(customer, CustomerProperty.DEFAULT_ALLOCATION)

export const getCustomerAddress = (customer: ICustomer) => getCustomerProperty<string>(customer, CustomerProperty.ADDRESS)

export const getCustomerLatLng = (customer: ICustomer) => getCustomerProperty<string[] | number[]>(customer, CustomerProperty.LAT_LNG)

export interface ICustomerExternalId {
  id: string
  customer_id: string
  external_id: string
  created_at: string
  updated_at?: string | null
  meta: Record<string, unknown>
}