import { createSelector } from "reselect";
import { byId, customersById } from "shared/store/customers/selectors";
import { getCurrentCustomer, getOpcos } from "shared/store/user/selectors";
import { Customer, CustomerProperty, getCustomerType, ICustomer } from "shared/types/customer";
import { isValidTimeZone } from "shared/utils/dates";

import { RootState } from "store";

const root = (state: RootState) => state.amp__ui;

export const viewingOpCoId = createSelector(
  root,
  (ui) => ui.viewingOpCoId || '',
);

export const getViewingOpCoId = createSelector(
  viewingOpCoId,
  getCurrentCustomer,
  (oci, currentCustomer) => {
    if (currentCustomer?.isOpco) {
      return currentCustomer.id;
    } else {
      return oci;
    }
  }
);

// return the list of opcos that the current user is viewing
export const getViewingCustomerIds = createSelector(
  getViewingOpCoId,
  getOpcos,
  getCurrentCustomer,
  (opcoId, opcos, currentCustomer) => {
    const currentCustomerId = currentCustomer?.id;
    const useCurrentCustomer = !opcoId && opcos.length === 0 && !!currentCustomerId;
    return useCurrentCustomer ? [currentCustomerId] : (opcoId ? [opcoId] : opcos.map(d => d.id));
  }
)

const getDataDates = (customer?: ICustomer) => {
  const latestGen = customer?.attributes.find(a => a.name === 'latest_amp_generation_date')?.value as string | undefined;
  const latestLoad = customer?.attributes.find(a => a.name === 'latest_amp_consumption_date')?.value as string | undefined;

  return {
    latestGen,
    latestLoad,
  };
}

export const getLatestLoadAndGenDataDates = createSelector(
  getViewingOpCoId,
  getOpcos,
  getCurrentCustomer,
  (viewingOpcoId, opcos, currentCustomer) => {
    const opcoIds = opcos.map(({id}) => id);
    if (currentCustomer?.isOpco) {
      return getDataDates(currentCustomer);
    } else if (!viewingOpcoId) {
      // get the latest opco dates from all opcos
      if (currentCustomer?.isUtility) {
        const opcoDates = opcos.map(opco => getDataDates(opco));
        return opcoDates.reduce((soFar, {latestGen, latestLoad}) => {
          const nextResult: ReturnType<typeof getDataDates> = {...soFar};
          if (!nextResult.latestGen && !!latestGen) {
            nextResult.latestGen = latestGen;
          } else if (!!latestGen && !!nextResult.latestGen && new Date(latestGen) > new Date(nextResult.latestGen)) {
            nextResult.latestGen = latestGen;
          }

          if (!nextResult.latestLoad && !!latestLoad) {
            nextResult.latestLoad = latestLoad;
          } else if (!!latestLoad && !!nextResult.latestLoad && new Date(latestLoad) > new Date(nextResult.latestLoad)) {
            nextResult.latestLoad = latestLoad;
          }
          return nextResult;
        }, {latestGen: undefined, latestLoad: undefined});
      } else {
        return getDataDates(currentCustomer || undefined);
      }
    } else if (!opcoIds.includes(viewingOpcoId)) {
      return getDataDates(currentCustomer || undefined);
    } else {
      return getDataDates(opcos.find(({id}) => id === viewingOpcoId));
    }
  }
)

// return the viewing operating customer ID, or, if not a utility,
// return the current customer ID
const getViewingCustomerId = createSelector(
  getViewingOpCoId,
  getCurrentCustomer,
  (opcoId, currentCustomer) => {
    if (currentCustomer?.isUtility && opcoId) {
      return opcoId
    } else {
      return currentCustomer?.id;
    }
  }
);

// returns the opco customer for a utility user viewing an opco
// returns the current user's customer (or undefined) in all other cases
export const getViewingCustomer = createSelector(
  getViewingCustomerId,
  byId,
  (customerId, custsById) => {
    if (!customerId) return undefined;

    const custData = custsById[customerId];

    if (!custData) return undefined;

    return new Customer(custData);
  }
);


export const getViewingCustomerType = createSelector(
  getViewingOpCoId,
  customersById,
  getCurrentCustomer,
  (viewingOpcoId, customersById, currentCustomer) => {
    if (viewingOpcoId) {
      const opco: ICustomer | undefined = customersById[viewingOpcoId];
      if (!opco && currentCustomer) {
        return getCustomerType(currentCustomer);
      } else if (opco) {
        return getCustomerType(opco);
      }
    } else if (currentCustomer) {
      return getCustomerType(currentCustomer);
    }
  }
)

export const getViewingTimeZone = createSelector(
  getViewingCustomerId,
  byId,
  (customerId, custsById) => {
    const customer = custsById[customerId || ''];
    if (!customer) {
      return 'UTC';
    }

    const tzName = customer.attributes.find(a => a.name === CustomerProperty.TIMEZONE)?.value as string;
    if (!tzName) {
      return 'UTC';
    } else if (!isValidTimeZone(tzName)) {
      console.warn(`${tzName} is not a supported timezone`);
      return 'UTC';
    }
    return tzName;
  }

);