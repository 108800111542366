import { Accordion, AppShell, Avatar, Box, Group, Menu, Stack, Text, rem } from '@mantine/core';
import { IconBriefcase, IconBuildingFactory, IconCertificate, IconChevronRight, IconRefresh, IconSitemap, IconUsers } from '@tabler/icons-react';
import { useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { setViewingOpco } from 'amp/store/ui/slice';
import AppPicker from 'shared/components/AppPicker';
import CustomerTypeRequired from 'shared/components/CustomerTypeRequired/customerTypeRequired';
import FeatureGate from 'shared/components/FeatureGate/featureGate';
import RoleRequired from 'shared/components/RoleRequired/roleRequired';
import { getCurrentCustomer, getCurrentUser, getOpcos } from 'shared/store/user/selectors';
import { ALL_CUSTOMER_TYPES, CustomerType, getCustomerLogoPath, getCustomerOpcoLogoPath, isUtilityCustomer, isUtilityParentCustomer } from 'shared/types/customer';
import { UserRole } from 'shared/types/user';
import { useAppDispatch, useAppSelector } from 'store';
import './style.css';

interface NavbarLinkProps {
  icon: typeof IconUsers
  label: string
  href: string
  roleRequired: UserRole
  customerTypesRequired: CustomerType[]
  featureGate?: string
  end?: boolean
  onMouseEnter?: () => void
}

const NavbarLink = ({ icon: Icon, label, href, onMouseEnter, roleRequired, customerTypesRequired, featureGate }: NavbarLinkProps) => {
  if (featureGate) {
    return (
      <FeatureGate propertyName={featureGate} key={label}>
        <CustomerTypeRequired allowedTypes={customerTypesRequired}>
          <RoleRequired role={roleRequired}>
            <NavLink to={href} className="main-navigation--navigation-link" onMouseEnter={onMouseEnter}>
              <Group justify="flex-start" align="center" w="100%" p={4}>
                <Icon color="white" style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                <Text c="white" fz={12}>{label}</Text>
              </Group>
            </NavLink>
          </RoleRequired>
        </CustomerTypeRequired>
      </FeatureGate>
    );
  } else {
    return (
      <CustomerTypeRequired allowedTypes={customerTypesRequired}>
        <RoleRequired role={roleRequired}>
          <NavLink to={href} className="main-navigation--navigation-link" onMouseEnter={onMouseEnter}>
            <Group justify="flex-start" align="center" w="100%" p={4}>
              <Icon color="white" style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
              <Text c="white" fz={12}>{label}</Text>
            </Group>
          </NavLink>
        </RoleRequired>
      </CustomerTypeRequired>
    );
  }
};


const Navigation = () => {
  const user = useAppSelector(getCurrentUser);
  const customer = useAppSelector(getCurrentCustomer);
  const opcos = useAppSelector(getOpcos);
  const selectedOpcoId = useAppSelector(getViewingOpCoId);
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  const selectedOpco = opcos.find(c => c.id === selectedOpcoId);

  const linkData = useMemo(() => ([
      {
        label: 'Devices',
        icon: IconBuildingFactory,
        href: `/registry/devices?oci=${selectedOpcoId}`,
        roleRequired: UserRole.VISITOR, // TODO
        customerTypesRequired: ALL_CUSTOMER_TYPES, // TODO
      },
      {
        label: 'Certificates',
        icon: IconCertificate,
        href: `/registry/certificates?oci=${selectedOpcoId}`,
        roleRequired: UserRole.VISITOR, // TODO
        customerTypesRequired: ALL_CUSTOMER_TYPES, // TODO
      },
      {
        label: 'Claims',
        icon: IconBriefcase,
        href: `/registry/claims?oci=${selectedOpcoId}`,
        roleRequired: UserRole.VISITOR, // TODO
        customerTypesRequired: ALL_CUSTOMER_TYPES, // TODO
      },
      {
        label: 'Registry Sync',
        icon: IconRefresh,
        href: `/registry/sync?oci=${selectedOpcoId}`,
        roleRequired: UserRole.VISITOR, // TODO
        customerTypesRequired: ALL_CUSTOMER_TYPES, // TODO
      },
      {
        label: 'Attestation',
        icon: IconSitemap,
        href: `/registry/attestation?oci=${selectedOpcoId}`,
        roleRequired: UserRole.VISITOR, // TODO
        customerTypesRequired: ALL_CUSTOMER_TYPES, // TODO
      },
    ]
  ), [selectedOpcoId]);

  const labelToPrefetch: Record<string, () => void> = useMemo(() => ({
  }), []);

  const onSelectOpco = (newOpCo: string | null) => {
    dispatch(setViewingOpco(newOpCo || null));
    // should this be replace?
    nav(`/dashboard/inventory?oci=${newOpCo ? newOpCo : ''}`);
  };

  const getLogoPath = () => {
    // utility customers have a property for both their own logo as well as the opco logo. use opco here.
    if (customer && (isUtilityCustomer(customer) || isUtilityParentCustomer(customer))) {
      return getCustomerOpcoLogoPath(customer);
    } else {
      const currentCustomerLogoPath = customer && getCustomerLogoPath(customer);
      return selectedOpco ? getCustomerLogoPath(selectedOpco) : currentCustomerLogoPath;
    }
  }

  const logoPath = getLogoPath();
  return (
    <AppShell.Navbar>
      <nav className="main-navigation--container">
        {/* TODO: logic for navbar collapse, need designs */}
        <Group justify="space-between" p="20px 16px" w="100%" wrap="nowrap" align="center">
          <CustomerTypeRequired allowedTypes={[CustomerType.UTILITY, CustomerType.UTILITY_OPCO]}>
            <img src="/images/singularity.svg" width={61} height={16} alt="The Singularity Energy logo" />
          </CustomerTypeRequired>
          <AppPicker />
        </Group>

        {(selectedOpco || customer) && <img className="main-navigation--user-logo" src={logoPath || '/images/acme.svg'} alt="Your company logo" />}

        {opcos.length > 0 &&
          <Menu position="bottom">
            <Menu.Target>
              <Box className="main-navigation--change-opco-button" fz={14}>
                Change
              </Box>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={() => onSelectOpco(null)}>
                Full Inventory
              </Menu.Item>
              {opcos.map(opco => <Menu.Item onClick={() => onSelectOpco(opco.id)} key={opco.id}>
                {opco.name}
              </Menu.Item>)}
            </Menu.Dropdown>
          </Menu>
        }

        <div className="main-navigation--scroll-content">
          <div className="main-navigation--navigation-links">
            <Accordion multiple defaultValue={['inventory', 'management', 'outputs']}>
              {/* Each top level header in the accordion should respect the permissions of its tabs */}
              <RoleRequired role={UserRole.USER}>
                <Stack gap={4}>
                  {linkData.map(navLink => (
                    <NavbarLink
                      {...navLink}
                      key={navLink.label}
                      onMouseEnter={() => labelToPrefetch[navLink.label] && labelToPrefetch[navLink.label]()}
                    />
                  ))}
                </Stack>
              </RoleRequired>
            </Accordion>
          </div>
        </div>

        <NavLink to={`/dashboard/settings/preferences?oci=${selectedOpcoId}`} className="main-navigation--account-container">
          {({ isActive }) => <>
            <Group justify="flex-start" align="center">
              <Avatar color="white" bg="var(--color-green-2)" size={28}>{user?.name.slice(0, 1).toLocaleUpperCase()}</Avatar>
              <Text c="white">Account</Text>
            </Group>
            {isActive && <IconChevronRight color="white" />}
          </>
          }
        </NavLink>
      </nav>
    </AppShell.Navbar>
  );
};

export default Navigation;
