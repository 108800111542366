import { sum } from 'ramda';
import { API } from 'shared/api';
import { queries, staticDataQueries } from 'shared/api/queries';
import { AllocationSettlementReviewStatus, IAllocationDiagnosticItem, IAllocationRun, IAllocationRunInputData, IAllocationSettlementReview, IAllocationSummaryResult, ICreateAllocationReviewPayload, ICreateAllocationRunPayload, ICustomerAllocationSummaryResult, IProgramAllocationSummaryResult, IStandardDeliveryResult } from 'shared/types/allocation';
import { IPagination, IPaginationResponse } from 'shared/types/api';


export interface IListAllocationRunsArgs {
  page: number
  perPage: number
  customerId?: string | null
  statuses?: IAllocationRun['status'][]
}

export interface IEditAllocationRunPayload {
  runId: string
  customerId?: string | null
  body: {
    status: IAllocationRun['status']
    description?: string | null
  }
}

export interface IListAllocationDiagnosticsArgs {
  customerId?: string | null
  allocationRunId: string
  page: number
  perPage: number
  levels: string[]
  families: string[]
}

export interface IReleaseAllocationRunPayload {
  customer_ids?: string[] | null
  release_to_all_customers: boolean
  customer_report_name?: string
  customer_report_description?: string
}


export const fetchRunsPage = (qs: string) => API.get<IPaginationResponse<IAllocationRun>>(`/api/v2/allocation_runs/?${qs}`);

export const fetchAllocationRunInput = (runId: string, customerId: string) => API.get<IAllocationRunInputData>(`/${customerId}/allocation-runs/${runId}/input.json`)

export const fetchAllocationRun = (runId: string, customerId: string) => API.get<{allocation_run: IAllocationRun}>(`/api/v2/allocation_runs/${runId}/?customer_id=${customerId}`)


const allocationsPage: {type: 'AllocationRun', id: string} = {type: 'AllocationRun', id: 'PAGE'};
const allocationSettlementReviewsPage: {type: 'AllocationSettlementReview', id: string} = {type: 'AllocationSettlementReview', id: 'PAGE'};


const allocationRunsQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    createRun: build.mutation<{ allocation_run: IAllocationRun }, { data: ICreateAllocationRunPayload, customerId?: string | null }>({
      query: ({ data, customerId }) => {
        return {
          url: `/v2/allocation_runs/?${customerId ? `customer_id=${customerId}` : ''}`,
          method: 'POST',
          body: data,
        }
      },

      invalidatesTags: [allocationsPage],
    }),

    paginateAllocationRuns: build.query<IPaginationResponse<IAllocationRun>, IListAllocationRunsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('page', body.page.toString());
        qs.set('per_page', body.perPage.toString());

        if (body.customerId) {
          qs.set('customer_id', body.customerId);
        }

        if (body.statuses) {
          body.statuses.forEach(status => {
            qs.append('statuses', status);
          })
        }

        return {
          url: `/v2/allocation_runs/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: [allocationsPage],
    }),

    fetchAllocationRun: build.query<{ allocation_run: IAllocationRun }, { id: string, customerId?: string | null }>({
      query: ({ id, customerId }) => `/v2/allocation_runs/${id}?${customerId ? `customer_id=${customerId}` : ''}`,

      providesTags: (res) => res ? [{ type: 'AllocationRun', id: res.allocation_run.id}] : []
    }),

    fetchUtilityAllocationRuns: build.query<IPaginationResponse<IAllocationRun>, {page: number, perPage: number, customerIds: string[], statuses?: IAllocationRun['status'][]}>({
      async queryFn({perPage, page, statuses, customerIds}, _queryApi, _extra, fetchWithBQ) {
        const qs = new URLSearchParams();
        qs.set('per_page', perPage.toString());
        qs.set('page', page.toString());
        statuses?.forEach(status => {
          qs.append('statuses', status);
        })

        const runsById: Record<string, IAllocationRun> = {};
        const allPaginations: IPagination[] = [];

        const runsRes = await Promise.all(customerIds.map(cid => fetchWithBQ(`/v2/allocation_runs/?${qs}&customer_id=${cid}`)));
        runsRes.forEach(async res => {
          if (res.error) {
            // TODO: error handling
            return;
          }
          const data = res.data as IPaginationResponse<IAllocationRun>;
          allPaginations.push(data.meta.pagination);
          data.data.forEach(prog => {
            runsById[prog.id] = prog;
          });
        })
        const pagination: IPagination = {
          this: allPaginations[0]?.this || 0,
          next: Math.max(...allPaginations.map(p => p.next || 0)),
          prev: Math.max(...allPaginations.map(p => p.prev || 0)),
          total_items: sum(allPaginations.map(p => p.total_items)),
          first: 1,
          last: Math.max(...allPaginations.map(p => p.last)),
        }
        return {data: {data: Object.values(runsById), meta: {pagination}}};
      },
      providesTags: (res) => res ? [allocationsPage, ...res.data.map(allocationRun => ({type: "AllocationRun" as const, id: allocationRun.id}))] : []
    }),

    paginateDiagnostics: build.query<IPaginationResponse<IAllocationDiagnosticItem>, IListAllocationDiagnosticsArgs>({
      query: (args) => {
        const qs = new URLSearchParams();

        if (args.customerId) {
          qs.set('customer_id', args.customerId);
        }

        qs.set('page', args.page.toString());
        qs.set('per_page', args.perPage.toString());

        args.families.forEach(family => qs.append('family', family));

        args.levels.forEach(level => qs.append('level', level));
        return `/v2/allocation_runs/${args.allocationRunId}/diagnostics?${qs}`
      },
    }),

    patchAllocationRun: build.mutation<{ allocation_run: IAllocationRun }, IEditAllocationRunPayload>({
      query: ({ runId, customerId, body }) => ({
        url: `/v2/allocation_runs/${runId}/?${customerId ? `customer_id=${customerId}` : ''}`,
        method: 'PATCH',
        body,
      }),

      invalidatesTags: (res) => res ? [allocationsPage, { type: 'AllocationRun', id: res.allocation_run.id }] : [],
    }),

    releaseAllocationToCustomers: build.mutation<{ status: string }, { data: IReleaseAllocationRunPayload, runId: string }>({
      query: ({ data, runId }) => {
        return {
          url: `/v2/allocation_runs/${runId}/release-to-customers`,
          method: 'POST',
          body: data,
        }
      },

      invalidatesTags: [{ type: 'CustomerReport', id: 'PAGE' }],
    }),

    listReviewsForAllocation: build.query<{data: IAllocationSettlementReview[]}, { id: string, customerId?: string }>({
      query: ({ id, customerId }) => `/v2/allocation_runs/${id}/reviews?${customerId ? `customer_id=${customerId}` : ''}`,

      providesTags: (res) => res ? [allocationSettlementReviewsPage, ...res.data.map(sr => ({ type: allocationSettlementReviewsPage.type, id: sr.id }))] :[],
    }),

    createAllocationReviewers: build.mutation<{reviews: IAllocationSettlementReview[]}, { data: ICreateAllocationReviewPayload, runId: string, customerId?: string | null }>({
      query: ({ data, runId, customerId }) => {
        return {
          url: `/v2/allocation_runs/${runId}/reviews?${customerId ? `customer_id=${customerId}` : ''}`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: (res, err, arg) => res ? [allocationsPage, allocationSettlementReviewsPage, { type: 'AllocationRun', id: arg.runId }] : [],

    }),

    updateAllocationSettlementApproval: build.mutation<{review: IAllocationSettlementReview}, { reviewId: string, newStatus: AllocationSettlementReviewStatus, customerId?: string | null }>({
      query: ({ reviewId, newStatus, customerId }) => {
        return {
          url: `/v2/allocation_runs/reviews/${reviewId}/${newStatus}?${customerId ? `customer_id=${customerId}` : ''}`,
          method: 'POST',
        }
      },

      invalidatesTags: (res) => res ? [allocationsPage, allocationSettlementReviewsPage, { type: 'AllocationRun', id: res.review.allocation_run_id }] : [],
    }),

    settleAllocationRun: build.mutation<{ status: 'OK' }, { runId: string, customerId?: string | null }>({
      query: ({ runId, customerId }) => {
        return {
          url: `/v2/allocation_runs/${runId}/settle?${customerId ? `customer_id=${customerId}` : ''}`,
          method: 'POST',
        }
      },

      invalidatesTags: (res, err, args) => res ? [allocationsPage, allocationSettlementReviewsPage, { type: 'AllocationRun', id:  args.runId}] : [],
    }),

    resendReviewRequestEmail: build.mutation<{ status: 'OK' }, { runId: string, reviewId: string, customerId?: string | null }>({
      query: ({ runId, reviewId, customerId }) => {
        return {
          url: `/v2/allocation_runs/${runId}/reviews/${reviewId}/remind?${customerId ? `customer_id=${customerId}` : ''}`,
          method: 'POST',
        }
      },

      invalidatesTags: (res, err, args) => res ? [allocationsPage, allocationSettlementReviewsPage, { type: 'AllocationRun', id:  args.runId}] : [],
    }),
  }),
  overrideExisting: false,
});

const allocationResultsQueries = staticDataQueries.injectEndpoints({
  endpoints: (build) => ({
    fetchAllocationRunSummaryResults: build.query<IAllocationSummaryResult, { runId: string, rootCustomerId: string }>({
      query: ({ runId, rootCustomerId }) => ({
        url: `/${rootCustomerId}/allocation-runs/${runId}/summary_output.json`,
      }),

      providesTags: (res, _, args) => res ? [{ type: 'AllocationRunSummary', id: args.runId }] : [],
    }),

    fetchAllocationRunInputData: build.query<IAllocationRunInputData, { runId: string, rootCustomerId: string }>({
      query: ({ runId, rootCustomerId }) => ({
        url: `/${rootCustomerId}/allocation-runs/${runId}/input.json`,
      }),

      providesTags: (res, _, args) => res ? [{ type: 'AllocationRunInput', id: args.runId }] : [],
    }),

    fetchAllocationRunCustomerResults: build.query<ICustomerAllocationSummaryResult, { runId: string, rootCustomerId: string, subCustomerId: string }>({
      query: ({ runId, rootCustomerId, subCustomerId }) => ({
        url: `/${rootCustomerId}/allocation-runs/${runId}/customer/${subCustomerId}_output.json`,
      }),

      providesTags: (res, _, args) => res ? [{ type: 'AllocationRunCustomerResult', id: `${args.runId}_${args.subCustomerId}` }] : [],
    }),

    fetchAllocationRunStdDeliveryResults: build.query<IStandardDeliveryResult, { runId: string, rootCustomerId: string }>({
      query: ({ runId, rootCustomerId }) => ({
        url: `/${rootCustomerId}/allocation-runs/${runId}/std_delivery_output.json`,
      }),

      providesTags: (res, _, args) => res ? [{ type: 'AllocationRunStdDeliveryResult', id: args.runId }] : [],
    }),

    fetchAllocationRunProgramResult: build.query<IProgramAllocationSummaryResult, { runId: string, rootCustomerId: string, programId: string }>({
      query: ({ runId, rootCustomerId, programId }) => ({
        url: `/${rootCustomerId}/allocation-runs/${runId}/program/${programId}_output.json`,
      }),

      providesTags: (res, _, args) => res ? [{ type: 'AllocationRunProgramResult', id: `${args.runId}_${args.programId}` }] : [],
    }),

    fetchReportSummaryResults: build.query<IAllocationSummaryResult, { summaryLocation: string }>({
      query: ({ summaryLocation }) => ({
        url: `/${summaryLocation}`,
      }),

      // TODO tags?
      // providesTags: (res, _, args) => res ? [{ type: 'AllocationRunSummary', id: args.runId }] : [],
    }),

    fetchReportInputData: build.query<IAllocationRunInputData, { inputLocation: string }>({
      query: ({ inputLocation }) => ({
        url: `/${inputLocation}`,
      }),

      // providesTags: (res, _, args) => res ? [{ type: 'AllocationRunInput', id: args.runId }] : [],
    }),

    fetchReportCustomerResults: build.query<ICustomerAllocationSummaryResult, { customerResultsLocation: string }>({
      query: ({ customerResultsLocation }) => ({
        url: `/${customerResultsLocation}`,
      }),

      // providesTags: (res, _, args) => res ? [{ type: 'AllocationRunCustomerResult', id: `${args.runId}_${args.subCustomerId}` }] : [],
    }),

    fetchReportStdDeliveryResults: build.query<IStandardDeliveryResult, { stdDeliveryResultsLocation: string }>({
      query: ({ stdDeliveryResultsLocation }) => ({
        url: `/${stdDeliveryResultsLocation}`,
      }),

      // providesTags: (res, _, args) => res ? [{ type: 'AllocationRunStdDeliveryResult', id: args.runId }] : [],
    }),

    fetchReportProgramResult: build.query<IProgramAllocationSummaryResult, { programResultsLocation: string }>({
      query: ({ programResultsLocation }) => ({
        url: `/${programResultsLocation}`,
      }),

      // providesTags: (res, _, args) => res ? [{ type: 'AllocationRunProgramResult', id: `${args.runId}_${args.programId}` }] : [],
    }),

  }),
  overrideExisting: false,
});

export const {
  usePrefetch,
  usePaginateAllocationRunsQuery,
  useFetchAllocationRunQuery,
  useFetchUtilityAllocationRunsQuery,
  useCreateRunMutation,
  usePaginateDiagnosticsQuery,
  usePatchAllocationRunMutation,
  useListReviewsForAllocationQuery,
  useCreateAllocationReviewersMutation,
  useReleaseAllocationToCustomersMutation,
  useUpdateAllocationSettlementApprovalMutation,
  useSettleAllocationRunMutation,
  useResendReviewRequestEmailMutation,
  endpoints,
} = allocationRunsQueries;

export const {
  useFetchAllocationRunSummaryResultsQuery,
  useFetchAllocationRunInputDataQuery,
  useFetchAllocationRunCustomerResultsQuery,
  useFetchAllocationRunStdDeliveryResultsQuery,
  useFetchAllocationRunProgramResultQuery,
  useFetchReportCustomerResultsQuery,
  useFetchReportInputDataQuery,
  useFetchReportProgramResultQuery,
  useFetchReportStdDeliveryResultsQuery,
  useFetchReportSummaryResultsQuery,
} = allocationResultsQueries;