import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGeneratorAssignment } from 'shared/types/assignment';
import { IProgram } from 'shared/types/program';
import { ISubscription } from 'shared/types/subscription';

interface IProgramSlice {
  byId: Record<string, IProgram>
  subscriptionsById: Record<string, ISubscription>,
  assignmentsById: Record<string, IGeneratorAssignment>,
}

const initialState: IProgramSlice = {
  byId: {},
  subscriptionsById: {},
  assignmentsById: {},
}


const programsSlice = createSlice({
  name: 'amp__programs',
  initialState,
  reducers: {
    receivePrograms: (state, action: PayloadAction<IProgram[]>) => {
      action.payload.forEach(program => {
        state.byId[program.id] = program;
      });
    },

    receiveSubscriptions: (state, action: PayloadAction<ISubscription[]>) => {
      action.payload.forEach(sub => {
        state.subscriptionsById[sub.id] = sub;
      });
    },

    receiveAssignments: (state, action: PayloadAction<IGeneratorAssignment[]>) => {
      action.payload.forEach(assignment => {
        state.assignmentsById[assignment.id] = assignment;
      });
    },
  },
});


export const { receivePrograms, receiveSubscriptions, receiveAssignments } = programsSlice.actions;
export default programsSlice.reducer;