import { IFetchAggregatedEventsArgs } from "amp/api/assetEvents";
import { IForecast, IForecastData, useFetchForecastForAssetQuery } from "amp/api/assetForecasts";
import { IListGeneratorsArgs, useFetchGeneratorQuery, usePaginateGeneratorsQuery } from "amp/api/generators";
import { useFetchUtilityInventorySummaryQuery, useFetchUtilitySourceFacetsQuery, useFetchUtilitySourcesQuery } from "amp/api/sources";
import { getViewingCustomerIds, getViewingOpCoId } from "amp/store/ui/selectors";
import { AxiosResponse, isAxiosError } from "axios";
import { difference } from "ramda";
import { useEffect, useState } from "react";
import { API } from "shared/api";
import { AggregationTypes, IAggregatedAssetEvent } from "shared/types/aggregatedEvents";
import { IPaginationResponse } from "shared/types/api";
import { IGenerator } from "shared/types/generator";
import { useAppDispatch, useAppSelector } from "store";
import { getAssignmentsForGeneratorId, getGeneratorById, getGeneratorsById, getSourcesByIds } from "./selectors";
import { receiveGeneratorAssignments, receiveGenerators, receiveSources } from "./slice";

export const useGenerator = (generatorId: string, customerId?: string, skip?: boolean) => {

  const oci = useAppSelector(getViewingOpCoId);
  const res = useFetchGeneratorQuery({id: generatorId, customerId: customerId || oci}, { skip: !generatorId || skip});
  const dispatch = useAppDispatch();
  const gen = useAppSelector(s => getGeneratorById(s, generatorId));

  useEffect(() => {
    if (res.data) {
      dispatch(receiveGenerators([res.data.generator]));
      dispatch(receiveGeneratorAssignments({assignments: res.data.assignments, generatorId}));
    }
  }, [res.data, dispatch, generatorId]);

  return {
    ...res,
    isFirstTimeLoading: res.isLoading,
    isRefreshingData: res.isFetching,
    data: (gen as IGenerator | undefined),
  }
};

export const useGeneratorsPage = ({ page, perPage, nameSearch, fuelCategory, usState, customerId }: IListGeneratorsArgs) => {
  const oci = useAppSelector(getViewingOpCoId);
  const res = usePaginateGeneratorsQuery({ page, perPage, nameSearch, fuelCategory, usState, customerId: customerId || oci });
  const dispatch = useAppDispatch();
  const generatorIds = res.data?.data.map(({ id }) => id) || [];
  const generators = useAppSelector(s => getGeneratorsById(s, generatorIds));

  useEffect(() => {
    if (res.data) {
      dispatch(receiveGenerators(res.data.data));
    }
  }, [res.data, dispatch, page, perPage]);

  return {
    ...res,
    isFirstTimeLoading: res.isLoading,
    isRefreshingData: res.isFetching,
    data: generators,
    pagination: res.data?.meta.pagination,
  }
}


export const useGeneratorAssignments = (generatorId: string) => {
  const oci = useAppSelector(getViewingOpCoId);
  const res = useFetchGeneratorQuery({id: generatorId, customerId: oci});
  const dispatch = useAppDispatch();
  const assignments = useAppSelector(s => getAssignmentsForGeneratorId(s, generatorId));

  useEffect(() => {
    if (res.data) {
      dispatch(receiveGenerators([res.data.generator]));
      dispatch(receiveGeneratorAssignments({assignments: res.data.assignments, generatorId}));
    }
  }, [res.data, dispatch, generatorId]);

  return {
    ...res,
    isFirstTimeLoading: res.isLoading,
    isRefreshingData: res.isFetching,
    data: assignments,
  }

}

export const useGeneratorForecast = (generatorId: string, year: number, customerId?: string) => {
  const oci = useAppSelector(getViewingOpCoId);
  const forecastRes = useFetchForecastForAssetQuery({asset_id: generatorId, year, customerId: customerId || oci}, {skip: !generatorId});
  const [forecastData, setForecastData] = useState<IForecastData[]>();
  const [resultsLoading, setResultsLoading] = useState(true);

  useEffect(() => {
    const forecastBatch = forecastRes.data?.event_forecast_batch
    if (forecastBatch && !forecastData) {
      API.get<{results: IForecastData[]}>(`/${forecastBatch.customer_id}/forecast-data/${forecastBatch.id}/output.json`, {withCredentials: true})
        .then((res) => {
          setForecastData(res.data.results);
        })
        .catch((err) => {
          console.warn('TODO: capture error', err);
        })
        .finally(() => setResultsLoading(false));
    }
  }, [forecastRes.data, forecastData]);

  return {
    ...forecastRes,
    isLoading: forecastRes.isLoading || resultsLoading,
    isFetching: forecastRes.isFetching || resultsLoading,
    isFirstTimeLoading: forecastRes.isLoading || resultsLoading,
    isRefreshingData: forecastRes.isFetching || resultsLoading,
    data: forecastData,
  }
}

type OutputResults = {generatorId: string, response: Promise<AxiosResponse<{results: IForecastData[]}>>}[];

const isErrorDueToMissingForecast = (res: AxiosResponse) => {
  return isAxiosError(res) && res.response?.status === 404 && res.response?.data?.error_code === 'event-forecast-batch-not-found';
};

export const useGeneratorsForecast = (generatorIds: string[], years: number[], customerId?: string) => {
  const oci = useAppSelector(getViewingOpCoId);
  // TODO: perhaps aggregating forecasts like this should be done on the backend, not the frontend...
  const [loadingBatches, setLoadingBatches] = useState(false);
  const [loadingResults, setLoadingResults] = useState(false);
  const [error, setError] = useState(false);
  const [outputData, setOutputData] = useState<IForecastData[]>();
  const [nonGroupedOutputData, setNonGroupedOutputData] = useState<Record<string, IForecastData[]>>();
  const [outputResultsPromises, setOutputResultPromises] = useState<OutputResults>();
  const [fetchedGenIds, setFetchedGenIds] = useState<string[]>([]);
  const [failedGenAndYears, setFailedGenAndYears] = useState<{generatorId: string, year: number, customerId: string}[]>([]);

  useEffect(() => {
    if (!loadingBatches && !loadingResults && !error && !outputResultsPromises) {
      setFetchedGenIds(generatorIds);
      setLoadingBatches(true);
      const urls: {url: string, generatorId: string, customerId: string, year: number}[] = [];
      generatorIds.forEach(genId => {
        years.forEach(year => {
          urls.push({
            url: `/api/v2/assets/${genId}/forecast?year=${year}&customer_id=${customerId || oci}`,
            customerId: customerId || oci,
            year,
            generatorId: genId,
          });
        });
      });
      const promises = urls.map(({url}) => API.get<{event_forecast_batch: IForecast}>(url, {withCredentials: true}));
      Promise.all(promises).then((results) => {
        const failures: {generatorId: string, year: number, customerId: string}[] = [];
        setOutputResultPromises(results.map((res, idx) => {
          if (isErrorDueToMissingForecast(res)) {
            const requestData = urls[idx];
            if (!requestData) {
              // this should never happen
              console.warn("Failed to find request data from index");
              return undefined;
            }
            const {generatorId, customerId, year} = requestData;
            failures.push({generatorId, customerId, year});
            return undefined;
          } else if (isAxiosError(res)) {
            // TODO: report the failed requests to Sentry - we should know about the missing forecasts
            return undefined;
          } else if (res.status !== 200) {
            // TODO: report the failed requests to Sentry - we should know about the missing forecasts
            return undefined;
          }
          const forecast = res.data.event_forecast_batch;
          const generatorId = res.data.event_forecast_batch.asset_id;
          return {
            generatorId,
            response: API.get<{results: IForecastData[]}>(`/${forecast.customer_id}/forecast-data/${forecast.id}/output.json`, {withCredentials: true})
          };
        }).filter(res => !!res) as {generatorId: string, response: Promise<AxiosResponse<{results: IForecastData[]}, any>>}[]);
        setFailedGenAndYears(failures);
        setLoadingBatches(false);
      }).catch((err) => {
        setError(true);
        setLoadingBatches(false);
        setLoadingResults(false);
      });
    }
  }, [loadingBatches, error, outputResultsPromises, generatorIds, years, loadingResults, oci, customerId]);

  // wipe the output.json request promises if the generatorIds changes
  useEffect(() => {
    const diffLtr = difference(generatorIds, fetchedGenIds);
    const diffRtl = difference(fetchedGenIds, generatorIds);
    if (diffLtr.length !== 0 || diffRtl.length !== 0) {
      setOutputResultPromises(undefined);
      setOutputData(undefined);
      setNonGroupedOutputData(undefined);
      setError(false);
    }
  }, [generatorIds, fetchedGenIds]);

  useEffect(() => {
    if (outputResultsPromises?.length && !outputData && !loadingBatches && !loadingResults && !error) {
      setLoadingResults(true);
      const finalDataByStartDate: Record<string, number> = {};
      const finalDataByGeneratorId: Record<string, IForecastData[]> = {};
      Promise.all(outputResultsPromises.map(({response}) => response)).then((res) => {
        if (res) {
          res.forEach(({data}, idx) => {
            const genId = outputResultsPromises[idx].generatorId;
            data.results.forEach(({start_date, y_axis_value_wh}) => {
              if (finalDataByStartDate[start_date]) {
                finalDataByStartDate[start_date] += y_axis_value_wh;
              } else {
                finalDataByStartDate[start_date] = y_axis_value_wh;
              }
            });
            if (!finalDataByGeneratorId[genId]) {
              finalDataByGeneratorId[genId] = data.results;
            } else {
              finalDataByGeneratorId[genId] = finalDataByGeneratorId[genId].concat(data.results);
            }
          });
        }
        const sorted = Object.entries(finalDataByStartDate)
          .sort((x1, x2) => new Date(x1[0]).valueOf() - new Date(x2[0]).valueOf());
        const formatted = sorted.map(([start_date, y_axis_value_wh]) => ({start_date, y_axis_value_wh}));
        setOutputData(formatted);
        setNonGroupedOutputData(finalDataByGeneratorId);
        setLoadingResults(false);
      }).catch(() => {
        setError(true);
        setLoadingResults(false);
      });
    }
  }, [outputResultsPromises, loadingBatches, loadingResults, error, outputData]);


  return {
    loading: loadingBatches || loadingResults,
    error,
    data: outputData,
    nonGroupedData: nonGroupedOutputData,
    failures: failedGenAndYears,
  }
};


export const useUtilitySources = ({ page, perPage, usState, nameSearch, fuelCategory, assetTypes }: IListGeneratorsArgs) => {
  const dispatch = useAppDispatch();
  const customerIds = useAppSelector(getViewingCustomerIds);

  const res = useFetchUtilitySourcesQuery({
    page,
    perPage,
    customerIds,
    nameSearch,
    usState,
    fuelCategory,
    assetTypes,
  });

  if (res.data) {
    dispatch(receiveSources(res.data.data));
  }

  const data = useAppSelector(s => getSourcesByIds(s, res.data?.data.map(({id}) => id) || []));

  return {
    ...res,
    data,
    pagination: res.data?.meta.pagination,
  };
};

export const useUtilitySourceFacets = () => {
  const customerIds = useAppSelector(getViewingCustomerIds);

  const res = useFetchUtilitySourceFacetsQuery({customerIds});

  return res
};


export const useUtilityInventorySummary = <T>({ startDate, endDate, aggregationType, resolution }: IFetchAggregatedEventsArgs) => {
  const customerIds = useAppSelector(getViewingCustomerIds);

  const res = useFetchUtilityInventorySummaryQuery({
    customerIds,
    startDate,
    endDate,
    aggregationType,
    resolution,
    skipSourcesCount: aggregationType !== AggregationTypes.GENERATION_BY_FUEL,
  });

  return {
    ...res,
    data: res.data?.data as IPaginationResponse<IAggregatedAssetEvent<T>>['data'] | undefined,
    pagination: res.data?.meta.pagination,
  };
}