import { IconDeviceDesktopAnalytics, IconLeaf, IconWorldBolt } from "@tabler/icons-react";
import { Point, PointLabelObject } from "highcharts";
import BaseChart from "shared/components/Chart/baseChart";


const gpcRetailRateLineSeriesData = [
  {x: 2007, y: 1.56},
  {x: 2008, y: 1.54},
  {x: 2009, y: 1.45},
  {x: 2010, y: 1.44},
  {x: 2011, y: 1.43},
  {x: 2012, y: 1.11},
  {x: 2013, y: 1.09},
  {x: 2014, y: 1.18},
  {x: 2015, y: 1.10},
  {x: 2016, y: 1.08},
  {x: 2017, y: 1.00},
  {x: 2018, y: 0.99},
  {x: 2019, y: 0.93},
  {x: 2020, y: 0.79},
  {x: 2021, y: 0.82},
  {x: 2022, y: 0.81},
  {x: 2023, y: 0.82},
];


const ciRateLineChartOptions: Highcharts.Options = {
  legend: {
    enabled: false,
  },
  chart: {
    type: 'line',
    height: 360,
    backgroundColor: 'transparent',
  },
  title: {
    text: 'Historical GPC Retail Carbon Rate',
    style: {
      fontSize: '12px',
    }
  },
  yAxis: [{
    title: {
      text: 'CO<sub>2</sub>e lb/kWh',
      useHTML: true,
    },
  }],
  xAxis: [{
    type: 'linear',
  }],
  series: [{
    data: gpcRetailRateLineSeriesData,
    type: 'line',
    showInLegend: true,
    color: '#71ac4d',
  }],
  plotOptions: {
    line: {
      dataLabels: {
        enabled: true,
        formatter: function(this: PointLabelObject) {
          return this.point.y?.toFixed(2);
        }
      },
      tooltip: {
        pointFormatter: function(this: Point) {
          return `<div>${this.y} lb/kWh</div>`
        }
      }
    }
  }
}

const gpcEnergyMixSeriesData = [
  {name: 'Gas', y: 46, color: '#1e72a6', dataLabels: {distance: -30}},
  {name: 'Coal', y: 16, color: '#464743', dataLabels: {distance: -30}},
  {name: 'Nuclear', y: 25, color: '#4f9042', dataLabels: {distance: -30, color: 'white'}},
  {name: 'Solar', y: 6, color: '#4f9042'},
  {name: 'Hydro', y: 2, color: '#4f9042'},
  {name: 'Wind', y: 1, color: '#4f9042'},
  {name: 'Null Energy<sup>2</sup>', y: 4, color: '#f2b02d'},
  {name: 'Oil', y: 0.02, color: '#464743'},
];


const fuelTypePieChartOptions: Highcharts.Options = {
  chart: {
    type: 'pie',
    height: 360,
    backgroundColor: 'transparent',
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    valueDecimals: 2,
    headerFormat: '<span style="color:{point.color}">\u25CF</span> {point.key}: ',
    pointFormat: '<b>{point.y}%</b>',
    useHTML: true,
  },
  title: {
    verticalAlign: 'middle',
    align: 'center',
    floating: true,
    useHTML: true,
    text: `<div style="font-size: 12px;">Carbon Free Energy</div><div style="text-align: center; font-size: 32px;">34%</div>`,
    x: 15,
    y: 40,
    style: {
      color: '#4f9042',
    }
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: true,
        useHTML: true,
        formatter: function(this: PointLabelObject) {
          return `<div>${this.point.name}</div><div>${this.point.y}%</div>`;
        }
      }
    }
  },
  series: [{
    type: 'pie',
    name: 'Generation',
    innerSize: 150,
    showInLegend: true,
    data: gpcEnergyMixSeriesData,
  }],
}


const OverviewSection = () => {
  return (
    <div className="soco-emissions-report--overview">
      <div className="soco-emissions-report-overview--title">Overview</div>
      <div className="soco-emissions-report-overview--separator"/>
      <div className="soco-emissions-overview--section">
        <div className="soco-emissions-overview-section--icon"><IconLeaf color="#508d33" /></div>
        <div className="soco-emissions-overview-section--content">Since 2007, through approvals in the
  state regulatory process, Georgia Power
  has retired more than 5,400 MW of
  coal and oil resources, converted 1,200
  MW of coal capacity to natural gas,
  and added more than 3,300 MW of
  renewable resources.</div>
      </div>

      <div className="soco-emissions-overview--section">
        <div className="soco-emissions-overview-section--icon"><IconWorldBolt color="#508d33" /></div>
        <div className="soco-emissions-overview-section--content">Starting in 2017, Southern Company
  subsidiaries, including Georgia Power,
  began to follow the World Resources
  Institute/World Business Council for
  Sustainable Development (WRI/WBCSD)
  protocols for calculating CO2e retail
  emission rates, which account for energy
  purchases, sales, and renewable energy
  credits (RECs). Data for 2016 and earlier
  includes only Georgia Power Generation
  CO2 emissions and does not account for
  energy purchases, sales, and RECs.</div>
      </div>

      <div className="soco-emissions-overview--section">
        <div className="soco-emissions-overview-section--icon"><IconDeviceDesktopAnalytics color="#508d33" /></div>
        <div className="soco-emissions-overview-section--content">RECs retired on behalf of all customers
  are included in the calculation as zero
  emissions for associated generation.
  Generation where Georgia Power
  does not own the associated RECs
  are considered “null” energy in the
  calculation with a U.S. Environmental
  Protection Agency Emissions & Generation
  Resource Integrated Database (eGRID)
  emission factor applied.</div>
      </div>
      <div className="soco-emissions-overview-section--content">RECs retired on behalf of specific
  customers, such as for purposes of a
  green energy program, and associated
  generation are excluded from this
  calculation. These retail emission rates
  only apply to retail grid energy outside
  of any subscription programs where RECs
  are retired on behalf of specific customers.</div>
    </div>
  );
}


const DetailSection = () => (
  <div className="soco-emissions-report--detail">
    <p>
      <strong>At Georgia Power, we believe everyone should have access to clean, safe, reliable and affordable energy. A carbon-free energy future for Georgia starts with sustainable changes across the state today.</strong>
      That’s why we maintain a diverse fuel mix, including natural gas, nuclear, solar, wind, hydro and coal. All while providing flexible payment options and rate plans to suit your business.
    </p>
    <div className="soco-emissions-report-detail--separator"/>
    <div className="soco-emissions-report-detail--section">
      <div className="soco-emissions-report-detail-rates--section">
        <div><strong>Retail Carbon Rates</strong>, 2023<sup>1</sup></div>
        <table className="soco-emissions-report-detail-rates--table">
          <thead>
            <tr>
              <th>Greenhouse Gas (GHG)</th>
              <th>lb/kWh</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CO<sub>2</sub>e</td>
              <td>0.82</td>
            </tr>
            <tr>
              <td>CO<sub>2</sub></td>
              <td>0.81</td>
            </tr>
            <tr>
              <td>CH<sub>4</sub></td>
              <td>0.00005</td>
            </tr>
            <tr>
              <td>N<sub>2</sub>O</td>
              <td>0.00001</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="soco-emissions-report-detail-rate--info">
        <ul>
          <li>CO<sub>2</sub>e, carbon dioxide equivalent, is the combined total for carbon dioxide (CO<sub>2</sub>), methane (CH<sub>4</sub>), and nitrous oxide (N<sub>2</sub>O).</li>
          <li>Global warming potentials of 25 for CH<sub>4</sub> and 298 for N<sub>2</sub>O are used to scale those GHG to CO<sub>2</sub>e.</li>
        </ul>
      </div>
    </div>
    <div className="soco-emissions-report-detail--separator"/>
    <div className="soco-emissions-report-detail--section">
      <div style={{flexShrink: 0, width: 400}}>
        <div>
          <div><strong>Retail Carbon Rates</strong></div>
          <div>2007 - 2023<sup>3</sup></div>
        </div>
        <BaseChart overrideOptions={ciRateLineChartOptions} />
      </div>
      <div style={{flexShrink: 0, width: 400}}>
        <div>
          <div><strong>Energy Mix</strong></div>
          <div>2023</div>
        </div>
        <BaseChart overrideOptions={fuelTypePieChartOptions} />
      </div>
    </div>
    <div className="soco-emissions-report-detail--disclaimers">
      <p>
        <sup>1</sup>
        Georgia Power provides this information to the publicly available Edison Electric Institute Electric Company Carbon Emissions and Electricity Mix Reporting Database for Corporate Customers. Updated information on this topic will be available in this database later this year.
      </p>
      <p>
        <sup>2</sup>
        Georgia Power purchases only the null energy output and not Renewable Energy Certificates (RECs) from some renewable generating facilities. The rights to RECs are addressed by the applicable power purchase agreement. The party that owns the RECs retains the right to make renewable energy claims in connection with the RECs.
      </p>
      <p>
        <sup>3</sup>
        Starting in 2024, Georgia Power has started using an accounting measurement of grid-based carbon emissions that is in compliance with the Greenhouse Gas (GHG) emissions protocol. This accounting is done in partnership with Singularity Energy.
      </p>
    </div>
  </div>
);


const SouthernFeb2025MVPEmissionsReport = () => {
  return <div className="soco-emissions-report--container">
    <div className="soco-emissions-report--header">
      <h1 className="soco-emissions-report--title">Retail Carbon Rate</h1>
      <div className="soco-emissions-report--subtitle">A market-based emission factor for electricity usage</div>
    </div>
    <div className="soco-emissions-report--content">
      <OverviewSection />
      <DetailSection />
    </div>
  </div>;
};

export default SouthernFeb2025MVPEmissionsReport;