import { Tabs as MTabs, TabsList } from '@mantine/core';
import { useAmpNav } from 'amp/hooks';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface ITab {
  title: string
  path: string
  disabled?: boolean
  selected?: boolean
  onMouseEnter?: () => void
}

export default function Tabs({tabs}: {tabs: ITab[]}) {
  const {pathname} = useLocation();
  const nav = useAmpNav();

  const [selectedTabValue, setSelectedTabValue] = useState('');

  useEffect(() => {
    const selected = tabs.find(tab => tab.selected);

    if (selected) {
      setSelectedTabValue(selected.path);
      return
    }

    const matchingTabs = tabs.filter(tab => pathname.includes(tab.path));
    if (matchingTabs.length === 1) {
      setSelectedTabValue(matchingTabs[0].path);
    } else if (matchingTabs.length > 1) {
      const bestMatch = matchingTabs.sort((a, b) => b.path.length - a.path.length);
      setSelectedTabValue(bestMatch[0].path);
    }
  }, [tabs, pathname]);

  return (
    <MTabs value={selectedTabValue}>
      <TabsList>
        {tabs.map(tab => <MTabs.Tab onMouseEnter={tab.onMouseEnter} fz="12" key={tab.path} value={tab.path} disabled={tab.disabled} onClick={() => nav(tab.path)}>{tab.title}</MTabs.Tab>)}
      </TabsList>
    </MTabs>
  );
}