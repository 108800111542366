import { Divider, Group, Pill, Title } from "@mantine/core";
import { IconBolt } from "@tabler/icons-react";
import { sum, uniq } from "ramda";

import BasePaper from "shared/components/Paper/basePaper";
import { IAllocationRunInputData, IAllocationSummaryResult } from "shared/types/allocation";
import { Program } from "shared/types/program";
import { numberToSiFormat, snakeToTitle } from "shared/utils/strings";


const AllocationResultsOverviewProgramDetailsOverview = ({program, inputs, summary}: {summary: IAllocationSummaryResult['summary_results'], program: Program, inputs: IAllocationRunInputData}) => {

  const numGenerators = uniq(inputs.generator_assignments.filter(d => d.retail_program_id === program.id).map(d => d.asset_id)).length
  const totalGenMwh = sum(Object.values(summary.hourly_gen_and_load_by_program[program.id] || {}).map(({total_gen_wh}) => total_gen_wh)) / 1_000_000;
  const programSubscriptions = inputs.customer_subscriptions.filter(({retail_program_id}) => program.id === retail_program_id);
  const totalGen = numberToSiFormat(totalGenMwh * 1_000_000);
  const isFixedCommitmentProgram = program?.isFixedCommitment || false;
  const currentCommitmentPercentTenThousandths = sum(programSubscriptions.map(s => {
    return (s.percentGenerationDedicatedTenThousandths / 10_000)
  }));
  const formattedCurrentCommitment = numberToSiFormat(sum(programSubscriptions.map(s => (s.committedGenerationWh))));

  return (
    <BasePaper titleContent="Program Overview" className="program-details-overview--pane">
      <Group justify="space-between">
        <div>
          <div>
            <Pill bg={program.status !== 'active' ? 'var(--color-neutral-4)' : 'var(--color-teal-2)'} c="var(--color-blue-2)" radius="sm">
              {snakeToTitle(program.status)}
            </Pill>
          </div>
          <Title order={3} fz="24px" mt="8px" mb="8px" c="var(--color-green-2)">{program.name}</Title>
          <div>
            <Pill bg='var(--color-grey-0)' c="var(--color-blue-1)" radius="sm">
              {program.data?.program_config?.accounting_period === 'hourly' ? '24/7' : 'Annual'} Program match
            </Pill>
          </div>
        </div>
        <Group justify="space-evenly" mr="60px">
          <Group>
            <div className="allocation-results-summary-pane-metric--container">
              <div className="allocation-results-summary-pane--metric">
                <IconBolt color="var(--color-grey-4)" className="allocation-results-summary-pane--icon"/>
                {numGenerators}
              </div>
              <div className="allocation-results-summary-pane--label">Generators Assigned</div>
            </div>
            <div className="allocation-results-summary-pane-metric--container">
              <div className="allocation-results-summary-pane--metric">{totalGen.value} <span className="allocation-results-summary-pane--unit">{totalGen.unitPrefix}Wh</span></div>
              <div className="allocation-results-summary-pane--label">Generation Actual</div>
            </div>
          </Group>
          <Divider color="var(--color-grey-3)" orientation="vertical"/>
          <Group>
            <div className="allocation-results-summary-pane-metric--container">
              <div className="allocation-results-summary-pane--metric">
                {programSubscriptions.length}
              </div>
              <div className="allocation-results-summary-pane--label">Active Subscriptions</div>
            </div>
            <div className="allocation-results-summary-pane-metric--container">
              <div className="allocation-results-summary-pane--metric blue">
              {isFixedCommitmentProgram ? `${formattedCurrentCommitment.value} ${formattedCurrentCommitment.unitPrefix}Wh` : `${currentCommitmentPercentTenThousandths.toFixed(4)}%`}<span className="allocation-results-summary-pane--unit"></span>
              </div>
              <div className="allocation-results-summary-pane--label">Current Commitment</div>
            </div>
          </Group>
        </Group>
      </Group>
    </BasePaper>
  )
}

export default AllocationResultsOverviewProgramDetailsOverview;