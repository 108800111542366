import { createSelector } from "reselect";
import { RootState } from "store";

const root = (state: RootState) => state.amp__generator;

const byId = createSelector(
  root,
  (state) => state.byId,
);

const sourceById = createSelector(
  root,
  (state) => state.sourceById,
);

export const getGeneratorById = createSelector(
  byId,
  (_: RootState, generatorId: string) => generatorId,
  (gensById, generatorId) => gensById[generatorId]
);

export const getGeneratorsById = createSelector(
  byId,
  (_: RootState, generatorIds: string[]) => generatorIds,
  (gensById, generatorIds) => generatorIds.map(genId => gensById[genId]).filter(x => !!x)
);

const assignmentsById = createSelector(
  root,
  (state) => state.assignmentsById,
);

const assignmentIdsByGenId = createSelector(
  root,
  (state) => state.assignmentIdsByGenId,
);

const assignmentIdsForGeneratorId = createSelector(
  assignmentIdsByGenId,
  (_: RootState, generatorId: string) => generatorId,
  (assignmentsByGenId, generatorId: string) => assignmentsByGenId[generatorId] || [],
)

export const getAssignmentsForGeneratorId = createSelector(
  assignmentIdsForGeneratorId,
  assignmentsById,
  (assignmentIds, byId) => (
    assignmentIds.map(id => byId[id]).filter(x => !!x)
  )
);

export const getSourcesByIds = createSelector(
  sourceById,
  (_: RootState, sourceIds: string[]) => sourceIds,
  (sourcesById, sourceIds) => sourceIds.map(sid => sourcesById[sid]).filter(x => !!x)
);
