import { Checkbox, Group, Skeleton, Text } from '@mantine/core';
import { sum } from 'ramda';
import { useSearchParams } from 'react-router-dom';

import { useFetchAggregatedEventsQuery } from 'amp/api/assetEvents';
import { getViewingTimeZone } from 'amp/store/ui/selectors';
import BaseTable, { IBaseTableColumn } from 'shared/components/Table/baseTable';
import { useCustomersPage, useUtilityCustomers } from 'shared/store/customers/hooks';
import { getCustomerById } from 'shared/store/customers/selectors';
import { AggregationTypes } from 'shared/types/aggregatedEvents';
import { IEnergyConsumptionData } from 'shared/types/assetEvents';
import { isUtilityCustomer } from 'shared/types/customer';
import { getLastYearEnd, getLastYearStart } from 'shared/utils/dates';
import { numberToString } from 'shared/utils/strings';
import { useAppSelector } from 'store';
import './style.css';

const generatorTableColumns: IBaseTableColumn[] = [
  { key: 'name', displayValue: 'Name' },
  { key: 'subActs', displayValue: 'Number of sub-accounts' },
  { key: 'priorYearConsumption', displayValue: 'Prior Year Consumption' },
];

const LastYearConsumptionCell = ({ customerId }: { customerId: string }) => {
  const customer = useAppSelector(s => getCustomerById(s, customerId));
  const tzName = useAppSelector(getViewingTimeZone);

  const startDate = getLastYearStart(tzName).toISOString();
  const endDate = getLastYearEnd(tzName).toISOString();
  const loadRes = useFetchAggregatedEventsQuery({
    startDate, endDate, resolution: '1d', aggregationType: AggregationTypes.TOTAL_LOAD, customerId,
  }, {skip: !isUtilityCustomer(customer)});

  const totalConsumptionKWh = sum(loadRes.data?.data.map(ae => (ae.data as IEnergyConsumptionData).sum_consumed_kwh) || []);
  const totalConsumptionMWh = totalConsumptionKWh / 1_000;
  return (
    <Skeleton visible={loadRes.isLoading}>
      <Text fz={10} fw={700}>{numberToString(Math.round(totalConsumptionMWh))} MWh</Text>
    </Skeleton>
  );
}

const SubAccountCountCell = ({ customerId }: { customerId: string }) => {
  const subAccountsRes = useCustomersPage({ page: 1, perPage: 1, parentCustomerId: customerId }); // used as a count
  const numSubAccounts = subAccountsRes.pagination?.total_items || 0;

  return (
    <Skeleton visible={subAccountsRes.isLoading}>
      <Text fz={10} fw={700}>{numSubAccounts}</Text>
    </Skeleton>
  );
}

const CustomerSelection = () => {
  const [params, setParams] = useSearchParams();
  const selectedCustomers = params.getAll('sc');

  const res = useUtilityCustomers();

  const onRowToggled = (checked: boolean, checkedGenId: string) => {
    if (!checked) {
      setParams(newParams => {
        const gens = newParams.getAll('sc');
        // TODO: delete should support deleting by value but caused errors when given a 2nd arg
        newParams.delete('sc');
        gens.forEach(gid => {
          if (gid !== checkedGenId) {
            newParams.append('sc', gid);
          }
        })
        return newParams;
      });
    } else {
      setParams(newParams => {
        newParams.append('sc', checkedGenId);
        return newParams;
      });
    }
  }

  const toggleRowSelected = (rowId: string) => {
    onRowToggled(!selectedCustomers.includes(rowId), rowId);
  };

  const rows = res.data?.map((row) => ({
    id: row.id,
    name: <Group>
      <Checkbox
        size="16px"
        color="var(--color-green-1)"
        checked={selectedCustomers.includes(row.id)}
      />
      {row.name}
    </Group>,
    subActs: <SubAccountCountCell customerId={row.id} />,
    priorYearConsumption: <LastYearConsumptionCell customerId={row.id} />
  })) || [];
  return (
    <div className="projections-page--selection-area">
      <BaseTable
        rows={rows}
        columnNames={generatorTableColumns}
        totalPages={1}
        currentPage={1}
        onPageChange={() => {}}
        isLoading={res.isLoading}
        onTableRowClicked={toggleRowSelected}
      />
    </div>
  )
}

export default CustomerSelection;