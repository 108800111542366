import { Loader, MultiSelect } from "@mantine/core";
import cx from 'classnames';
import { useParams, useSearchParams } from "react-router-dom";

import { useAllocationRunDiagnosticOutputData, useAllocationRunInputData } from "amp/store/allocationRuns/hooks";
import { getCurrentUser } from "shared/store/user/selectors";
import { useAppSelector } from "store";

import { IconAlertTriangle, IconCircleCheck, IconCircleDotted, IconInfoCircle, IconUrgent } from "@tabler/icons-react";
import { usePageParams } from "amp/hooks";
import { getViewingOpCoId } from "amp/store/ui/selectors";
import { useEffect } from "react";
import { IAllocationDiagnosticItem } from "shared/types/allocation";
import { tracker, TrackEventNames } from "shared/utils/tracker";
import './style.css';


const LevelIcon = ({level}: {level: IAllocationDiagnosticItem['level']}) => {
  switch(level) {
    case 'warning':
      return <IconAlertTriangle color="var(--color-orange-1)"/>
    case 'info':
      return <IconInfoCircle color="var(--color-blue-6)" />
    case 'error':
      return <IconUrgent color="black" />
    case 'success':
      return <IconCircleCheck color="var(--color-teal-6)" />
    case 'debug':
      return <IconCircleDotted color="var(--color-yellow-1)" />
    case 'critical':
      return <IconUrgent color="black" />
    case 'fatal':
      return <IconUrgent color="black" />
  }
}



const AllocationDiagnosticItem = ({item}: {item: IAllocationDiagnosticItem}) => {
  return <div className={cx("allocation-diagnostic-item--container", item.level)}>
    <div className="allocation-diagnostic-item--level"><LevelIcon level={item.level} /></div>
    <div className="allocation-diagnostic-item--info">
      <div className="allocation-diagnostic-item--summary">{item.summary}</div>
      <div className="allocation-diagnostic-item--detail">{item.detail}</div>
    </div>
  </div>
};


const AllocationResultsDiagnostic = () => {
  const { runId = '' } = useParams<{ runId: string }>();
  const currentCustomerId = useAppSelector(getCurrentUser)?.customer_id;
  const oci = useAppSelector(getViewingOpCoId);
  const [searchParams, setSearchParams] = useSearchParams();
  const levels = searchParams.getAll('l');
  const families = searchParams.getAll('f');

  useEffect(() => {
    tracker.track(TrackEventNames.VADP, {runId});
  }, [runId]);

  // TODO: add pagination controls for UI
  const {page, perPage} = usePageParams({defaultPage: 0, defaultPerPage: 200});

  const res = useAllocationRunDiagnosticOutputData({
    allocationRunId: runId,
    customerId: oci || currentCustomerId,
    page,
    perPage,
    levels,
    families,
  });
  const inputsRes = useAllocationRunInputData(runId, oci || currentCustomerId);

  const result = res.data;
  const inputs = inputsRes.data;

  const onLevelsChange = (newLevels: string[]) => {
    setSearchParams(newParams => {
      newParams.delete('l')
      newLevels.forEach(level => newParams.append('l', level));
      return newParams
    })
  };

  const onFamiliesChange = (newFamilies: string[]) => {
    setSearchParams(newParams => {
      newParams.delete('f')
      newFamilies.forEach(family => newParams.append('f', family));
      return newParams
    })
  };


  const isLoading = !result || res.isLoading || !inputs || inputsRes.isLoading
  const familiyOptions = [
    "batch_run",
    "rollup_results",
    "customer_reports",
    "customer_report",
    "program_reports",
    "program_report",
    "summary_report",
    "standard_delivery_report",
    "input_data",
    "allocation_run",
    "allocation_algorithm"
  ];

  return <div className="allocation-results-overview--container">
    <div className="allocation-diagnostic-filters--container">
      <MultiSelect
        label={<div>Diagnostic level</div>}
        value={levels}
        data={[
          {label: 'Debug', value: 'debug'},
          {label: 'Info', value: 'info'},
          {label: 'Success', value: 'success'},
          {label: 'Warning', value: 'warning'},
          {label: 'Error', value: 'error'},
          {label: 'Critical', value: 'critical'},
          {label: 'Fatal', value: 'fatal'},
        ]}
        onChange={onLevelsChange}
        w={200}
        mr={32}
      />
      <MultiSelect
        label={<div>Diagnostic Family</div>}
        value={families}
        data={familiyOptions.map(f => ({label: f, value: f}))}
        onChange={onFamiliesChange}
        w={200}
      />
    </div>
    {isLoading && <Loader ml="45%" mt="20%"/>}
    {/* @ts-ignore */}
    {!isLoading && result.items.map(item => <AllocationDiagnosticItem item={item} key={item.timestamp} />)}
  </div>
};

export default AllocationResultsDiagnostic;