import { Anchor, Button, Group, Input, SegmentedControl, Select, Skeleton, Stack, Text, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconMail, IconX } from '@tabler/icons-react';

import { getViewingCustomer, getViewingTimeZone } from 'amp/store/ui/selectors';
import { useEffect } from 'react';
import { useUpsertMySettingMutation } from 'shared/api/users';
import BasePaper from 'shared/components/Paper/basePaper';
import RoleRequired from 'shared/components/RoleRequired/roleRequired';
import { LOGIN_URL } from 'shared/constants/resources';
import { useCustomer } from 'shared/store/customers/hooks';
import { getCurrentUser, getCurrentUserRole, getMeasurementPreference } from 'shared/store/user/selectors';
import { setRoleOverride } from 'shared/store/user/slice';
import { MeasurementDisplayPreference, UserProperty, UserRole } from 'shared/types/user';
import { tracker, TrackEventNames } from 'shared/utils/tracker';
import { useAppDispatch, useAppSelector } from 'store';
import './style.css';

const allTimezones = [...Intl.supportedValuesOf('timeZone'), 'UTC'];

const roleOptions = Object.entries(UserRole).map(([label, value]) => ({
  value,
  label
}));

const SettingsPreferencesView = () => {
  const dispatch = useAppDispatch();
  const metricPref = useAppSelector(getMeasurementPreference);
  const timezone = useAppSelector(getViewingTimeZone);
  const editingCustomer = useAppSelector(getViewingCustomer);
  const currentUser = useAppSelector(getCurrentUser);
  const role = useAppSelector(getCurrentUserRole);
  const usersCustomerRes = useCustomer(currentUser.customer_id);
  const [upsertMySettings] = useUpsertMySettingMutation();

  useEffect(() => {
    tracker.track(TrackEventNames.VUP);
  }, []);

  const setRole = (role: UserRole | null) => {
    dispatch(setRoleOverride(role || null));
  }

  const setMetricPref = (value: string) => {
    upsertMySettings({
      value,
      name: UserProperty.MEASUREMENT_PREF,
    }).unwrap()
      .then(() => {
        tracker.track(TrackEventNames.CMDP, { measurement: value });
        notifications.show({
          title: 'Success',
          message: 'Saved your settings preferences',
          color: "teal",
          icon: <IconCheck style={{ width: '20px', height: '20px' }} />,
        });
      })
      .catch(() => {
        notifications.show({
          title: 'Error',
          message: 'Failed to save your settings preferences',
          icon: <IconX style={{ width: '20px', height: '20px' }} />,
          color: "red",
        });
      })
  };

  return (
    <div className="account-details-page--scroll-container">
      <Stack p={0} gap={16}>
        <BasePaper titleContent="Account Details">
          <Group align="flex-start" justify="space-between">
            <Stack>
              <TextInput
                miw={400}
                label="Name"
                // TODO: let users update their name once there's an endpoint in user auth service
                value={currentUser.name}
                disabled={true}
              />
              <TextInput
                miw={400}
                label="Email"
                value={currentUser.email}
                disabled={true}
              />
            </Stack>
            <Stack>
              <Skeleton width="fit-content" visible={usersCustomerRes.isLoading || usersCustomerRes.isFetching}>
                <TextInput
                  miw={400}
                  label="Company"
                  value={usersCustomerRes.data?.name || 'unknown'}
                  disabled={true}
                />
              </Skeleton>
              <RoleRequired role={UserRole.SADMIN}>
                <Select
                  label="Override role"
                  description="Singularity admins can explicitly set their role to view the app without having to logout/login"
                  maw={280}
                  data={roleOptions}
                  value={role}
                  onChange={(e) => e && setRole(e as UserRole)}
                  clearable
                />
              </RoleRequired>
            </Stack>
          </Group>
        </BasePaper>
        <BasePaper titleContent="Platform Settings">
          <Group align="flex-start" justify="space-between">
            <Input.Wrapper label="Measurement display preference" description="Choose how you would like various metrics to appear in the application">
              <SegmentedControl data={Object.values(MeasurementDisplayPreference)} defaultValue={metricPref} onChange={setMetricPref} />
            </Input.Wrapper>
            <Input.Wrapper label="Time Zone" description={`The official timezone used for all reporting & data for ${editingCustomer?.name || 'your customer'}`}>
              <Select data={allTimezones} defaultValue={timezone} disabled />
              <Text fz={12} maw={400}>You must contact Singularity to change this value, as all data and report need to be re-created when this changes.</Text>
            </Input.Wrapper>
          </Group>
        </BasePaper>
        <div className="account-details-page--contact-us-container">
          <Group align="flex-start" justify="space-between">
            <Stack p={0} gap={20}>
              <Text fz={14} c="var(--color-blue-2)">
                If you need help, please send an email to support@singularity.energy
              </Text>
              <Anchor href="mailto:support@singularity.energy" target="_blank" w="fit-content">
                <Button
                  className="account-details-page--contact-us-button"
                  leftSection={<IconMail size={16} />}
                >
                  Contact us
                </Button>
              </Anchor>
            </Stack>
            <Stack p={0} gap={20} align="flex-end">
              <Text fz={14} c="var(--color-blue-2)">
                You will be logged out and returned to the login screen.
              </Text>
              <Anchor href={`${LOGIN_URL}/logout?product=amp`} w="fit-content">
                <Button className="account-details-page--contact-us-button">
                  Logout
                </Button>
              </Anchor>
            </Stack>
          </Group>
        </div>
      </Stack>
    </div>
  );

};

export default SettingsPreferencesView;