import { Group } from "@mantine/core";
import { useListDevicesQuery } from "registry/api/device";


const DevicesView = () => {
  const {
    data,
    isLoading
  } = useListDevicesQuery({offset: 0, limit: 20});

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Devices</h2>
      {data?.data.map(dev => <Group>
        <div>{dev.id}</div>
        <div>{dev.name}</div>
        <div>{dev.fuel_category}</div>
        <div>{dev.grid_identifier}</div>
        <div>{(dev.capacity_wh || 0) / 1_000_000}</div>
      </Group>)}
    </div>
  )
};

export default DevicesView;