import { useEffect } from 'react';

import { IListAllocationDiagnosticsArgs, useFetchAllocationRunCustomerResultsQuery, useFetchAllocationRunInputDataQuery, useFetchAllocationRunProgramResultQuery, useFetchAllocationRunQuery, useFetchAllocationRunStdDeliveryResultsQuery, useFetchAllocationRunSummaryResultsQuery, useFetchUtilityAllocationRunsQuery, usePaginateDiagnosticsQuery } from 'amp/api/allocationRuns';
import { getViewingCustomerIds } from 'amp/store/ui/selectors';
import { AllocationRunStatus, IAllocationRun, IAllocationRunInputData, IAllocationSummaryResult, ICustomerAllocationSummaryResult, IProgramAllocationSummaryResult, IStandardDeliveryResult } from 'shared/types/allocation';
import { useAppDispatch, useAppSelector } from 'store';
import { getAllocationCustomerResultByRunIdAndCustomerId, getAllocationInputDataById, getAllocationProgramResultByRunIdAndProgramId, getAllocationRunById, getAllocationRunsById, getAllocationSummaryResultById, getBulkFetchAllocationInputsLoading, getBulkFetchInputsData, getBulkFetchInputsError, getStdDeliveryResultById, shouldBulkFetchAllocationInputs } from './selectors';
import { fetchUtilityAllocationRunInputs, receiveAllocationRunCustomerResult, receiveAllocationRunInput, receiveAllocationRunProgramResult, receiveAllocationRunSummary, receiveAllocationRuns, receiveStdDeliveryResult } from './slice';


export function useAllocationRun(runId: string, customerId?: string | null) {
  const res = useFetchAllocationRunQuery({ id: runId, customerId });
  const dispatch = useAppDispatch();
  const run = useAppSelector(s => getAllocationRunById(s, runId));

  useEffect(() => {
    if (res.data) {
      dispatch(receiveAllocationRuns([res.data.allocation_run]));
    }
  }, [res.data, dispatch, runId]);

  return {
    ...res,
    isFirstTimeLoading: res.isLoading,
    isRefreshingData: res.isFetching,
    data: run,
  };
}

export function useAllocationRunInputData(runId?: string, customerId?: string, skip?: boolean) {
  const dispatch = useAppDispatch();
  const inputRes = useFetchAllocationRunInputDataQuery(
    { runId: runId || '', rootCustomerId: customerId || '' },
    { skip: !runId || !customerId || skip }
  );
  const inputData = useAppSelector(s => getAllocationInputDataById(s, runId || ''));

  useEffect(() => {
    if (inputRes.data) {
      dispatch(receiveAllocationRunInput({ inputData: inputRes.data, runId: runId || '' }));
    }
  }, [runId, inputRes.data, dispatch]);


  return {
    ...inputRes,
    data: inputData as IAllocationRunInputData | undefined,
    isFirstTimeLoading: inputRes.isLoading,
    isRefreshingData: inputRes.isFetching,
  };
}

export function useBulkAllocationRunInputs(allocationRuns: IAllocationRun[]) {
  const dispatch = useAppDispatch();

  const asArgs = allocationRuns.map(run => ({ id: run.id, customer_id: run.customer_id })).sort((a, b) => `${a.customer_id}${a.id}`.localeCompare(`${b.customer_id}${b.id}`));
  const serializedArgs = JSON.stringify(asArgs);

  const shouldFetch = useAppSelector(s => shouldBulkFetchAllocationInputs(s, serializedArgs));

  if (shouldFetch) {
    dispatch(fetchUtilityAllocationRunInputs({ allocationRuns }));
  }
  const isLoading = useAppSelector(s => getBulkFetchAllocationInputsLoading(s));
  const isError = useAppSelector(s => getBulkFetchInputsError(s));
  const data = useAppSelector(s => getBulkFetchInputsData(s));

  return {
    isLoading,
    isError,
    data,
  }
}


export function useAllocationRunCustomerOutputData(runId?: string, rootCustomerId?: string, subCustomerId?: string | null) {
  const dispatch = useAppDispatch();
  const customerResultRes = useFetchAllocationRunCustomerResultsQuery(
    { runId: runId || '', rootCustomerId: rootCustomerId || '', subCustomerId: subCustomerId || '' },
    { skip: !runId || !rootCustomerId || !subCustomerId },
  );
  const customerResultData = useAppSelector(
    s => getAllocationCustomerResultByRunIdAndCustomerId(s, { runId: runId || '', customerId: subCustomerId || '' })
  );

  useEffect(() => {
    if (customerResultRes.data) {
      dispatch(receiveAllocationRunCustomerResult({ runId: runId || '', customerData: customerResultRes.data }));
    }
  }, [runId, rootCustomerId, subCustomerId, customerResultRes, dispatch]);

  return {
    ...customerResultRes,
    data: customerResultData as ICustomerAllocationSummaryResult | undefined,
    isFirstTimeLoading: customerResultRes.isLoading,
    isRefreshingData: customerResultRes.isFetching,
  };
}

export function useAllocationRunSummaryOutputData(runId?: string, rootCustomerId?: string, skip?: boolean) {
  const dispatch = useAppDispatch();
  const summaryResultRes = useFetchAllocationRunSummaryResultsQuery(
    { runId: runId || '', rootCustomerId: rootCustomerId || '' },
    { skip: !runId || !rootCustomerId || skip }
  );
  const summaryResult = useAppSelector(s => getAllocationSummaryResultById(s, runId || ''));

  useEffect(() => {
    if (summaryResultRes.data) {
      dispatch(receiveAllocationRunSummary(summaryResultRes.data))
    }
  }, [summaryResultRes.data, runId, dispatch]);

  return {
    ...summaryResultRes,
    data: summaryResult as IAllocationSummaryResult | undefined,
    isFirstTimeLoading: summaryResultRes.isLoading,
    isRefreshingData: summaryResultRes.isFetching,
  };
}

export function useAllocationRunStdDeliveryOutputData(runId?: string, rootCustomerId?: string) {
  const dispatch = useAppDispatch();
  const stdDeliveryRes = useFetchAllocationRunStdDeliveryResultsQuery(
    { runId: runId || '', rootCustomerId: rootCustomerId || '' },
    { skip: !runId || !rootCustomerId }
  );
  const stdDeliveryResult = useAppSelector(s => getStdDeliveryResultById(s, runId || ''));

  useEffect(() => {
    if (stdDeliveryRes.data) {
      dispatch(receiveStdDeliveryResult({ runId: runId || '', stdDeliveryData: stdDeliveryRes.data }));
    }
  }, [runId, rootCustomerId, stdDeliveryRes.data, dispatch]);

  return {
    ...stdDeliveryRes,
    data: stdDeliveryResult as IStandardDeliveryResult | undefined,
    isFirstTimeLoading: stdDeliveryRes.isLoading,
    isRefreshingData: stdDeliveryRes.isFetching,
  };
}


export function useAllocationRunProgramOutputData(runId?: string, rootCustomerId?: string, programId?: string | null) {
  const dispatch = useAppDispatch();
  const programResultsRes = useFetchAllocationRunProgramResultQuery(
    { runId: runId || '', rootCustomerId: rootCustomerId || '', programId: programId || '' },
    { skip: !runId || !rootCustomerId || !programId },
  );
  const programResultData = useAppSelector(
    s => getAllocationProgramResultByRunIdAndProgramId(s, { runId: runId || '', programId: programId || '' })
  );

  useEffect(() => {
    if (programResultsRes.data) {
      dispatch(receiveAllocationRunProgramResult({ programData: programResultsRes.data, runId: runId || '' }));
    }
  }, [runId, rootCustomerId, programId, programResultsRes.data, dispatch]);

  return {
    ...programResultsRes,
    data: programResultData as IProgramAllocationSummaryResult | undefined,
    isFirstTimeLoading: programResultsRes.isLoading,
    isRefreshingData: programResultsRes.isFetching,
  };
}

export function useAllocationRunDiagnosticOutputData(args: IListAllocationDiagnosticsArgs) {
  const res = usePaginateDiagnosticsQuery(args);
  return {
    data: res.data,
    isLoading: res.isLoading || res.isFetching,
    isError: res.isError,
  };
}


export const useUtilityAllocationRuns = ({ page, perPage, statuses }: { page: number, perPage: number, statuses: AllocationRunStatus[] }) => {
  const dispatch = useAppDispatch();
  const customerIds = useAppSelector(getViewingCustomerIds);

  const res = useFetchUtilityAllocationRunsQuery({
    page: page,
    perPage: perPage,
    statuses: statuses,
    customerIds,
  });

  if (res.data) {
    dispatch(receiveAllocationRuns(res.data.data));
  }
  const data = useAppSelector(s => getAllocationRunsById(s, res.data?.data.map(allocationRun => allocationRun.id) || []));
  return {
    ...res,
    data,
    pagination: res.data?.meta.pagination
  };
}