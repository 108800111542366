import { DatePickerInput } from '@mantine/dates';
import { IconCalendarEvent } from '@tabler/icons-react';
import { getViewingTimeZone } from 'amp/store/ui/selectors';
import { useSearchParams } from 'react-router-dom';
import { dateToLastSecondOfDay, dateToMidnight } from 'shared/utils/dates';
import { useAppSelector } from 'store';


interface IUtcDatePickerProps {
  value?: Date | null | undefined
  isStartDate?: boolean | undefined
  startQs?: string | undefined
  endQs?: string | undefined
  maxDate?: Date | undefined
  minDate?: Date | undefined
  onChange?: Function | undefined
  error?: string | undefined
  label?: string | undefined
  dropdownType?: 'modal' | 'popover' | undefined
}

const UtcDatePicker = ({ startQs, endQs, isStartDate, value, maxDate, minDate, onChange, error, label, dropdownType}: IUtcDatePickerProps) => {
  const [, setParams] = useSearchParams();
  const tzName = useAppSelector(getViewingTimeZone);

  const onParamsChange = (params: { name: string, value: string }[]) => {
    setParams(newParams => {
      params.forEach(p => {
        newParams.set(p.name, p.value);
      });
      return newParams;
    });
  };

  // Mantine returns midnight local time when selecting a date, we want a more specific range in UTC
  const handleDateChange = (newDate: Date | null) => {
    if (newDate !== null) {
      if (isStartDate) {
        // start date inclusive of all hours in day picked
        const asMidnightUtc = dateToMidnight(newDate, tzName);
        onParamsChange([{ name: startQs || 's', value: asMidnightUtc.toISOString() }]);
      } else {
        // end date inclusive of all hours in day picked
        const asEndOfDayUtc = dateToLastSecondOfDay(newDate, tzName);
        onParamsChange([{ name: endQs || 'e', value: asEndOfDayUtc.toISOString() }]);
      }
    }
  };

  const asSimpleDate = value && new Date(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate());
  return <DatePickerInput
    value={asSimpleDate}
    maxDate={maxDate}
    minDate={minDate}
    onChange={onChange ? d => onChange(d) : d => handleDateChange(d)}
    rightSection={<IconCalendarEvent size={20} />}
    rightSectionPointerEvents="none"
    numberOfColumns={2}
    error={error}
    label={label}
    dropdownType={dropdownType || 'modal'}
    valueFormat="MM/DD/YYYY"
    w="140px"
  />
}

export default UtcDatePicker;