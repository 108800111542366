import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import { createReduxEnhancer } from '@sentry/react';
import allocationRunsReducer from 'amp/store/allocationRuns/slice';
import eventsReducer from 'amp/store/events/slice';
import generatorsReducer from 'amp/store/generators/slice';
import programsReducer from 'amp/store/programs/slice';
import subscriptionsReducer from 'amp/store/subscriptions/slice';
import uiReducer from 'amp/store/ui/slice';
import { API } from 'shared/api';
import { queries, registryQueries, staticDataQueries, userAuthQueries } from 'shared/api/queries';
import { DEBUG } from 'shared/constants/resources';
import customersReducer from 'shared/store/customers/slice';
import usersReducer from 'shared/store/user/slice';


const loggingMiddleware = () => (next: (action: unknown) => unknown) => (action: unknown) => {
  const shouldLog = DEBUG;
  if (shouldLog) {
    console.info('[Singularity.app] applying action', action);
  }
  return next(action);
};


export const store = configureStore({
  reducer: {
    user: usersReducer,
    customer: customersReducer,
    amp__generator: generatorsReducer,
    amp__program: programsReducer,
    amp__ui: uiReducer,
    amp__subscriptions: subscriptionsReducer,
    amp__allocation_runs: allocationRunsReducer,
    amp__events: eventsReducer,
    [queries.reducerPath]: queries.reducer,
    [userAuthQueries.reducerPath]: userAuthQueries.reducer,
    [staticDataQueries.reducerPath]: staticDataQueries.reducer,
    [registryQueries.reducerPath]: registryQueries.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(queries.middleware, userAuthQueries.middleware, staticDataQueries.middleware, registryQueries.middleware, loggingMiddleware),
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(createReduxEnhancer({attachReduxState: false})),
})


setupListeners(store.dispatch);


declare global {
  interface Window {
    store: typeof store;
    API: typeof API;
  }
}

if (DEBUG) {
  window.store = store;
  window.API = API;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector