import { Box, Group, LoadingOverlay, Skeleton, Stack, Title } from '@mantine/core';
import { IconArrowRight, IconCalendarEvent, IconIdBadge2, IconUserUp } from '@tabler/icons-react';
import { Map, Marker } from 'mapbox-gl';
import { mean } from 'ramda';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { usePaginateSubscriptionsQuery } from 'amp/api/programs';
import { AmpLink } from 'amp/components/Link';
import LoadChart from 'amp/components/LoadChart';
import { getAsDOMElement } from 'amp/components/MapIcons/small';
import CustomerSubscriptions from 'amp/components/SubscriptionsTable';
import { useAmpNav } from 'amp/hooks';
import BasePaper from 'shared/components/Paper/basePaper';
import { MAPBOXGL_ACCESS_TOKEN } from 'shared/constants/resources';
import { useCustomer, useCustomersPage } from 'shared/store/customers/hooks';
import { getCurrentCustomer, getOpcos } from 'shared/store/user/selectors';
import { CustomerStatus, getCustomerLogoPath, ICustomer } from 'shared/types/customer';
import { Subscription } from 'shared/types/subscription';
import { timestampToNumericDate } from 'shared/utils/dates';
import { tracker, TrackEventNames } from 'shared/utils/tracker';
import { useAppSelector } from 'store';
import './style.css';


const CustomerDetails = ({ customer }: { customer: ICustomer }) => {
  const opcos = useAppSelector(getOpcos);
  const currentCustomer = useAppSelector(getCurrentCustomer);
  const hasParentCustomer = !opcos.find(o => o.id === customer.parent_id) && customer.parent_id !== currentCustomer?.id;
  const parentCustomerRes = useCustomer(customer.parent_id || '');
  const loadingParent = parentCustomerRes.isLoading || parentCustomerRes.isFetching;

  return (
    <BasePaper titleContent={<div>Customer Details</div>}>
      <div className="paper-card--body">
        <div className="customer-details--logo">
          <img className="allocation-results--logo" src={getCustomerLogoPath(customer)} width={167} alt={`${customer.name} logo`} />
        </div>
        {hasParentCustomer && <>
          {loadingParent && <Skeleton visible={true} h={16} w={140} />}
          {!loadingParent && <div className="customer-details--row">
            <IconUserUp size="16" />
            <div>Parent Customer: <AmpLink to={`/dashboard/customers/${customer.parent_id}`}>{parentCustomerRes.data?.name || 'unknown customer'}</AmpLink></div>
          </div>}
        </>}
        <div className="customer-details--row">
          <IconIdBadge2 size="16" />
          <div>Customer ID: {parseInt(customer.id, 36)}</div>
        </div>
        <div className="customer-details--row">
          <IconCalendarEvent size="16" />
          <div>Date created: {timestampToNumericDate(customer.created_at)}</div>
        </div>
        <div className="customer-details--row">
          <IconCalendarEvent size="16" />
          <div>Date updated: {timestampToNumericDate(customer.updated_at)}</div>
        </div>
      </div>
    </BasePaper>
  );
};

const SubAccounts = ({ customer }: { customer: ICustomer }) => {
  const subCustRes = useCustomersPage({ page: 1, perPage: 10, parentCustomerId: customer.id, statuses: [CustomerStatus.ACTIVE] })
  const map = useRef<Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    if (!map.current) {
      map.current = new Map({
        accessToken: MAPBOXGL_ACCESS_TOKEN,
        container: 'customer-page--map-container',
        attributionControl: false,
        interactive: false,
        center: [-83, 33], // [lng, lat] is expected
        zoom: 4.3,
        style: 'mapbox://styles/mapbox/light-v10',
        renderWorldCopies: false,
      });
      setMapLoaded(true)
    }
  }, [setMapLoaded]);

  useEffect(() => {
    const mapVal = map.current;
    const accounts = subCustRes.data;

    if (mapLoaded && accounts && mapVal) {
      const lngs: number[] = [];
      const lats: number[] = [];
      accounts.forEach(account => {
        const latLng = (account.attributes.find(({ name }) => name === 'location_lat_lng')?.value) as number[]
        if (latLng) {
          const lng = typeof latLng[1] === 'number' ? latLng[1] : parseFloat(latLng[1]);
          const lat = typeof latLng[0] === 'number' ? latLng[0] : parseFloat(latLng[0]);
          lngs.push(lng);
          lats.push(lat);
          new Marker(getAsDOMElement()).setLngLat([lng, lat]).addTo(mapVal);
        }
      });

      if (lngs.length && lats.length) {
        mapVal.setCenter([mean(lngs), mean(lats)])
      }
    }
  }, [subCustRes.data, mapLoaded]);


  return (
    <BasePaper titleContent={<div>Sub-accounts</div>}>
      <div className="paper-card--body">
        <Group gap={0}>
          <Box w="280px" h="138px">
            <div id="customer-page--map-container" />
          </Box>
          <Stack w="15vw" gap={0} ml={32} className="customer-sub-accounts--body">
            <div>
              <Title fz={32}>{subCustRes.pagination?.total_items}</Title>
              <div color="var(--color-grey-4)">Total sub-accounts</div>
            </div>
            <AmpLink to="accounts" className="customer-view-sub-accounts--link">See sub-accounts <IconArrowRight size={14} /></AmpLink>
          </Stack>
        </Group>
      </div>
    </BasePaper>
  )
};


const CustomerProgramSubscriptions = ({ customer }: { customer: ICustomer }) => {
  const res = usePaginateSubscriptionsQuery({ page: 1, perPage: 50, customerId: customer.parent_id, childCustomerId: customer.id })
  const subs = res.data?.data.map(sub => new Subscription(sub)) || []
  return (
    <CustomerSubscriptions title="Program Subscriptions to date" subscriptions={subs} titleRow="program" />
  );
};

export default function CustomerView() {
  const { customerId = '' } = useParams<{ customerId: string }>();
  const nav = useAmpNav();
  const {
    data: customer,
    isLoading,
  } = useCustomer(customerId);

  useEffect(() => {
    if (customer?.isCustomerAccount) {
      nav(`/dashboard/customers/${customer.parent_id}/accounts/${customer.id}`, {replace: true});
    } else if (customer) {
      tracker.track(TrackEventNames.VC, {customerId, customerName: customer?.name});
    }
  }, [customer, nav, customerId]);

  if (isLoading || !customer) {
    return <Box h="100vh">
      <LoadingOverlay visible={true} />
    </Box>;
  }

  return (
    <div className="customer-page--scroll-container">
      <Group align="flex-start">
        <CustomerDetails customer={customer} />
        <SubAccounts customer={customer} />
      </Group>
      <LoadChart customerId={customer.id} isSubAccount={false} />
      <CustomerProgramSubscriptions customer={customer} />
    </div>
  );
}