export const ONE_MONTH_MILLIS = 1000 * 60 * 60 * 24 * 31;

export const toShortDateString = (date: Date, timeZone: string = 'UTC') => date.toLocaleString('default', { timeZone, month: 'short', year: 'numeric', day: 'numeric', weekday: 'short' });
export const toNumericDateString = (date: Date, timeZone: string = 'UTC') => date.toLocaleString('default', { timeZone, month: 'numeric', year: 'numeric', day: 'numeric' });
export const toHoursMinutesString = (date: Date, timeZone: string = 'UTC') => date.toLocaleString('default', { timeZone, hour: '2-digit', minute: '2-digit' });

export const timestampToNumericDate = (timestamp: string, timeZone: string = 'UTC') => toNumericDateString(new Date(timestamp), timeZone);
export const timestampToShortDate = (timestamp: string, timeZone: string = 'UTC') => toShortDateString(new Date(timestamp), timeZone);
export const timestampToHoursMinutes = (timestamp: string, timeZone: string = 'UTC') => toHoursMinutesString(new Date(timestamp), timeZone);

export const timestampToLongDate = (timestamp: string, timeZone: string = 'UTC') => {
  return new Date(timestamp)
    .toLocaleString('default', { timeZone, month: 'short', year: 'numeric', day: 'numeric', weekday: 'short', hour: '2-digit', minute: '2-digit' });
};

export const isDateWithinDateRange = (date: Date, start: Date, end: Date) => {
  return start <= date && date <= end;
};

export const daysInYear = (year: number) => {
  return ((year % 4 === 0 && year % 100 > 0) || year % 400 === 0) ? 366 : 365;
}

// Month in JavaScript is 0-indexed (January is 0, February is 1, etc),
// but by using 0 as the day it will give us the last day of the prior
// month. So passing in 1 as the month number will return the last day
// of January, not February
export const daysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
}

// Test cases:
// 1/1/11 -> 1/2/11 = 2 days
// 1/1/11 -> 12/31/11 = 365 days
export const numberOfDaysBetween = (first: Date, second: Date, inclusive: boolean = true) => {
  const daysBetween = Math.round((second.valueOf() - first.valueOf()) / (1000 * 60 * 60 * 24));

  // Add one to be inclusive of both start and end date
  return daysBetween + (inclusive ? 1 : 0);
}

export const getEarlierDate = (date1: Date, date2: Date): Date => {
  return date1 < date2 ? date1 : date2;
}

export const getLaterDate = (date1: Date, date2: Date): Date => {
  return date1 > date2 ? date1 : date2;
}

export const daysOfOverlap = (start1: Date, start2: Date, end1: Date, end2: Date, inclusive: boolean = true) => {
  const overlapStart = getLaterDate(start1, start2);
  const overlapEnd = getEarlierDate(end1, end2);
  if (overlapStart < overlapEnd) {
    const overlapDays = numberOfDaysBetween(overlapStart, overlapEnd, inclusive)
      // Add one to be inclusive of both start and end date
    return overlapDays;
  }
  return 0;
}

export const getThisYearStart = (timeZone: string) => {
  const date = new Date();
  date.setUTCMonth(0, 1);
  date.setUTCHours(0, 0, 0, 0);

  return convertToTz(date, timeZone);
}

export const getThisYearEnd = (timeZone: string) => {
  const date = new Date();
  date.setUTCMonth(11, 31);
  date.setUTCHours(23, 59, 59, 999);
  return convertToTz(date, timeZone);
}

export const getLastYearStart = (timeZone: string) => {
  const thisYearStart = getThisYearStart(timeZone);
  thisYearStart.setFullYear(thisYearStart.getFullYear() - 1);
  return thisYearStart;
}

export const getLastYearEnd = (timeZone: string) => {
  const thisYearEnd = getThisYearEnd(timeZone);
  thisYearEnd.setFullYear(thisYearEnd.getFullYear() - 1);
  return thisYearEnd;
}

// Mantine always returns local dates from date pickers.
// converts midnight local time to midnight for the given tz
export const dateToMidnight = (date: Date, timeZone: string) => {
  date.setUTCHours(0,0,0,0);
  return convertToTz(date, timeZone);
}

// the end date in a date range should be inclusive of all hours for that day
export const dateToLastSecondOfDay = (date: Date, timeZone: string) => {
  date.setUTCHours(23,59,59);
  return convertToTz(date, timeZone);
}

export const nMinutesAgo = (minutes: number = 5) => {
  const nowEpoch = Date.now();
  const minutesInMs = minutes * 60 * 1000;
  return new Date(nowEpoch - minutesInMs);
}

export const getYearsBetweenDates = (startDate: Date, endDate: Date) => {
  // get all of the years between the two dates, inclusive
  const years: number[] = [];
  for (let year = startDate.getFullYear(); year <= endDate.getFullYear(); year++) {
    if (!years.includes(year)) {
      years.push(year);
    }
  }
  return years;
};

export const isValidTimeZone = (tz: string) => {
  try {
    Intl.DateTimeFormat(undefined, {timeZone: tz});
    return true;
  } catch (ex) {
    return false;
  }
}


const convertToTz = (date: Date, timeZone: string) => {
  // Given a date and timezone, add the GMT offset to the date
  // most useful for going from a "year start" or "day start" in UTC
  // to a "year start" or "day start" of a given timezone
  const longOffsetFormatter = new Intl.DateTimeFormat("en-US", {timeZone, timeZoneName: "longOffset"});
  const longOffsetString = longOffsetFormatter.format(date);

  // longOffsetString.split('GMT')[1] will give us '-05:00'
  const gmtOffset = longOffsetString.split('GMT')[1] || 'Z';
  // get the ISO formatted date without the Z or + to indicate the timezone
  // don't need to handle the "-" timezone offset because toISOString always
  // returns the format in UTC
  const dtStrWithoutTz = date.toISOString().split('Z')[0].split('+')[0]
  return new Date(dtStrWithoutTz + gmtOffset);
};