import { createSelector } from "reselect";
import { IAlgorithmV1Config, IAlgorithmV3Config, IAllocationRunInputData } from "shared/types/allocation";
import { Program } from "shared/types/program";
import { Subscription } from "shared/types/subscription";
import { nMinutesAgo } from "shared/utils/dates";
import { RootState } from "store";

const root = (state: RootState) => state.amp__allocation_runs;

const byId = createSelector(root, s => s.byId);

export const getAllocationRunById = createSelector(
  byId,
  (_: RootState, allocationRunId: string) => allocationRunId,
  (runsById, id) => runsById[id]
);


export const getAllocationRunsById = createSelector(
  byId,
  (_: RootState, allocationRunIds: string[]) => allocationRunIds,
  (runsById, ids) => ids.map(id => runsById[id]).filter(x => !!x),
);


const getAllocationRunConfig = createSelector(
  getAllocationRunById,
  (run) => ({
    config: run?.allocation_config.algorithm_config,
    version: run?.allocation_config.algorithm_config_version,
  })
);


export const getAllocationRunStartAndEnd = createSelector(
  getAllocationRunConfig,
  ({ config, version }) => {
    if (!config || !version) {
      return {
        start: null,
        end: null,
      }
    }
    if (version === 'v1' || version === 'v2') {
      const year = (config as IAlgorithmV1Config).year_for_run;
      const start = new Date();
      const end = new Date();
      start.setFullYear(year, 0, 1);
      start.setUTCHours(0, 0, 0, 0);
      end.setFullYear(year, 11, 31);
      end.setUTCHours(23, 59, 59, 999);

      return {
        start,
        end,
      }
    } else if (version === 'v3' || version === 'v4') {
      const startStr = (config as IAlgorithmV3Config).start;
      const endStr = (config as IAlgorithmV3Config).end;
      return {
        start: new Date(startStr),
        end: new Date(endStr),
      }
    }
  }
)

const summaryResultsById = createSelector(root, s => s.summaryResultsById);

export const getAllocationSummaryResultById = createSelector(
  summaryResultsById,
  (_: RootState, allocationRunId: string) => allocationRunId,
  (runsById, id) => runsById[id],
);

const inputsById = createSelector(root, s => s.inputsById);

export const getAllocationInputDataById = createSelector(
  inputsById,
  (_: RootState, allocationRunId: string) => allocationRunId,
  (inputsById, id) => {
    const inputData = inputsById[id]
    const programs = inputData?.programs?.map(programData => new Program(programData)) || [];
    const subs = inputData?.customer_subscriptions?.map(sub => new Subscription(sub)) || [];

    return {
      programs: programs,
      customer_subscriptions: subs,
      generator_assignments: inputData?.generator_assignments || [],
      generators: inputData?.generators
    } as IAllocationRunInputData
  }
);

const customerResultsById = createSelector(root, s => s.customerResultsById);

export const getAllocationCustomerResultByRunIdAndCustomerId = createSelector(
  customerResultsById,
  (_: RootState, ids: { runId: string, customerId: string }) => ids,
  (customerResultsById, ids) => customerResultsById[ids.runId]?.[ids.customerId],
);

const stdDeliveryResultsById = createSelector(root, s => s.stdDeliveryResultsById);

export const getStdDeliveryResultById = createSelector(
  stdDeliveryResultsById,
  (_: RootState, allocationRunId: string) => allocationRunId,
  (stdDeliveryResultsById, id) => stdDeliveryResultsById[id],
);

const programResultsById = createSelector(root, s => s.programResultsById);

export const getAllocationProgramResultByRunIdAndProgramId = createSelector(
  programResultsById,
  (_: RootState, ids: { runId: string, programId: string }) => ids,
  (programResultsById, ids) => programResultsById[ids.runId]?.[ids.programId],
);

const getBulkFetchAllocationInputsResponse = createSelector(
  root,
  (rootState) => rootState.bulkFetchAllocationInputsResponse,
);

export const getBulkFetchAllocationInputsLoading = createSelector(
  getBulkFetchAllocationInputsResponse,
  (bulkInputsResp) => bulkInputsResp.isFetching,
);

export const getBulkFetchAllocationInputsArgs = createSelector(
  getBulkFetchAllocationInputsResponse,
  bulkInputsResp => bulkInputsResp.serializedArgs,
);

export const getBulkFetchInputsError = createSelector(
  getBulkFetchAllocationInputsResponse,
  (inputsResp) => inputsResp.fetchFailed,
);

export const getBulkFetchInputsData = createSelector(
  getBulkFetchAllocationInputsResponse,
  (inputsResp) => inputsResp.data || {},
);

export const shouldBulkFetchAllocationInputs = createSelector(
  getBulkFetchAllocationInputsResponse,
  (_: RootState, serializedArgs: string) => serializedArgs,
  getBulkFetchAllocationInputsArgs,
  (bulkFetchRes, newArgs, previousArgs) => {
    if (!bulkFetchRes) {
      return true;
    }
    const isExpired = bulkFetchRes.lastReceived === null || bulkFetchRes.lastReceived < nMinutesAgo(5);
    if (isExpired && !bulkFetchRes.isFetching && !bulkFetchRes.fetchFailed) {
      return true
    }
    const didArgsChange = newArgs !== previousArgs;
    if (didArgsChange) {
      return true
    }
    return false;
  }
);
