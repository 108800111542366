import { registryQueries } from "shared/api/queries";
import { IRegistryPaginationResponse } from "shared/types/api";
import { IDevice } from "shared/types/device";

const deviceQueries = registryQueries.injectEndpoints({
  endpoints: (build) => ({
    listDevices: build.query<IRegistryPaginationResponse<IDevice>, {offset: number, limit: number} | string>({
      query: (qsOrObj) => {
        if (typeof qsOrObj === 'string') {
          return `/v1/app/devices?${qsOrObj}`
        } else {
          return `/v1/app/devices?offset=${qsOrObj.offset}&limit=${qsOrObj.limit}`;
        }
      },

      providesTags: res => res ? [{type: 'Device', id: 'PAGE'}, ...res.data.map(({id}) => ({type: 'Device' as const, id}))] : []
    }),
  })
});

export const {
  useListDevicesQuery,
} = deviceQueries;