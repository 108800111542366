import { IGeneratorAssignment } from './assignment';
import { IGenerator } from './generator';
import { Program } from './program';
import { Subscription } from './subscription';


export interface IAllocationRunInputData {
  // TODO: move to class instances for generators and assignments
  programs: Program[];
  generators?: IGenerator[];
  customer_subscriptions: Subscription[];
  generator_assignments: IGeneratorAssignment[];
}

export interface IAllocationRunAccountOutputData {
  results: {
    start_date: string
    account_load_wh: number
    generation_dedicated_wh: number
  }[]
}

export interface IAllocationRunProgramOutputData {
  results: {
    start_date: string
    program_load_wh: number
    program_generation_wh: number
  }[]
}

export enum AllocationStatusReason {
  ERROR__INVALID_CONFIG = 'ERROR__INVALID_CONFIG',
  ERROR__INVALID_ALGO_VERSION = 'ERROR__INVALID_ALGO_VERSION',
  ERROR__NO_INPUT_DATA = 'ERROR__NO_INPUT_DATA',
  ERROR__INTERNAL_ERROR = 'ERROR__INTERNAL_ERROR',

  FINISHED__NO_PROGRAMS = 'FINISHED__NO_PROGRAMS',
  FINISHED__SUCCESSFUL_RUN = 'FINISHED__SUCCESSFUL_RUN',
}

export enum AllocationCanBeSettledReason {
  FALSE__NOT_FINISHED = 'FALSE__NOT_FINISHED',
  FALSE__HYPOTHETICAL_PROGRAMS = 'FALSE__HYPOTHETICAL_PROGRAMS',
  FALSE__OVERLAPS_WITH_EXISTING_SETTLEMENT = 'FALSE__OVERLAPS_WITH_EXISTING_SETTLEMENT',
  FALSE__ALREADY_SETTLED = 'FALSE__ALREADY_SETTLED',
  FALSE__AUTO_REJECTED = 'FALSE__AUTO_REJECTED',
  FALSE__SETTLEMENT_REJECTED = 'FALSE__SETTLEMENT_REJECTED',
  TRUE__ALL_CHECKS_PASSED = 'TRUE__ALL_CHECKS_PASSED',
}

interface IAlgorithmConfig {
  config_version: string
  algorithm_version: string,

  [key: string]: unknown
}

export interface IAlgorithmV1Config extends IAlgorithmConfig {
  year_for_run: number
}

export interface IAlgorithmV2Config extends IAlgorithmConfig {
  year_for_run: number
}

export interface IAlgorithmV3Config extends IAlgorithmConfig {
  start: string
  end: string
}

export interface IAllocationReportReleaseData {
  released_at: string | null
  released_by_actor_id: string | null
  status: "not_started" | "success" | "in_progress" | "error"
  worker_job_id: string | null
  released_customer_statuses: {[customerId: string]: {
    status: "success" | "failed"
    status_updated_at: string
    reason: string
  }}
}


interface IAllocationRunConfig {
  algorithm_config_version: string
  last_run_worker_job_id: string
  request_id: string
  status_reason?: AllocationStatusReason,
  can_be_settled_reason?: AllocationCanBeSettledReason,
  algorithm_config: IAlgorithmV1Config | IAlgorithmV2Config | IAlgorithmV3Config
  report_release?: IAllocationReportReleaseData
  num_total_reviews?: number
  num_review_status_counts?: Record<AllocationSettlementReviewStatus, number>
}

export enum AllocationRunStatus {
  ERROR = "ERROR",
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED",
  PAUSED = "PAUSED",
  SETTLED = "SETTLED",
  IN_SETTLEMENT_REVIEW = "IN_SETTLEMENT_REVIEW",
  SETTLEMENT_REJECTED = "SETTLEMENT_REJECTED",
  SETTLEMENT_APPROVED = "SETTLEMENT_APPROVED",
  SETTLEMENT_AUTO_REJECTED = "SETTLEMENT_AUTO_REJECTED",
}

// TODO: make a class that implements dataToDisplayValues?
export interface IAllocationRun {
  id: string
  customer_id: string
  status: AllocationRunStatus
  created_at: string
  created_by: string
  updated_at: string | null
  allocation_config: IAllocationRunConfig
  can_be_settled: boolean | null
  results_location?: string | null
  description?: string | null
}


export interface ICreateAllocationRunPayload {
  start_immediately: boolean;
  description: string | null;
  algorithm_config_version: 'v1' | 'v2' | 'v3' | 'v4';
  config: {
    year_for_run?: number;
    start?: string;
    end?: string;
    program_ids?: string[];
  };
}

export interface IAllocationRunUpdatePayload {
  allocation_run_id: string
  customer_id: string
}

export const dataToDisplayValues = (run: IAllocationRun) => {
  return {
    yearForRun: (run.allocation_config.algorithm_config.year_for_run || 2021) as number,
    startImmediately: true,
    description: run.description,
  };
};


export const displayValuesToRequestData = (values: ReturnType<typeof dataToDisplayValues>) => {
  return {
    config: {year_for_run: values.yearForRun},
    start_immediately: values.startImmediately,
    description: values.description,
  };
};


export const errorResponseToDisplayErrors = (errs: Record<string, string[]>) => {
  const displayErrors: Record<string, string> = {};

  const fieldMap: Record<string, string> = {
     name: 'name',
     description: 'description',
     // TODO: decompose one field into multiple
     // lat_lng: 'latLng'
     capacity_mw: 'capacityMw',
   };

  Object.entries(errs).forEach(([field, errors]) => {
     if (errors.length && fieldMap[field]) {
       displayErrors[fieldMap[field]] = errors[0];
     }
  });

  return displayErrors;
};


export interface IAllocationResult<T> {
  results: Array<T>
}

export interface IStandardDeliveryResult {
  results_type: string,
  customer_id: string,
  hourly_results: {
    hour: string,
    mix: {
      [fuel: string]: {
        mwh: number,
        sum_co2e_lbs: number,
      },
    },
  }[]
}

export interface ISubscriptionSummary {
  program_id: string,
  subscribed_customer_id: string,
  start: string,
  end: string,
  allocated_generation_mwh: number,
  generation_commit_pct: number,
  committed_generation_wh: number,
  program_generation_mwh: number,
  customer_consumption_mwh: number
}

export interface IAllocationSummaryResult {
  results_type: string
  allocation_run_id: string
  output_schema_version: string
  summary_results: {
    num_generators: number,
    total_generation_mwh: number,
    total_program_generation_mwh: number,
    num_customers: number,
    total_load_mwh: number,
    num_programs: number,
    generation_matched_pct: number,
    by_subscription_id: {
      [subscriptionId: string]: ISubscriptionSummary
    },
    hourly_gen_and_load_by_program: {
      [programId: string]: {
        hour: string
        total_gen_wh: number
        load_wh: number
        dedicated_gen_wh: number
        allocated_gen_wh: number
      }[],
    },
  }
}

export interface ICustomerAllocationSummaryResult {
  results_type: string,
  customer_id: string,
  output_schema_version: 'v1',
  hourly_results: {
    datetime: string,
    consumption_wh: number,
    subscription_program_generation: {
      subscription_id: string,
      program_id: string,
      allocated_wh: number,
      dedicated_wh: number,
      excess_gen_wh: number,
      allocated_mwh_by_generator_id: {
        [generatorId: string]: number,
      }
      dedicated_mwh_by_generator_id: {
        [generatorId: string]: number,
      }
    }[],
  }[]
}

interface ISubscriptionAllocationResultDatum {
  customer_id: string,
  consumption_wh: number,
  allocated_wh: number,
  dedicated_wh: number,
  excess_gen_wh: number,
  allocated_mwh_by_generator_id: Record<string, number>,
  dedicated_mwh_by_generator_id: Record<string, number>,
}

export interface IProgramAllocationSummaryResult {
  results_type: string,
  program_id: string,
  output_schema_version: 'v1',
  hourly_results: {
    datetime: string,
    generation_mwh: number,
    program_allocation_by_subscription_id: Record<string, ISubscriptionAllocationResultDatum>,
  }[],
}

export interface IAllocationDiagnosticItem {
  level: 'debug' | 'info' | 'warning' | 'error' | 'success' | 'critical' | 'fatal'
  family: string
  summary: string
  detail: string
  timestamp: string
  data: Record<string, unknown> | null
  allocation_run_id: string
  id: string
  customer_id: string
}

export interface IAllocationSettlementReview {
  id: string
  customer_id: string
  allocation_run_id: string
  status: AllocationSettlementReviewStatus
  data: Record<string, any>
  requested_by_id: string
  reviewer_id: string
  requested_at: string
  reviewed_at: string | null
  updated_at: string | null
}

export enum AllocationSettlementReviewStatus {
  PENDING = 'PENDING',
  RESCINDED = 'RESCINDED',
  DECLINED = 'DECLINED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export interface ICreateAllocationReviewPayload {
  reviewer_user_ids: string[]
  include_self: boolean
  send_review_email_to_self: boolean
}

 export function getAllocationStatusText(allocationRun: IAllocationRun)  {
  let background = 'rgba(0,0,0,0)';
  let color = '#000000';
  let text = '';

  switch (allocationRun.status) {
    case AllocationRunStatus.IN_PROGRESS:
      color = '#5C5F66';
      text = 'Processing...';
      break;
    case AllocationRunStatus.NOT_STARTED:
      // we shouldn't see this, allocations are kicked off immediately through the UI
      color = '#5C5F66';
      text = 'Not started';
      break;
    case AllocationRunStatus.PAUSED:
      // we shouldn't see this, just handling the case
      color = '#5C5F66';
      text = 'Not started';
      break;
    case AllocationRunStatus.ARCHIVED:
      // we shouldn't see this, just handling the case
      color = '#5C5F66';
      text = 'Archived';
      break;
    case AllocationRunStatus.DELETED:
      // we shouldn't see this, just handling the case
      color = '#5C5F66';
      text = 'Deleted';
      break;
    case AllocationRunStatus.ERROR:
      background = 'var(--color-se-red-1)';
      color = '#FFFFFF';
      text = 'Error';
      break;
    case AllocationRunStatus.FINISHED:
      color = '#5C5F66';
      text = 'Completed';
      break;
    case AllocationRunStatus.IN_SETTLEMENT_REVIEW:
      const hasReviewCounts = !!allocationRun.allocation_config?.num_total_reviews && !!allocationRun.allocation_config.num_review_status_counts;
      const approvedReviews = allocationRun.allocation_config.num_review_status_counts?.[AllocationSettlementReviewStatus.APPROVED] || 0;
      const rescindedReviews = allocationRun.allocation_config.num_review_status_counts?.[AllocationSettlementReviewStatus.RESCINDED] || 0;
      const declinedReviews = allocationRun.allocation_config.num_review_status_counts?.[AllocationSettlementReviewStatus.DECLINED] || 0;
      const numPositiveReviews = approvedReviews + rescindedReviews + declinedReviews;
      const reviewCountString = ` ${numPositiveReviews.toFixed(0)}/${allocationRun.allocation_config.num_total_reviews?.toFixed(0) || '?'}`
      background = 'var(--color-orange-2)';
      color = '#D9480F';
      text = `In approval${hasReviewCounts ? reviewCountString : ''}`;
      break;
    case AllocationRunStatus.SETTLEMENT_REJECTED:
      background = 'var(--color-se-red-1)';
      color = '#FFFFFF';
      text = 'Declined';
      break;
    case AllocationRunStatus.SETTLEMENT_AUTO_REJECTED:
      background = '#FFF5F5';
      color = '#C92A2A';
      text = 'Auto-rejected';
      break;
    case AllocationRunStatus.SETTLEMENT_APPROVED:
      background = 'var(--color-teal-2)';
      color = 'var(--color-green-2)';
      text = 'Approved';
      break;
    case AllocationRunStatus.SETTLED:
      background = 'var(--color-green-2)';
      color = '#FFFFFF';
      text = 'Settled';
      break;
  }
  return { text, background, color };
}