import Highcharts from 'highcharts';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import { mergeDeepLeft } from 'ramda';

import { getViewingTimeZone } from 'amp/store/ui/selectors';
import { LegacyRef } from 'react';
import { useAppSelector } from 'store';
import './baseChart.css';

export default function BaseChart({
  chartTitle, xAxisTitle, yAxisTitle, overrideOptions, chartRef, enableLegendClick,
}: { chartTitle?: string, xAxisTitle?: string, yAxisTitle?: string, overrideOptions?: Highcharts.Options, chartRef?: LegacyRef<HighchartsReactRefObject>, enableLegendClick?: boolean }) {
  const timezone = useAppSelector(getViewingTimeZone);

  // The default chart is a "column" chart with timestamps on the x axis.
  // All the options set here can be changed using the overrideOptions object.
  const defaultOptions: Highcharts.Options = {
    time: {
      useUTC: timezone.toLowerCase() === 'utc',
      timezone,
    },
    chart: {
      type: 'column',
    },
    legend: {
      enabled: false,
      itemStyle: {
        cursor: 'default',
      },
    },
    plotOptions: {
      column: {
        minPointLength: 4,
        borderWidth: 0.5,
        pointPadding: 0,
        groupPadding: 0,
      },
      series: {
        events: {
          legendItemClick: enableLegendClick ? undefined : e => e.preventDefault(),
        },
        point: {
          events: {
            legendItemClick: enableLegendClick ? undefined : e => e.preventDefault(),
          },
        },
      },
    },
    title: {
      text: chartTitle || '',
      align: 'left',
      style: {
        color: 'var(--color-blue-2)',
      }
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: xAxisTitle || '',
      },
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },
    yAxis: {
      title: {
        text: yAxisTitle || '',
      },
    },
    credits: { enabled: false },
  };
  const mergedOptions = mergeDeepLeft(overrideOptions || {}, defaultOptions);

  return (
    <div className="base-chart--chart-container">
      <HighchartsReact highcharts={Highcharts} options={mergedOptions} ref={chartRef} />
    </div>
  );
}