import { Button } from '@mantine/core';
import { captureException } from '@sentry/react';
import { IconMoodSadDizzy } from '@tabler/icons-react';
import { viewingOpCoId } from 'amp/store/ui/selectors';
import { setViewingOpco } from 'amp/store/ui/slice';
import { useEffect } from 'react';
import { Navigate, Outlet, Route, useNavigate, useRouteError, useSearchParams } from 'react-router-dom';

import PageBreadcrumbs from 'amp/components/PageBreadcrumbs/pageBreadcrumbs';
import { useAppDispatch, useAppSelector } from 'store';
import Shell from './components/Shell/shell';
import DevicesView from './views/Devices';

// TODO: make this shared
export const RegistryOutlet = () => {
  const [params] = useSearchParams();
  const ociInParams = params.get('oci');
  const dispatch = useAppDispatch();
  const ociInState = useAppSelector(viewingOpCoId);

  useEffect(() => {
    if (ociInParams !== ociInState) {
      dispatch(setViewingOpco(ociInParams));
    }
  }, [ociInParams, ociInState, dispatch]);

  return (
    <Shell>
      <Outlet />
    </Shell>
  )
}

// TODO: make this shared
export const RegistryErrorBoundary = () => {
  const nav = useNavigate();
  const error = useRouteError();

  if (error) {
    captureException(error);
  }

  return (
    <Shell>
      <div className="error-boundary--breadcrumbs">
        <PageBreadcrumbs />
      </div>
      <div className="error-boundary--container">
        <div className="error-boundary--icon">
          <IconMoodSadDizzy color="var(--color-se-red-1)" height={32} width={32} />
        </div>
        <div className="error-boundary-text--container">
          <h2 className="error-boundary-text--title">Whoops!</h2>
          <p className="error-boundary-text--description">Something went wrong, sorry about that. The engineering team has been alerted. You can reload to try again, or try another time.</p>
          <Button className="error-boundary-text--button" onClick={() => nav(0)}>Reload</Button>
        </div>
      </div>
    </Shell>
  )
};

const registryRoutes = (
  <>
    <Route path="*" element={<Navigate to="/registry/devices" replace />} index/>
    <Route path="devices" element={<DevicesView />} >
    </Route>
  </>
)

export default registryRoutes;