import { Box, Button, Center, Group, Loader, Pill, Select, Skeleton, Stack, Table, Text, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconBolt, IconCalendar, IconCheck, IconX } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import { useCreateRunMutation } from 'amp/api/allocationRuns';
import { AmpLink } from 'amp/components/Link';
import PageBreadcrumbs from 'amp/components/PageBreadcrumbs/pageBreadcrumbs';
import { useAmpNav } from 'amp/hooks';
import { useProgram, useProgramsPage } from 'amp/store/programs/hooks';
import { getViewingCustomerType, getViewingOpCoId, getViewingTimeZone } from 'amp/store/ui/selectors';
import { useSearchParams } from 'react-router-dom';
import BaseCheckbox from 'shared/components/Checkbox';
import UtcDatePicker from 'shared/components/DatePicker/utcDatePicker';
import BasePaper from 'shared/components/Paper/basePaper';
import { getMaxAllocationEndDateForCustomer } from 'shared/store/customers/selectors';
import { getCurrentUser, getOpcos } from 'shared/store/user/selectors';
import { CustomerType } from 'shared/types/customer';
import { filterActiveAssignments, filterActiveSubscriptions, ProgramStatus } from 'shared/types/program';
import { getLastYearEnd, getLastYearStart, toNumericDateString } from 'shared/utils/dates';
import { tracker, TrackEventNames } from 'shared/utils/tracker';
import { useAppSelector } from 'store';
import './style.css';


const ProgramSelectionRow = ({ programId, onProgramSelected, selected }: { programId: string, onProgramSelected: (programId: string) => void, selected: boolean }) => {
  const programRes = useProgram(programId);

  const activeAssignments = filterActiveAssignments(programRes.data.assignments);
  const activeSubscriptions = filterActiveSubscriptions(programRes.data.subscriptions);

  const accountingPeriod = programRes.data?.program?.data.program_config?.accounting_period;
  const accountingPeriodText = accountingPeriod === 'hourly' ? '24/7 Program' : 'Annual program';

  const isHypothetical = programRes.data?.program?.is_hypothetical;
  return (
    <Table.Tr className="allocation-create--program-row" onClick={() => onProgramSelected(programId)}>
      <Table.Td>
        <Skeleton visible={programRes.isLoading || programRes.isFetching}>
          <Group>
            <BaseCheckbox size="16px" color="var(--color-green-2)" checked={selected} />
            <Text fz={14} c="var(--color-black-2)">
              {programRes.data?.program?.name || 'unknown name'}
            </Text>
            <Pill c="var(--color-blue-2)" fz={12}>
              {accountingPeriod ? accountingPeriodText : 'unknown accounting period'}
            </Pill>
          </Group>
        </Skeleton>
      </Table.Td>
      <Table.Td>
        <Skeleton visible={programRes.isLoading || programRes.isFetching}>
          <Text fz={14} c="var(--color-black-2)" ta="center">
            {isHypothetical === false ? 'Live' : 'Hypothetical'}
          </Text>
        </Skeleton>
      </Table.Td>
      <Table.Td>
        <Skeleton visible={programRes.isLoading || programRes.isFetching}>
          <Text fz={14} c="var(--color-black-2)" ta="center">
            {activeSubscriptions ? activeSubscriptions.length : 'unknown'}
          </Text>
        </Skeleton>
      </Table.Td>
      <Table.Td>
        <Skeleton visible={programRes.isLoading || programRes.isFetching}>
          <Group justify="center">
            <IconBolt color="var(--color-green-2)" size={16} />
            <Text fz={14} c="var(--color-black-2)" ta="center">
              {activeAssignments ? activeAssignments.length : 'unknown'}
            </Text>
          </Group>
        </Skeleton>
      </Table.Td>
    </Table.Tr>
  );
}

export default function AllocationCreateView() {
  const [params] = useSearchParams();
  const oci = useAppSelector(getViewingOpCoId);
  const tzName = useAppSelector(getViewingTimeZone);
  const viewingCustomerType = useAppSelector(getViewingCustomerType);

  const maxForCustomer = useAppSelector(s => getMaxAllocationEndDateForCustomer(s, oci || ''));
  const currentUser = useAppSelector(s => getCurrentUser(s));
  const opcos = useAppSelector(s => getOpcos(s));

  const [maxEndDate, setMaxEndDate] = useState<Date | undefined>(undefined);
  const startDateStr = params.get('s') || getLastYearStart(tzName).toISOString();
  const endDateStr = params.get('e') || maxEndDate || getLastYearEnd(tzName).toISOString();
  const start = new Date(startDateStr);
  const end = new Date(endDateStr);

  const [description, setDescription] = useState<string | null>(null);
  const [selectedPrograms, setSelectedPrograms] = useState(new Set<string>());
  const [selectedOpco, setSelectedOpco] = useState<string | null>(null);
  const [createRun, createResponse] = useCreateRunMutation();
  const nav = useAmpNav();

  // TODO: may eventually stop scaling. I don't want to paginate the programs in the UI here, just have a list
  const programsRes = useProgramsPage({ page: 1, perPage: 50, statuses: [ProgramStatus.ACTIVE], customerId: selectedOpco });
  const programs = programsRes.data || [];

  useEffect(() => {
    setMaxEndDate(maxForCustomer);
  }, [maxForCustomer]);

  const onProgramSelected = (programId: string) => {
    const inSet = selectedPrograms.has(programId);
    if (inSet) {
      selectedPrograms.delete(programId);
    } else {
      selectedPrograms.add(programId);
    }
    setSelectedPrograms(new Set(selectedPrograms));
  }

  const createDisabled = (viewingCustomerType === CustomerType.UTILITY && selectedOpco === null) || description === null;
  const onSubmit = async () => {
    // the submit button is disabled in this case, this bail-out is for extra safety.
    if (createDisabled) {
      return;
    }

    // Validate inputs.
    if (start >= end) {
      // This is also checked on the backend via marshmallow schema validation, but also check it here so we can display a user-friendly error message.
      // Without this, backend-only schema validation fails silently to the user.
      notifications.show({
        title: 'Error',
        message: 'Allocation start time must be earlier than the allocation end time.',
        color: 'red',
        icon: <IconX size={20} />,
      });
      return;
    }

    try {
      await createRun({
        data: {
          start_immediately: true,
          algorithm_config_version: 'v4',
          config: {
            start: start.toISOString(),
            end: end.toISOString(),
            program_ids: selectedPrograms.size !== 0 ? Array.from(selectedPrograms) : undefined,
          },
          description,
        },
        customerId: selectedOpco || oci,
      }).unwrap();
      tracker.track(TrackEventNames.CAR, {description});
      notifications.show({
        title: 'Success',
        message: 'Successfully started the allocation run',
        color: "teal",
        icon: <IconCheck size={20} />,
      });
      nav('/dashboard/allocation');
    } catch (err) {
      notifications.show({
        title: 'Error',
        message: 'There was an issue kicking off the allocation',
        color: 'red',
        icon: <IconX size={20} />,
      });
    }
  };

  const loadingPrograms = programsRes.isLoading || programsRes.isFetching;
  return (
    <Stack>
      <PageBreadcrumbs />
      <Stack ml={60} mr={60}>
        <Group justify="space-between">
          <Text fz={24} fw={700}>Create New Allocation</Text>
          <Group>
            <AmpLink to="/dashboard/allocation">
              <Button className="allocation-create--cancel-button">
                Cancel
              </Button>
            </AmpLink>
            <Button className={`allocation-create--submit-button${createDisabled ? ' disabled' : ''}`} loading={createResponse.isLoading} onClick={onSubmit} disabled={createDisabled}>
              Run Allocation
            </Button>
          </Group>
        </Group>
        <BasePaper titleContent="Allocation Details">
          <Group gap={60} align="flex-start">
            <Stack w="calc(50% - 30px)">
              <TextInput
                label="Name"
                value={description || ''}
                onChange={(e) => setDescription(e.target.value)}
                required={true}
              />
              {viewingCustomerType === CustomerType.UTILITY &&
                <Select
                  label="Operating Company"
                  allowDeselect={false}
                  data={opcos.map(opco => ({ label: opco.name, value: opco.id }))}
                  value={selectedOpco}
                  onChange={newValue => setSelectedOpco(newValue)}
                  required={true}
                />
              }
              <Group align="flex-end">
                <UtcDatePicker
                  value={start}
                  isStartDate={true}
                  maxDate={end}
                  label="Allocation Period"
                />
                <Text lh="36px">-</Text>
                <UtcDatePicker
                  value={end}
                  maxDate={maxEndDate}
                />
              </Group>
            </Stack>
            <Stack w="calc(50% - 30px)">
              <TextInput
                label="Created date"
                value={toNumericDateString(new Date())}
                rightSection={<IconCalendar />}
                disabled
              />
              <TextInput
                label="Created by"
                value={currentUser.name || 'Unknown user name'}
                disabled
              />
            </Stack>
          </Group>
        </BasePaper>

        <BasePaper titleContent="Program Details">
          {loadingPrograms &&
            <Box w="100%">
              <Center>
                <Loader />
              </Center>
            </Box>
          }
          {!loadingPrograms && viewingCustomerType === CustomerType.UTILITY && selectedOpco === null &&
            <Text fz={14} c="var(--color-blue-3)">
              Select an operating company above before choosing programs
            </Text>
          }
          {!loadingPrograms && (viewingCustomerType !== CustomerType.UTILITY || selectedOpco !== null) &&
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>
                    <Text fz={14} c="var(--color-blue-1)" fw={600}>Program Name</Text>
                  </Table.Th>
                  <Table.Th>
                    <Text ta="center" fz={14} c="var(--color-blue-1)" fw={600}>Program Type</Text>
                  </Table.Th>
                  <Table.Th>
                    <Text ta="center" fz={14} c="var(--color-blue-1)" fw={600}>Subscriptions</Text>
                  </Table.Th>
                  <Table.Th>
                    <Text ta="center" fz={14} c="var(--color-blue-1)" fw={600}>Generators Assigned</Text>
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {programs.map(p => (
                  <ProgramSelectionRow
                    key={p.id}
                    programId={p.id}
                    onProgramSelected={onProgramSelected}
                    selected={selectedPrograms.has(p.id)}
                  />
                ))}
              </Table.Tbody>
            </Table>
          }
        </BasePaper>
      </Stack>
    </Stack >
  );
}