import { Avatar, Box, Center, Group, Loader, Modal, Stack, Text, Tooltip } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconRefresh, IconThumbDown, IconThumbUp, IconX } from '@tabler/icons-react';
import { useMemo } from 'react';

import { useListReviewsForAllocationQuery, useResendReviewRequestEmailMutation, useUpdateAllocationSettlementApprovalMutation } from 'amp/api/allocationRuns';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { useBulkFetchPublicUsersQuery } from 'shared/api/users';
import { getCurrentUser } from 'shared/store/user/selectors';
import { AllocationSettlementReviewStatus, IAllocationSettlementReview } from 'shared/types/allocation';
import { IPublicUser } from 'shared/types/user';
import { tracker, TrackEventNames } from 'shared/utils/tracker';
import { useAppSelector } from 'store';

const canRescindReview = (sr: IAllocationSettlementReview) => {
  const rescindableStatuses = [AllocationSettlementReviewStatus.PENDING, AllocationSettlementReviewStatus.REJECTED];
  return rescindableStatuses.includes(sr.status);
}

const ViewReviewersModal = ({
  onClose,
  isOpen,
  runId,
}: { onClose: () => void, isOpen: boolean, runId: string }) => {
  const oci = useAppSelector(getViewingOpCoId);
  const currentUser = useAppSelector(s => getCurrentUser(s));
  const [resend, resendResp] = useResendReviewRequestEmailMutation();
  const [update, updateRes] = useUpdateAllocationSettlementApprovalMutation();

  const settlementReviewsResp = useListReviewsForAllocationQuery({ id: runId, customerId: oci });
  const settlementReviews = settlementReviewsResp.data?.data || [];
  const reviewerIds = settlementReviews.map(sr => sr.reviewer_id);
  const reviewersRes = useBulkFetchPublicUsersQuery(reviewerIds, { skip: !reviewerIds.length });

  const reviewersById = useMemo(() => {
    const reviewers: Record<string, IPublicUser> = {};
    reviewersRes.data?.data.forEach(reviewer => reviewers[reviewer.id] = reviewer);
    return reviewers;
  }, [reviewersRes.data]);

  const onResendEmailClick = (reviewId: string) => {
    resend({
      runId: runId,
      reviewId: reviewId,
      customerId: oci,
    }).unwrap().then(() => {
      // TODO: add confetti - needs to be tied to a "fun mode" user property
      notifications.show({
        title: 'Success',
        message: 'Successfully resent the review email',
        color: "teal",
        icon: <IconCheck style={{ width: '20px', height: '20px' }} />,
      });
      onClose();
    }).catch((err) => {
      if (err?.data?.error_code === 'reminder-recently-sent') {
        notifications.show({
          title: 'Error',
          message: 'Failed to send reminder email, prior reminder sent too recently',
          icon: <IconX style={{ width: '20px', height: '20px' }} />,
          color: "red",
        });
      } else {
        notifications.show({
          title: 'Error',
          message: 'Failed to resend the review email',
          icon: <IconX style={{ width: '20px', height: '20px' }} />,
          color: "red",
        });
      }
    })
  }

  const onRescindReviewClick = (reviewId: string) => {
    update({
      reviewId: reviewId,
      newStatus: AllocationSettlementReviewStatus.RESCINDED,
      customerId: oci,
    }).unwrap().then(() => {
      tracker.track(TrackEventNames.RARR, {runId, reviewId});
      notifications.show({
        title: 'Success',
        message: 'Successfully rescinded the allocation review request',
        color: "teal",
        icon: <IconCheck style={{ width: '20px', height: '20px' }} />,
      });
      onClose();
    }).catch(() => notifications.show({
      title: 'Error',
      message: 'Failed to rescind the review request',
      icon: <IconX style={{ width: '20px', height: '20px' }} />,
      color: "red",
    }))
  }

  const isLoading = reviewersRes.isLoading || reviewersRes.isFetching || settlementReviewsResp.isLoading || settlementReviewsResp.isFetching;
  return (
    <Modal
      onClose={onClose}
      opened={isOpen}
      title="Allocation Approvers"
    >
      {isLoading && <>
        <Box w="100%" h="200px">
          <Center>
            <Loader />
          </Center>
        </Box>
      </>}
      {!isLoading && <>
        <Stack>
          {settlementReviews.map(sr => (
            <Group justify="space-between" key={sr.id}>
              <Group>
                <Avatar.Group spacing="xs">
                  <Avatar color="white" bg="black" size={28}>
                    {reviewersById[sr.reviewer_id]?.name.slice(0, 1).toLocaleUpperCase()}
                  </Avatar>
                  {sr.status === AllocationSettlementReviewStatus.APPROVED && (
                    <Tooltip label="This user approved the allocation settlement">
                      <Avatar size={20} bg="var(--color-teal-2)">
                        <IconThumbUp size={16} color="var(--color-green-2)" />
                      </Avatar>
                    </Tooltip>
                  )}
                  {sr.status === AllocationSettlementReviewStatus.REJECTED && (
                    <Tooltip label="This user rejected the allocation settlement">
                      <Avatar size={20} bg="var(--color-red-1)">
                        <IconThumbDown size={16} color="var(--color-red-3)" />
                      </Avatar>
                    </Tooltip>
                  )}
                  {sr.status === AllocationSettlementReviewStatus.RESCINDED && (
                    <Tooltip label="The review request to this user was rescinded">
                      <Avatar size={20} bg="var(--color-neutral-6)">
                        <IconX size={16} color="var(--color-black-1)" />
                      </Avatar>
                    </Tooltip>
                  )}
                </Avatar.Group>
                <Text>{reviewersById[sr.reviewer_id]?.name || 'unknown name'}</Text>
              </Group>
              <Stack gap={4}>
                {(sr.status === AllocationSettlementReviewStatus.PENDING) &&
                  <Tooltip label="Resend the review reminder email">
                    <Group justify="space-between">
                      <Text fz={14}>Resend</Text>
                      {resendResp.isLoading && resendResp.originalArgs?.reviewId === sr.id && <Loader c="var(--color-grey-4)" size={16} />}
                      {(!resendResp.isLoading || resendResp.originalArgs?.reviewId !== sr.id) &&
                        <IconRefresh className="allocation-run-outlet--reviewers-modal-icon" onClick={() => onResendEmailClick(sr.id)} />
                      }
                    </Group>
                  </Tooltip>
                }
                {sr.requested_by_id === currentUser.id && canRescindReview(sr) &&
                  <Tooltip label="Rescind the review request from this user">
                    <Group justify="space-between">
                      <Text fz={14}>Rescind</Text>
                      {updateRes.isLoading && updateRes.originalArgs?.reviewId === sr.id && <Loader c="var(--color-grey-4)" size={16} />}
                      {(!updateRes.isLoading || updateRes.originalArgs?.reviewId !== sr.id) &&
                        <IconX className="allocation-run-outlet--reviewers-modal-icon" onClick={() => onRescindReviewClick(sr.id)} />
                      }
                    </Group>
                  </Tooltip>
                }
              </Stack>
            </Group>
          ))}
        </Stack>
      </>}
    </Modal>
  );
}

export default ViewReviewersModal;