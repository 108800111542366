import { AppShell, Button } from '@mantine/core';
import { captureException } from '@sentry/react';
import { IconMoodSadDizzy } from '@tabler/icons-react';
import { Navigate, Outlet, Route, useNavigate, useRouteError } from 'react-router-dom';
import { getCurrentUser } from 'shared/store/user/selectors';
import { isSingularityAdmin } from 'shared/types/user';
import { useAppSelector } from 'store';
import PageBreadcrumbs from './components/PageBreadcrumbs/pageBreadcrumbs';
import Navigation from './components/Sidebar';
import AsyncWorkOutlet from './outlets/async';
import CustomerOutlet from './outlets/customer';
import CustomersOutlet from './outlets/customers';
import DataOutlet from './outlets/data';
import DataAuthOutlet from './outlets/data/auth';
import DataCCDOutlet from './outlets/data/ccd';
import DataRegistryOutlet from './outlets/data/registry';
import CustomerAllocationRunView from './views/customerAllocationRun';
import CustomerAllocationRunsView from './views/customerAllocationRuns';
import CustomerAssetView from './views/customerAsset';
import CustomerAssetsView from './views/customerAssets';
import CustomerChildrenView from './views/customerChildren';
import CustomerConnectorView from './views/customerConnector';
import CustomerConnectorsView from './views/customerConnectors';
import CustomerExternalIdsView from './views/customerExternalIds';
import CustomerParentsView from './views/customerParents';
import CustomerReportView from './views/customerReport';
import CustomerReportsView from './views/customerReports';
import CustomerSSOView from './views/customerSSOConfig';
import CustomerSettingsView from './views/customerSettings';
import CustomerUserView from './views/customerUser';
import CustomerUsersView from './views/customerUsers';
import CustomersView from './views/customers';
import CustomersStarredView from './views/customersStarred';
import DataAuthQuery from './views/dataAuthQuery';
import DataAuthTable from './views/dataAuthTable';
import DataAuthTables from './views/dataAuthTables';
import DataCcdQuery from './views/dataCcdQuery';
import DataCCDTable from './views/dataCcdTable';
import DataCCDTables from './views/dataCcdTables';
import DataRegistryTable from './views/dataRegistryTable';
import DataRegistryTables from './views/dataRegistryTables';
import WorkerJobView from './views/job';
import WorkerJobsView from './views/jobs';
import ScheduledTaskView from './views/task';
import ScheduledTasksView from './views/tasks';


export const AdminOutlet = () => {
  const user = useAppSelector(getCurrentUser);

  if (!isSingularityAdmin(user)) {
    return <Navigate to="/"/>
  }

  return (
    <AppShell
      navbar={{ width: 220, breakpoint: 'sm' }}
      padding={0}
    >
      <Navigation />
      <AppShell.Main>
        <PageBreadcrumbs />
        <div>
          <Outlet />
        </div>
      </AppShell.Main>
    </AppShell>
  )
}

export const AdminErrorBoundary = () => {
  const nav = useNavigate();
  const error = useRouteError();

  if (error) {
    captureException(error);
  }

  return (
    <AppShell
      navbar={{ width: 220, breakpoint: 'sm' }}
      padding={0}
    >
      <Navigation />
      <AppShell.Main>
        <PageBreadcrumbs />
        <div className="error-boundary--container">
          <div className="error-boundary--icon">
            <IconMoodSadDizzy color="var(--color-se-red-1)" height={32} width={32} />
          </div>
          <div className="error-boundary-text--container">
            <h2 className="error-boundary-text--title">Whoops!</h2>
            <p className="error-boundary-text--description">Something went wrong, sorry about that. The engineering team has been alerted. You can reload to try again, or try another time.</p>
            <Button className="error-boundary-text--button" onClick={() => nav(0)}>Reload</Button>
          </div>
        </div>
      </AppShell.Main>
    </AppShell>
  )
};


const adminRoutes = (
  <>
    <Route element={<Navigate to="/admin/customers" replace />} index />

    <Route path="customers" element={<CustomersOutlet />}>
      <Route element={<CustomersView />} index />
      <Route element={<CustomersStarredView />} path="starred" />
      <Route path=":customerId" element={<CustomerOutlet />}>
        <Route element={<Navigate to="children" replace/>} index />
        <Route path="users">
          <Route element={<CustomerUsersView />} index />
          <Route path=":userId" element={<CustomerUserView />} />
        </Route>
        <Route path="parents" element={<CustomerParentsView />} />
        <Route path="children" element={<CustomerChildrenView />} />
        <Route path="customer-reports">
          <Route element={<CustomerReportsView />} index />
          <Route path=":reportId" element={<CustomerReportView />} />
        </Route>
        <Route path="external-ids" element={<CustomerExternalIdsView />} />
        <Route path="assets">
          <Route element={<CustomerAssetsView />} index />
          <Route path=":assetId" element={<CustomerAssetView />} />
        </Route>
        <Route path="connectors">
          <Route element={<CustomerConnectorsView />} index />
          <Route path=":connectorId" element={<CustomerConnectorView />} />
        </Route>
        <Route path="allocation-runs">
          <Route element={<CustomerAllocationRunsView />} index />
          <Route path=":runId" element={<CustomerAllocationRunView />} />
        </Route>
        <Route path="sso" element={<CustomerSSOView />} />
        <Route path="settings">
          <Route element={<CustomerSettingsView />} index />
        </Route>
      </Route>
    </Route>

    <Route path="async-work" element={<AsyncWorkOutlet />}>
      <Route element={<Navigate to="jobs" replace />} index />
      <Route path="jobs">
        <Route element={<WorkerJobsView />} index/>
        <Route element={<WorkerJobView />} path=":jobId" />
      </Route>
      <Route path="scheduled-tasks">
        <Route element={<ScheduledTasksView />} index/>
        <Route element={<ScheduledTaskView />} path=":taskId" />
      </Route>
    </Route>

    <Route path="data" element={<DataOutlet />}>
      <Route element={<Navigate to="ccd" replace />} index />
      <Route path="ccd" element={<DataCCDOutlet />}>
        <Route element={<Navigate to="tables" replace />} index />
        <Route path="query" element={<DataCcdQuery />}/>
        <Route path="tables">
          <Route element={<DataCCDTables />} index />
          <Route path=":table" element={<DataCCDTable />} />
        </Route>
      </Route>
      <Route path="user-auth" element={<DataAuthOutlet />}>
        <Route element={<Navigate to="tables" replace />} index />
        <Route path="query" element={<DataAuthQuery />}/>
        <Route path="tables">
          <Route element={<DataAuthTables />} index />
          <Route path=":table" element={<DataAuthTable />} />
        </Route>
      </Route>
      <Route path="registry" element={<DataRegistryOutlet />}>
        <Route element={<Navigate to="tables" replace />} index />
        <Route path="query" element={<DataAuthQuery />}/>
        <Route path="tables">
          <Route element={<DataRegistryTables />} index />
          <Route path=":table" element={<DataRegistryTable />} />
        </Route>
      </Route>
    </Route>
  </>
)

export default adminRoutes;