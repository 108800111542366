import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { isEmpty, sum } from "ramda";
import { queries } from "shared/api/queries";
import { AggregationTypes, GenerationByFuelAggEventData, IAggregatedAssetEvent, IEmissionsFromGenerationData, IListAggregatedAssetEventsResponse, ITotalGenerationEventData } from "shared/types/aggregatedEvents";
import { IPagination, IPaginationResponse } from "shared/types/api";
import { IGenerationSource } from "shared/types/source";
import { IFetchAggregatedEventsArgs } from "./assetEvents";


export interface IListSourcesArgs {
  page: number
  perPage: number
  usState?: string | null
  fuelCategory?: string | null
  nameSearch?: string | null
  customerId?: string | null
  assetTypes?: string[] | null
}

interface IFetchUtilityInventoryOptions {
  skipSourcesCount: boolean
  customerIds: string[]
}

interface IListUtilitySourcesArgs extends IListSourcesArgs {
  customerIds: string[];
}


export interface ISourcesFacets {
  fuel_categories: Record<string, number>,
  us_states: Record<string, number>
}

const sourcesPage: {type: 'Source', id: string} = { type: 'Source', id: 'PAGE' }

const getAggFn = (aggType: AggregationTypes) => {
  if (aggType === AggregationTypes.GENERATION_BY_FUEL) {
    return (events: IAggregatedAssetEvent[]) => {
      const data: Record<string, ITotalGenerationEventData> = {};
      events.forEach(event => {
        Object.entries(event.data).forEach(([key, value]) => {
          if (key in data) {
            data[key].max_generated_w += value.max_generated_w;
            data[key].min_generated_w += value.min_generated_w;
            data[key].mean_generated_w += value.mean_generated_w;
            data[key].sum_generated_wh += value.sum_generated_wh;
          } else {
            data[key] = {...value};
          }
        })
      })
      return {
        ...events[0],
        data: data as GenerationByFuelAggEventData,
      }
    }
  } else {
    // TODO: this else case is currently only for aggregated emissions data, it should be its own "else if" case
    return (events: IAggregatedAssetEvent[]) => {
      const data: Record<string, number> = {};
      events.forEach(event => {
        Object.entries(event.data).forEach(([key, value]) => {
          if (key in data) {
            data[key] += value;
          } else {
            data[key] = value;
          }
        })
      });
      const asEmissionsData = data as unknown as IEmissionsFromGenerationData;
      const totalMWh = (asEmissionsData.sum_generated_wh || 0) / 1_000_000;
      asEmissionsData.mean_co2_rate_lb_per_mwh = asEmissionsData.sum_co2_mass_lb / (totalMWh || 1);
      asEmissionsData.mean_co2e_rate_lb_per_mwh = asEmissionsData.sum_co2e_mass_lb / (totalMWh || 1);
      asEmissionsData.mean_ch4_rate_lb_per_mwh = asEmissionsData.sum_ch4_mass_lb / (totalMWh || 1);
      asEmissionsData.mean_n2o_rate_lb_per_mwh = asEmissionsData.sum_n2o_mass_lb / (totalMWh || 1);
      return {
        ...events[0],
        data: asEmissionsData,
      }
    }
  }
}


const sourcesQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    paginateSources: build.query<IPaginationResponse<IGenerationSource>, IListSourcesArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('page', body.page.toString());
        qs.set('per_page', body.perPage.toString());
        if (body.usState) {
          qs.set('us_state', body.usState);
        }

        if (body.fuelCategory) {
          qs.set('fuel_category', body.fuelCategory);
        }

        if (body.nameSearch) {
          qs.set('name', body.nameSearch);
        }

        if (body.customerId) {
          qs.set('customer_id', body.customerId);
        }

        return {
          url: `/v2/sources/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [sourcesPage, ...res.data.map(source => ({ type: 'Source' as const, id: source.id }))] : [],
    }),

    fetchSource: build.query<{ source: IGenerationSource }, {id: string, customerId?: string | null}>({
      query: ({id, customerId}) => `/v2/sources/${id}?${customerId ? `customer_id=${customerId}` : ''}`,
      providesTags: (res) => res ? [{ type: 'Source', id: res.source.id }] : [],
    }),

    fetchUtilitySources: build.query<IPaginationResponse<IGenerationSource>, IListUtilitySourcesArgs>({
      async queryFn({customerIds, page, perPage, usState, fuelCategory, nameSearch, assetTypes}, _queryApi, _extra, fetchWithBQ) {
        const qs = new URLSearchParams();
        qs.set('page', page.toString());
        qs.set('per_page', perPage.toString());
        if (usState) {
          qs.set('us_state', usState);
        }

        if (fuelCategory) {
          qs.set('fuel_category', fuelCategory);
        }

        if (nameSearch) {
          qs.set('name', nameSearch);
        }

        assetTypes?.forEach(at => {
          qs.append('asset_type', at);
        });

        const sourcesById: Record<string, IGenerationSource> = {};
        let pagination: IPagination = {this: 1, last: 1, total_items: 1, first: 1};
        let latestError: FetchBaseQueryError | null = null;
        const responses = await Promise.all(customerIds.map(customerId => fetchWithBQ(`/v2/sources/?${qs}&customer_id=${customerId}`)));
        responses.forEach(async res => {
          if (res.error) {
            // TODO: report to sentry
            console.warn("Failed to request sources page", res.meta?.request.url, res.error)
            latestError = res.error;
            return;
          }
          const data = res.data as IPaginationResponse<IGenerationSource>;
          pagination = data.meta.pagination;
          data.data.forEach(source => {
            sourcesById[source.id] = source;
          });
        });

        if (isEmpty(sourcesById) && customerIds.length !== 0 && latestError) {
          return { error: latestError };
        }

        return {data: {data: Object.values(sourcesById), meta: {pagination}}};
      },

      providesTags: (res) => res ? [sourcesPage, ...res.data.map(({id}) => ({type: 'Source' as const, id }))] : [],
    }),

    fetchUtilityInventorySummary: build.query<IPaginationResponse<IAggregatedAssetEvent>, IFetchAggregatedEventsArgs & IFetchUtilityInventoryOptions>({
      async queryFn({customerIds, startDate, endDate, aggregationType, resolution, skipSourcesCount}, _api, _extraOptions, fetchWithBQ) {
        const qs = new URLSearchParams();
        qs.set('start', startDate);
        qs.set('end', endDate);
        qs.set('resolution', resolution);
        qs.set('aggregation_type', aggregationType);

        const sourcesQs = new URLSearchParams();
        sourcesQs.set('page', '1');
        sourcesQs.set('per_page', '1');

        const eventsByStartDate: Record<string, IAggregatedAssetEvent[]> = {};
        const invRes = await Promise.all(customerIds.map(cid => fetchWithBQ(`/v2/aggregated_events/?${qs}&customer_id=${cid}`)));
        let latestError: FetchBaseQueryError | null = null;
        invRes.forEach(res => {
          if (res.error) {
            // TODO: report to sentry
            console.warn("Failed to request sources page", res.meta?.request.url, res.error)
            latestError = res.error;
            return;
          }
          const data = res.data as IListAggregatedAssetEventsResponse;
          data.data.forEach(event => {
            if (event.start_date in eventsByStartDate) {
              eventsByStartDate[event.start_date].push(event);
            } else {
              eventsByStartDate[event.start_date] = [event];
            }
          });
        });
        const aggregatedEventsByStartDate: IAggregatedAssetEvent[] = Object
          .values(eventsByStartDate)
          .map(events => getAggFn(aggregationType)(events))
          .sort((x1, x2) => Date.parse(x1.start_date) - Date.parse(x2.start_date));

        // if requested, fetch the count of generation sources
        const allPaginations: IPagination[] = [];
        if (!skipSourcesCount) {
          const pageRes = await Promise.all(customerIds.map(cid => fetchWithBQ(`/v2/sources/?${sourcesQs}&customer_id=${cid}`)));
          pageRes.forEach(res => {
            if (res.error) {
              // TODO: log error
              return;
            }
            const resData = res.data as IPaginationResponse<IGenerationSource>;
            allPaginations.push(resData.meta.pagination);
          });
        }
        const pagination: IPagination = {
          this: allPaginations[0]?.this || 0,
          next: Math.max(...allPaginations.map(p => p.next || 0)),
          prev: Math.max(...allPaginations.map(p => p.prev || 0)),
          total_items: sum(allPaginations.map(p => p.total_items)),
          first: 1,
          last: Math.max(...allPaginations.map(p => p.last)),
        };

        if (isEmpty(eventsByStartDate) && customerIds.length !== 0 && latestError) {
          return { error: latestError };
        }

        return {data: {data: aggregatedEventsByStartDate, meta: {pagination}}};
      },

      providesTags: res => res ? [{type: 'Source', id: 'OVERVIEW'}] : [],
    }),

    fetchUtilitySourceFacets: build.query<ISourcesFacets, {customerIds: string[]}>({
      async queryFn({customerIds}, _api, _extra, fetchWithBQ) {

        const totalFacets: ISourcesFacets = {
          fuel_categories: {},
          us_states: {},
        };

        const responses = await Promise.all(customerIds.map(cid => fetchWithBQ(`/v2/sources/facets?customer_id=${cid}`)));
        let latestError: FetchBaseQueryError | null = null;

        responses.forEach(res => {
          if (res.error) {
            latestError = res.error;
            return;
          }
          const data = res.data as {data: ISourcesFacets};
          Object.entries(data.data.fuel_categories).forEach(([category, count]) => {
            if (!totalFacets.fuel_categories[category]) {
              totalFacets.fuel_categories[category] = count;
            } else {
              totalFacets.fuel_categories[category] += count;
            }
          });

          Object.entries(data.data.us_states).forEach(([state, count]) => {
            if (!totalFacets.us_states[state]) {
              totalFacets.us_states[state] = count;
            } else {
              totalFacets.us_states[state] += count;
            }
          });
        });

        if (isEmpty(totalFacets.fuel_categories) && isEmpty(totalFacets.us_states) && customerIds.length !== 0 && latestError) {
          return { error: latestError };
        }

        return {data: totalFacets};
      },
      providesTags: res => res ? [{type: 'Source', id: 'FACETS'}] : [],
    }),
  }),
  overrideExisting: false,
});


export const {
  usePrefetch,
  usePaginateSourcesQuery,
  useFetchSourceQuery,
  useFetchUtilitySourcesQuery,
  useFetchUtilityInventorySummaryQuery,
  useFetchUtilitySourceFacetsQuery,
} = sourcesQueries;