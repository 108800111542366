import { Box, Button, Center, Group, Loader, Select, Skeleton, Stack, TextInput, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { AmpLink } from 'amp/components/Link';
import { useAmpNav } from 'amp/hooks';
import { usePatchCustomerMutation } from 'shared/api/customers';
import BasePaper from 'shared/components/Paper/basePaper';
import { useCustomer } from 'shared/store/customers/hooks';
import { CustomerStatus } from 'shared/types/customer';
import { getErrorMessagesFromApiError } from 'shared/utils/data';
import { snakeToTitle } from 'shared/utils/strings';
import { tracker, TrackEventNames } from 'shared/utils/tracker';
import './style.css';


export default function EditCustomerView() {
  const { customerId = '' } = useParams<{ customerId: string }>();
  const navigate = useAmpNav();
  const [edit, editResp] = usePatchCustomerMutation();

  const customerRes = useCustomer(customerId);
  const customer = customerRes.data;
  const [editingName, setEditingName] = useState<string | null>(null);
  const [editingStatus, setEditingStatus] = useState<CustomerStatus | null>(null);
  const [errorsByField, setErrorsByField] = useState<Record<string, string[]>>({});

  useEffect(() => {
    if (customer) {
      setEditingName(customer.name);
      setEditingStatus(customer.status);
    }
  }, [customer])

  const onSubmit = async () => {
    setErrorsByField({});
    try {
      await edit({
        id: customerId,
        body: {
          name: editingName,
          status: editingStatus,
        }
      }).unwrap()
      tracker.track(TrackEventNames.EC, { customerId });
      notifications.show({
        title: 'Success',
        message: 'Successfully updated the customer',
        color: 'teal',
        icon: <IconCheck size={20} />,
      });
      navigate(`/dashboard/customers/${customerId}`)
    } catch (err) {
      const errMsgs = getErrorMessagesFromApiError(err);
      if (errMsgs) {
        setErrorsByField(errMsgs);
      }
      notifications.show({
        title: 'Error',
        message: 'There was an issue updating the customer',
        color: 'red',
        icon: <IconX size={20} />,
      });
    }
  };

  const nameChanged = customer?.name !== editingName;
  const statusChanged = customer?.status !== editingStatus;
  const isSaveEnabled = !customerRes.isLoading && (nameChanged || statusChanged);
  return (
    <div>
      <div className="customer-edit--title-container">
        <Skeleton visible={customerRes.isLoading} width="fit-content">
          <Title size="24px">{customer?.name || 'Unknown Customer'}</Title>
        </Skeleton>
        <Group>
          <AmpLink to={`/dashboard/customers/${customerId}`}>
            <Button className="customer-edit--button">
              Cancel
            </Button>
          </AmpLink>
          <Button
            className={`customer-edit--button ${isSaveEnabled ? '' : 'is-disabled'}`}
            onClick={onSubmit}
            disabled={!isSaveEnabled}
            loading={editResp.isLoading}
          >
            Save Changes
          </Button>
        </Group>
      </div>
      <div className="customer-edit--content-container">
        <BasePaper>
          {customerRes.isLoading && <Box>
            <Center>
              <Loader />
            </Center>
          </Box>}
          {!customerRes.isLoading && <Stack>
            <TextInput
              placeholder="Customer name"
              label="Name"
              error={errorsByField.name && errorsByField.name[0]}
              value={editingName || ''}
              onChange={e => setEditingName(e.target.value)}
            />
            <Select
              placeholder="Customer status"
              label="Status"
              allowDeselect={false}
              error={errorsByField.status && errorsByField.status[0]}
              data={Object.values(CustomerStatus).map(val => ({ label: snakeToTitle(val), value: val }))}
              value={editingStatus}
              onChange={e => setEditingStatus(e as CustomerStatus)}
            />
          </Stack>
          }
        </BasePaper>
      </div>
    </div>
  );
}