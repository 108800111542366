import { useEffect, useMemo } from 'react';

import { IListProgramsArgs, IListSubscriptionsArgs, useFetchProgramQuery, useFetchUtilityProgramsQuery, usePaginateProgramsQuery, usePaginateSubscriptionsQuery } from 'amp/api/programs';
import { getViewingCustomerIds, getViewingOpCoId } from 'amp/store/ui/selectors';
import { Program } from 'shared/types/program';
import { useAppDispatch, useAppSelector } from 'store';
import { getAssignmentsById, getProgramById, getProgramsById, getSubscriptionsById } from './selectors';
import { receiveAssignments, receivePrograms, receiveSubscriptions } from './slice';

export const useProgram = (programId: string) => {
  const oci = useAppSelector(getViewingOpCoId);
  const program = useAppSelector(s => getProgramById(s, programId));
  const res = useFetchProgramQuery({id: programId, customerId: program?.customer_id || oci});
  const dispatch = useAppDispatch();
  const subIds = useMemo(() => res.data?.subscriptions.map(({ id }) => id) || [], [res.data]);
  const asgnIds = useMemo(() => res.data?.assignments.map(({ id }) => id) || [], [res.data]);
  const subscriptions = useAppSelector(s => getSubscriptionsById(s, subIds));
  const assignments = useAppSelector(s => getAssignmentsById(s, asgnIds));

  useEffect(() => {
    if (res.data) {
      const prog = {
        ...res.data.program,
        assignments: res.data.assignments,
        subscriptions: res.data.subscriptions,
      };

      dispatch(receivePrograms([prog]));
      dispatch(receiveSubscriptions(res.data.subscriptions));
      dispatch(receiveAssignments(res.data.assignments));
    }
  }, [res.data, dispatch, programId]);

  return {
    ...res,
    isFirstTimeLoading: res.isLoading,
    isRefreshingData: res.isFetching,
    data: {
      program: program as Program | undefined,
      assignments,
      subscriptions,
    }
  }
};

export const useProgramsPage = ({ page, perPage, customerId, statuses }: IListProgramsArgs) => {
  const oci = useAppSelector(getViewingOpCoId);
  const res = usePaginateProgramsQuery({ page, perPage, statuses, customerId: customerId || oci });
  const dispatch = useAppDispatch();
  const programIds = useMemo(() => res.data?.data.map(({ id }) => id) || [], [res.data]);
  const programs = useAppSelector(s => getProgramsById(s, programIds));

  useEffect(() => {
    if (res.data) {
      dispatch(receivePrograms(res.data.data));
    }
  }, [res.data, dispatch, page, perPage]);

  return {
    ...res,
    isFirstTimeLoading: res.isLoading,
    isRefreshingData: res.isFetching,
    data: programs,
    pagination: res.data?.meta.pagination,
  }
};

export const useSubscriptionsPage = ({ page, perPage, customerId, childCustomerId, programId }: IListSubscriptionsArgs) => {
  const oci = useAppSelector(getViewingOpCoId);
  const res = usePaginateSubscriptionsQuery({ page, perPage, childCustomerId, programId, customerId: customerId || oci});
  const dispatch = useAppDispatch();
  const subscriptionIds = useMemo(() => res.data?.data.map(({ id }) => id) || [], [res.data]);
  const subscriptions = useAppSelector(s => getSubscriptionsById(s, subscriptionIds));

  useEffect(() => {
    if (res.data) {
      dispatch(receiveSubscriptions(res.data.data));
    }
  }, [res.data, dispatch]);

  return {
    ...res,
    isFirstTimeLoading: res.isLoading,
    isRefreshingData: res.isFetching,
    data: subscriptions,
    pagination: res.data?.meta.pagination,
  }
};

export const useUtilityPrograms = ({ page, perPage, statuses }: IListProgramsArgs) => {
  const dispatch = useAppDispatch();
  const customerIds = useAppSelector(getViewingCustomerIds);

  const res = useFetchUtilityProgramsQuery({
    page,
    perPage,
    customerIds,
    statuses,
  });

  if (res.data) {
    dispatch(receivePrograms(res.data.data));
  }

  const data = useAppSelector(s => getProgramsById(s, res.data?.data.map(({id}) => id) || []));

  return {
    ...res,
    data,
    pagination: res.data?.meta.pagination,
  }
}