import { Box } from '@mantine/core';
import { Map, Marker, Popup } from 'mapbox-gl';
import { difference, mean } from 'ramda';
import { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { getAsDOMElement } from 'amp/components/MapIcons/large';
import { useAmpNav } from 'amp/hooks';
import BasePaper from 'shared/components/Paper/basePaper';
import BaseTable, { IBaseTableColumn } from 'shared/components/Table/baseTable';
import { MAPBOXGL_ACCESS_TOKEN } from 'shared/constants/resources';
import { useCustomersPage } from 'shared/store/customers/hooks';
import { CustomerType } from 'shared/types/customer';
import { tracker, TrackEventNames } from 'shared/utils/tracker';
import './style.css';


const accountTableColumns: IBaseTableColumn[] = [
  { key: 'name', displayValue: 'Name' },
  { key: 'status', displayValue: 'Status' },
  { key: 'percentAllocation', displayValue: '% Program Generation' },
];

export default function CustomerAccountsView() {
  const { customerId = '' } = useParams<{ customerId: string }>();
  const [params, setParams] = useSearchParams();
  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const perPage = isNaN(parseInt(params.get('ps') || '10')) ? 10 : parseInt(params.get('ps') || '10');

  useEffect(() => {
    tracker.track(TrackEventNames.VSAL, {customerId, page, perPage});
  }, [customerId, page, perPage]);

  // record of customer_id -> Marker
  const [mapMarkers, setMapMarkers] = useState<Record<string, Marker | null>>({});

  const accountsRes = useCustomersPage({ page, perPage, parentCustomerId: customerId })
  const nav = useAmpNav();
  const map = useRef<Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    const mapVal = map.current;
    const accounts = accountsRes.data;
    const mappedAccountIds = Object.keys(mapMarkers);

    if (!mapLoaded || !accounts || difference(accounts.map(({ id }) => id), mappedAccountIds).length === 0) {
      return
    }

    if (mapVal && accountsRes.data) {
      Object.values(mapMarkers).forEach(marker => {
        marker?.remove();
      });
      const newMapMarkers: Record<string, Marker | null> = {};
      const lngs: number[] = [];
      const lats: number[] = [];
      accountsRes.data.forEach(account => {
        const latLng = (account.attributes.find(({ name }) => name === 'location_lat_lng')?.value) as number[]
        if (latLng) {
          const lng = typeof latLng[1] === 'number' ? latLng[1] : parseFloat(latLng[1]);
          const lat = typeof latLng[0] === 'number' ? latLng[0] : parseFloat(latLng[0]);
          lngs.push(lng);
          lats.push(lat);
          const marker = new Marker({ element: getAsDOMElement(true) }).setLngLat([lng, lat]).addTo(mapVal);
          marker.setPopup(new Popup().setHTML(`<div color="var(--color-blue-3)">${account.name}</div>`))
          newMapMarkers[account.id] = marker;
        } else {
          newMapMarkers[account.id] = null;
        }
      });

      if (lngs.length && lats.length) {
        mapVal.setCenter([mean(lngs), mean(lats)])
      }

      setMapMarkers(newMapMarkers);
    }
  }, [accountsRes.data, mapLoaded, mapMarkers]);


  useEffect(() => {
    if (!map.current) {
      map.current = new Map({
        accessToken: MAPBOXGL_ACCESS_TOKEN,
        container: 'customer-accounts-page--map-container',
        attributionControl: false,
        interactive: true,
        center: [-83, 33], // [lng, lat] is expected
        zoom: 5,
        style: 'mapbox://styles/mapbox/light-v10',
        renderWorldCopies: false,
      });
      setMapLoaded(true);
    }
  }, [setMapLoaded]);

  const onParamsChange = (params: { name: string, value: string }[]) => {
    setParams(newParams => {
      params.forEach(p => {
        newParams.set(p.name, p.value);
      });
      return newParams;
    });
  };

  const onPageChange = (newPage: number) => {
    onParamsChange([{ name: 'p', value: newPage.toString() }]);
  };

  const onRowClicked = (accountId: string) => {
    const accounts = accountsRes.data || [];
    const customer = accounts.find(a => a.id === accountId);
    const customerTypeProp = customer?.customer_type;
    if (customerTypeProp && customerTypeProp === CustomerType.UTILITY_CUSTOMER) {
      nav(`/dashboard/customers/${accountId}`)
    } else {
      nav(`/dashboard/customers/${customerId}/accounts/${accountId}`)
    }
  }

  const rows = accountsRes.data?.map((row) => {
    const pctAllocationTenThousandths = (row.attributes.find(attr => attr.name === 'allocation_percent_ten_thousandths')?.value as number) || 0;
    return {
      name: row.name,
      id: row.id,
      status: row.status.toString(),
      percentAllocation: `${pctAllocationTenThousandths / 10_000}%`,
    };
  }) || [];

  return (
    <div className="customer-accounts-page--container">
      <BasePaper titleContent={<div>Sub-accounts</div>}>
        <Box w="100%" h="220px" m="0 0 24px">
          <div id="customer-accounts-page--map-container" />
        </Box>
        <BaseTable
          columnNames={accountTableColumns}
          rows={rows}
          totalPages={accountsRes.pagination?.last || 0}
          totalItems={accountsRes.pagination?.total_items || 0}
          currentPage={page}
          onPageChange={onPageChange}
          onTableRowClicked={onRowClicked}
        />
      </BasePaper>
    </div>
  );
}
