import { queries } from "shared/api/queries";

export interface IFetchForecastForCustomerArgs {
  forecastCustomerId: string
  customerId?: string | null
  year: number
}

export interface IForecastData {
  start_date: string
  y_axis_value_wh: number
}

export interface IForecast {
  id: string
  asset_id: string
  customer_id: string
  forecasted_at: string
  results_location: string
}

export interface IForecastWithData extends IForecast {
  data: {
    results: IForecastData[]
  } | null
}


const forecastQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    fetchForecastForCustomer: build.query<{ event_forecast_batch: IForecastWithData }, IFetchForecastForCustomerArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        if (body.customerId) {
          qs.set('customer_id', body.customerId);
        }
        qs.set('forecast_customer_id', body.forecastCustomerId);
        qs.set('year', body.year.toString());

        return {
          url: `/v2/assets/forecast-for-customer/by-customer-id?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [{type: 'Customer Forecast', id: res.event_forecast_batch.id}] : [],
    }),

    fetchForecastForAsset: build.query<{ event_forecast_batch: IForecast}, {year: number, asset_id: string, customerId?: string | null}>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('year', body.year.toString());
        if (body.customerId) {
          qs.set('customer_id', body.customerId);
        }
        return {
          url: `/v2/assets/${body.asset_id}/forecast?${qs}`
        }
      },

      providesTags: (res) => res ? [{type: 'Asset Forecast', id: res.event_forecast_batch.id}] : [],
    }),

    createForecastForAsset: build.mutation<{ event_forecast_batch: IForecast}, {year: number, asset_id: string, customerId?: string | null}>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('year', body.year.toString());
        if (body.customerId) {
          qs.set('customer_id', body.customerId);
        }
        return {
          url: `/v2/assets/${body.asset_id}/forecast?${qs}`,
          method: 'POST',
        }
      },
    }),

  }),
  overrideExisting: false,
});


export const {
  usePrefetch,
  useFetchForecastForCustomerQuery,
  useFetchForecastForAssetQuery,
  useCreateForecastForAssetMutation,
} = forecastQueries;