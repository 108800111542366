import { Box, Group, Table, Text } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { MouseEvent, ReactNode, useState } from 'react';

import { AmpLink } from 'amp/components/Link';
import { useAmpNav } from 'amp/hooks';
import { useProgram } from 'amp/store/programs/hooks';
import BasePaper from 'shared/components/Paper/basePaper';
import { useCustomer } from 'shared/store/customers/hooks';
import { ICustomer } from 'shared/types/customer';
import { IProgram, Program } from 'shared/types/program';
import { ISubscription, Subscription } from 'shared/types/subscription';
import { timestampToNumericDate } from 'shared/utils/dates';
import { numberToSiFormat } from 'shared/utils/strings';
import DeleteSubscriptionModal from './deleteSubscriptionModal';
import './style.css';


const ProgramTableCell = ({ program }: { program: IProgram | undefined }) => {
  if (!program) {
    return <em>Unknown customer</em>;
  }

  return (
    <AmpLink to={`/dashboard/programs/${program.id}`} onClick={(e) => e.stopPropagation()}>
      <Group>
        <Text fz={12} c="var(--color-black-2)" truncate="end" style={{ textOverflow: 'ellipsis' }}>
          {program.name}
        </Text>
      </Group>
    </AmpLink>
  );
}


const CustomerTableCell = ({ customer, subscription }: { customer: ICustomer | undefined, subscription: ISubscription }) => {
  if (!customer) {
    return <Text fz={10} fw={400}>Unknown customer</Text>;
  }

  return (
    <AmpLink to={`/dashboard/programs/${subscription.retail_program_id}/${subscription.id}`} onClick={(e) => e.stopPropagation()}>
      <Group>
        <Box>
          <Text fz={10} fw={400} c="var(--color-black-2)" truncate="end" style={{ textOverflow: 'ellipsis' }} title={customer?.name || 'unknown customer'}>
            {customer?.name}
          </Text>
        </Box>
      </Group>
    </AmpLink>
  );
}

const SubscriptionTableRow = ({ subscription, titleRow }: { subscription: Subscription, titleRow: 'customer' | 'program' }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { data: customer } = useCustomer(subscription.customer_id);
  const { data: program } = useProgram(subscription.retail_program_id);
  const subscriptionStart = subscription.data.configuration.subscription_start;
  const subscriptionEnd = subscription.data.configuration.subscription_end;
  const nav = useAmpNav();
  // this number is less than 1
  const formattedCommittedGeneration = numberToSiFormat(subscription.committedGenerationWh)
  const isFixedCommitmentProgram = program.program?.isFixedCommitment || false;
  const titleCell = titleRow === 'customer' ? <CustomerTableCell customer={customer} subscription={subscription} /> : <ProgramTableCell program={program.program} />
  const rowLink = `/dashboard/programs/${subscription.retail_program_id}/${subscription.id}`;
  const countSubAccountsSubscribed = subscription.subAccountAllocationPercentTenThousandths.length

  if (program.program?.status !== 'active') {
    return null;
  }

  const onDeleteSubscription = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteModalOpen(true);
  }

  return (
    <>
      <Table.Tr className="subscription-table--row" onClick={() => nav(rowLink)}>
        <Table.Td p="16px 8px">
          {titleCell}
        </Table.Td>
        <Table.Td>
          <Text fz={10} c="var(--color-blue-3)" ta="center">{`${subscriptionStart ? timestampToNumericDate(subscriptionStart) : 'not specified'} - ${subscriptionEnd ? timestampToNumericDate(subscriptionEnd) : 'not specified'}`}</Text>
        </Table.Td>
        <Table.Td>
          <Text fz={10} c="var(--color-blue-3)" ta="center">{ isFixedCommitmentProgram ? `${formattedCommittedGeneration.value} ${formattedCommittedGeneration.unitPrefix}Wh` : `${subscription.percentGenerationDedicatedTenThousandths / 10_000}%`}</Text>
        </Table.Td>
        <Table.Td>
          <Text fz={10} c="var(--color-blue-3)" ta="center">{ countSubAccountsSubscribed }</Text>
        </Table.Td>
        <Table.Td onClick={onDeleteSubscription} className="subscription-table--delete-icon">
          <IconTrash size="12px" />
        </Table.Td>
      </Table.Tr>
      <DeleteSubscriptionModal
        onClose={() => setDeleteModalOpen(false)}
        isOpen={deleteModalOpen}
        subscriptionId={subscription.id}
        programId={subscription.retail_program_id}
        customerId={subscription.parent_customer_id}
      />
    </>
  );
}

export default function CustomerSubscriptions({
  subscriptions,
  title = 'Program Subscriptions',
  titleRow = 'customer'
}: { program?: Program, subscriptions: Subscription[], title?: ReactNode, titleRow: 'customer' | 'program' }) {

  return (
    <BasePaper titleContent={title}>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th className="subscription-table--header-container">
              <Text size="10px" c="var(--color-blue-2)" fw="600">Customer</Text>
            </Table.Th>
            <Table.Th className="subscription-table--header-container">
              <Text ta="center" size="10px" c="var(--color-blue-2)" fw="600">Duration of contract</Text>
            </Table.Th>
            <Table.Th className="subscription-table--header-container">
              <Text ta="center" size="10px" c="var(--color-blue-2)" fw="600">Commitment</Text>
            </Table.Th>
            <Table.Th className="subscription-table--header-container">
              <Text ta="center" size="10px" c="var(--color-blue-2)" fw="600">Number of Subscribed Sub-accounts</Text>
            </Table.Th>
            <Table.Th />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {subscriptions.map(sub => <SubscriptionTableRow key={sub.id} subscription={sub} titleRow={titleRow} />)}
        </Table.Tbody>
      </Table>
    </BasePaper>
  );
}