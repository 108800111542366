import { Box, Button, Group, LoadingOverlay, Skeleton, Stack, Text, Title } from '@mantine/core';
import { IconBolt, IconPlus } from '@tabler/icons-react';
import { sum } from 'ramda';

import { AmpLink } from 'amp/components/Link';
import OpcoBadge from 'amp/components/OpcoBadge';
import { useProgram, useUtilityPrograms } from 'amp/store/programs/hooks';
import { getViewingCustomerIds, getViewingOpCoId } from 'amp/store/ui/selectors';
import { useEffect } from 'react';
import BasePaper from 'shared/components/Paper/basePaper';
import RoleRequired from 'shared/components/RoleRequired/roleRequired';
import { filterActiveAssignments, filterActiveSubscriptions } from 'shared/types/program';
import { UserRole } from 'shared/types/user';
import { numberToSiFormat, snakeToTitle } from 'shared/utils/strings';
import { tracker, TrackEventNames } from 'shared/utils/tracker';
import { useAppSelector } from 'store';
import './style.css';


const actPeriodText = (actPeriod: string | undefined) => {
  if (!actPeriod) {
    return '';
  }

  return actPeriod === 'annual' ? 'Annual Program' : '24/7 Program';
}

const ProgramCard = ({ programId }: { programId: string }) => {
  const programRes = useProgram(programId);
  const { assignments, subscriptions, program } = programRes.data;

  const activeAssignments = filterActiveAssignments(assignments);
  const activeSubscriptions = filterActiveSubscriptions(subscriptions);
  const isFixedCommitmentProgram = program?.isFixedCommitment || false;

  const currentCommitmentPercentTenThousandths = sum(activeSubscriptions.map(s => {
    return (s.percentGenerationDedicatedTenThousandths / 10_000)
  }));
  const formattedCurrentCommitment = numberToSiFormat(sum(activeSubscriptions.map(s => (s.committedGenerationWh))));

  if (!program) {
    return (
      <BasePaper>
        <div style={{ height: '80px' }} />
      </BasePaper>
    );
  }

  return (
    <BasePaper>
      <div className="programs-page--card-container">
        <Skeleton visible={programRes.isLoading} maw={260}>
          <div className="programs-page--card-info-container">
            <Group>
              <div className="programs-page--program-status-container">
                <Text
                  className="programs-page--program-status"
                  p="4px 6px"
                  display="inline"
                  bg={program.status === 'active' ? '#ecf8f5' : '#f8f3ec'}
                  c={program.status === 'active' ? 'var(--color-blue-1)' : 'var(--color-blue-3)'}
                >
                  {snakeToTitle(program.status)}
                </Text>
              </div>
              {program.is_hypothetical === true &&
                <Text
                  className="programs-page--program-status"
                  p="4px 6px"
                  display="inline"
                  bg="#f8f3ec"
                  c="var(--color-blue-3)"
                >
                  Hypothetical
                </Text>
              }
            </Group>
            <div className="programs-page--program-card-name">{program.name}</div>
            <Text bg="f8f9fa" c="#4d7396">
              {actPeriodText(program.data.program_config?.accounting_period)}
            </Text>
            <OpcoBadge customerId={program.customer_id} />
          </div>
        </Skeleton>

        <div className="programs-page--card-stats-container">
          <Skeleton visible={programRes.isLoading}>
            <Stack gap="4px" align="center">
              <div className="programs-page--card-topline-number">{activeSubscriptions?.length || 0}</div>
              <div className="programs-page--stat-subtext">Active Subscriptions</div>
            </Stack>
          </Skeleton>
          <Skeleton visible={programRes.isLoading}>
            <Stack gap="4px" align="center">
              <Group gap="6px">
                <IconBolt color="#245d5b" />
                <div className="programs-page--card-topline-number">{activeAssignments?.length || 0}</div>
              </Group>
              <div className="programs-page--stat-subtext">Generators Assigned</div>
            </Stack>
          </Skeleton>
          <Skeleton visible={programRes.isLoading}>
            <Stack gap="4px" align="center">
              <div className="programs-page--card-topline-number">{isFixedCommitmentProgram ? `${formattedCurrentCommitment.value} ${formattedCurrentCommitment.unitPrefix}Wh` : `${currentCommitmentPercentTenThousandths.toFixed(1)}%`}</div>
              <div className="programs-page--stat-subtext">Current Commitment</div>
            </Stack>
          </Skeleton>
        </div>
      </div>
    </BasePaper>
  );
};

const ProgramsView = () => {
  const oci = useAppSelector(getViewingOpCoId);
  const customerIds = useAppSelector(getViewingCustomerIds);

  // This should cover the scale of the pilot
  const res = useUtilityPrograms({ page: 1, perPage: 50, statuses: ['active', 'inactive'] });
  const programs = res.data;

  useEffect(() => {
    tracker.track(TrackEventNames.VPL, {customerIds});
  });

  return (
    <div className="programs-page--container">
      <div className="programs-page--title-container">
        <Title size="24px">
          Programs
        </Title>
        <RoleRequired role={UserRole.ADMIN}>
          <AmpLink to="/dashboard/programs/create">
            <Button fw={400} size="sm" className="programs-page--create-button" leftSection={<IconPlus size={20} />}>
              Add Program
            </Button>
          </AmpLink>
        </RoleRequired>
      </div>

      <div className="programs-page--scroll-area">
        {res.isLoading && <Box pos="relative" w="100%" h="100%">
          <LoadingOverlay visible={true} />
        </Box>}
        <Stack gap="lg">
          {(programs && programs.length === 0) && <BasePaper>
            No programs found
          </BasePaper>}
          {programs && programs.map(program => (
            <AmpLink to={`/dashboard/programs/${program.id}?oci=${oci}`} key={program.id}>
              <ProgramCard programId={program.id} />
            </AmpLink>
          ))}
        </Stack>
      </div>
    </div>
  );
};

export default ProgramsView;