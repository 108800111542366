import { Group, Menu, Select } from '@mantine/core';
import { IconChevronDown, IconDots, IconDownload } from '@tabler/icons-react';
import { indexBy, prop, sum } from 'ramda';
import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useFetchReportCustomerResultsQuery, useFetchReportStdDeliveryResultsQuery } from 'amp/api/allocationRuns';
import { useFetchCustomerReportQuery } from 'amp/api/reports';
import { useAllocationRun, useAllocationRunCustomerOutputData, useAllocationRunStdDeliveryOutputData } from 'amp/store/allocationRuns/hooks';
import { getAllocationRunStartAndEnd } from 'amp/store/allocationRuns/selectors';
import { getViewingOpCoId, getViewingTimeZone } from 'amp/store/ui/selectors';
import BaseChart from 'shared/components/Chart/baseChart';
import UtcDatePicker from 'shared/components/DatePicker/utcDatePicker';
import BasePaper from 'shared/components/Paper/basePaper';
import { getCurrentUser, getMeasurementPreference } from 'shared/store/user/selectors';
import { AssetEventResolution } from 'shared/types/assetEvents';
import { isCfeFuel } from 'shared/types/generator';
import { CustomerReport } from 'shared/types/report';
import { MeasurementDisplayPreference } from 'shared/types/user';
import { groupData } from 'shared/utils/chart';
import { getLastYearEnd, getLastYearStart } from 'shared/utils/dates';
import { tracker, TrackEventNames } from 'shared/utils/tracker';
import { useAppSelector } from 'store';


const ONE_MONTH_MILLIS = 1000 * 60 * 60 * 24 * 31;

const ConsumptionMatchingTimeSeries = ({ isReport = false }: { isReport: boolean }) => {
  const { runId = '' } = useParams<{ runId: string }>();
  const [params, setParams] = useSearchParams();
  const tzName = useAppSelector(getViewingTimeZone);
  const oci = useAppSelector(getViewingOpCoId);
  const customerId = params.get('c');

  useAllocationRun(runId, oci);
  const reportRes = useFetchCustomerReportQuery({ id: runId, customerId: '' }, { skip: !isReport });
  const report = reportRes.data?.customer_report;

  const allocationStartAndEnd = useAppSelector(s => getAllocationRunStartAndEnd(s, runId));
  const repStartAndEnd = {
    start: report?.config?.report_start_date ? new Date(report.config.report_start_date) : null,
    end: report?.config?.report_end_date ? new Date(report.config.report_end_date) : null,
  };
  const startAndEnd = isReport ? repStartAndEnd : allocationStartAndEnd;

  const defaultStart = getLastYearStart(tzName);
  const defaultEnd = getLastYearEnd(tzName);
  const startDateStr = params.get('s') || (startAndEnd?.start || defaultStart).toISOString();
  const endDateStr = params.get('e') || (startAndEnd?.end || defaultEnd).toISOString();
  const startDate = useMemo(() => new Date(startDateStr), [startDateStr]);
  const endDate = useMemo(() => new Date(endDateStr), [endDateStr]);

  const chartBarType = params.get('bt') || 'cfe';
  let resolution = params.get('r') || '1d';
  const isHourlyDisabled = Math.abs(startDate.valueOf() - endDate.valueOf()) > ONE_MONTH_MILLIS;
  const resolutionOptions = [
    { label: `Hourly${isHourlyDisabled ? ' (1 month max)' : ''}`, value: AssetEventResolution.HOUR, disabled: isHourlyDisabled },
    { label: 'Daily', value: AssetEventResolution.DAY },
    // { label: 'Monthly', value: AssetEventResolution.MONTH },
  ];
  if (isHourlyDisabled && resolution === '1h') {
    resolution = '1d';
  }

  const measurement = useAppSelector(getMeasurementPreference);
  const isMetric = measurement === MeasurementDisplayPreference.METRIC;
  const lbsConversion = isMetric ? 2.20462 : 1;

  const currentCustomerId = useAppSelector(getCurrentUser)?.customer_id;
  const customerRes = useAllocationRunCustomerOutputData(runId, oci || currentCustomerId, customerId);
  const stdDeliveryRes = useAllocationRunStdDeliveryOutputData(runId, oci || currentCustomerId);

  // TODO: this conditional logic is hard to follow, clean it up
  const locations = report ? new CustomerReport(report).getReportOutputsLocations({ programId: '', customerId: customerId || undefined }) : null;
  const repCustomerResultsResp = useFetchReportCustomerResultsQuery(
    { customerResultsLocation: locations?.customer || '' },
    { skip: !isReport || !locations }
  );
  const repCustomerResults = repCustomerResultsResp.data;
  const repStdDeliveryResultsResp = useFetchReportStdDeliveryResultsQuery(
    { stdDeliveryResultsLocation: locations?.standardDelivery || '' },
    { skip: !isReport || !locations }
  );
  const repStdDeliveryResults = repStdDeliveryResultsResp.data;

  const customerResult = isReport ? repCustomerResults : customerRes.data;
  const hourlyResults = useMemo(() => customerResult?.hourly_results || [], [customerResult]);
  const hourlyResultsByHour = indexBy(prop('datetime'), hourlyResults);

  const stdDeliveryResult = isReport ? repStdDeliveryResults : stdDeliveryRes.data;
  const stdDelivery = stdDeliveryResult?.hourly_results;


  // calculate the consumption series which is always displayed.
  const consumptionSeriesMwh = useMemo(() => {
    const consumptionMwh: number[][] = [];
    if (!hourlyResults.length) {
      return null;
    }

    stdDelivery?.forEach(stdHour => {
      const currentHour = new Date(stdHour.hour);
      const xValue = new Date(stdHour.hour).valueOf();

      if (currentHour <= endDate && currentHour >= startDate) {
        const hourlyConsumptionWh = hourlyResultsByHour[stdHour.hour]?.consumption_wh || 0;
        consumptionMwh.push([xValue, hourlyConsumptionWh / 1_000_000]);
      }
    });

    const grouping = resolution === AssetEventResolution.DAY ? 'day' : 'hour';
    const groupedConsumption = groupData(consumptionMwh.map(d => ({ epoch: d[0], value: d[1] })), grouping);
    return groupedConsumption;
  }, [hourlyResults, stdDelivery, hourlyResultsByHour, resolution, startDate, endDate]);

  // calculate the various series for the CFE bar type
  const [programGenSeriesMwh, excessGenSeriesMwh, gridCfeSeriesMWh, gridNonCfeSeriesMWh] = useMemo(() => {
    const programsGenerationMwh: number[][] = [];
    const excessProgramsGeneration: number[][] = [];
    const gridCfeSeries: number[][] = [];
    const gridNonCfeSeries: number[][] = [];
    if (!stdDelivery || !hourlyResults.length) {
      return [null, null, null, null, null];
    }

    stdDelivery?.forEach(stdHour => {
      const currentHour = new Date(stdHour.hour);
      const xValue = new Date(stdHour.hour).valueOf();
      if (currentHour <= endDate && currentHour >= startDate) {
        const hourlyConsumptionWh = hourlyResultsByHour[stdHour.hour]?.consumption_wh || 0;

        const subscriptionResults = hourlyResultsByHour[stdHour.hour]?.subscription_program_generation || [];
        const allocatedWh = sum(subscriptionResults.map(s => s.allocated_wh));
        const excessWh = sum(subscriptionResults.map(s => s.excess_gen_wh));
        programsGenerationMwh.push([xValue, allocatedWh / 1_000_000]);
        excessWh && excessProgramsGeneration.push([xValue, excessWh / 1_000_000]);

        if (allocatedWh < hourlyConsumptionWh) {
          const whMissing = hourlyConsumptionWh - allocatedWh;
          const totalStdMWh = sum(Object.values(stdHour.mix).map(d => d.mwh));
          if (!totalStdMWh) { // prevent divide by 0
            return;
          }

          let totalGridCfeMWh = 0;
          let totalGridNonCfeMWh = 0;
          Object.entries(stdHour.mix).forEach(([fuel, { mwh }]) => {
            const percentOfMix = mwh / totalStdMWh;
            if (isCfeFuel(fuel)) {
              totalGridCfeMWh += (whMissing * percentOfMix) / 1_000_000;
            } else {
              totalGridNonCfeMWh += (whMissing * percentOfMix) / 1_000_000;
            }
          });
          totalGridCfeMWh && gridCfeSeries.push([xValue, totalGridCfeMWh]);
          totalGridNonCfeMWh && gridNonCfeSeries.push([xValue, totalGridNonCfeMWh]);
        }
      }
    });

    const grouping = resolution === AssetEventResolution.DAY ? 'day' : 'hour';
    const groupedProgramGen = groupData(programsGenerationMwh.map(d => ({ epoch: d[0], value: d[1] })), grouping);
    const groupedProgramExcess = groupData(excessProgramsGeneration.map(d => ({ epoch: d[0], value: d[1] })), grouping);
    const groupedGridCfe = groupData(gridCfeSeries.map(d => ({ epoch: d[0], value: d[1] })), grouping);
    const groupedGridNonCfe = groupData(gridNonCfeSeries.map(d => ({ epoch: d[0], value: d[1] })), grouping);


    return [groupedProgramGen, groupedProgramExcess, groupedGridCfe, groupedGridNonCfe];
  }, [stdDelivery, hourlyResultsByHour, hourlyResults, startDate, endDate, resolution]);

  // calculate the series for the CI bar type and for data export
  const [ciTimeSeries, ciResultsByHour] = useMemo(() => {
    const carbonIntensityTimeSeries: number[][] = [];
    const carbonIntensityHourlyResults: Record<string, number> = {};
    if (!stdDelivery || !hourlyResults.length) {
      return [null, null];
    }

    stdDelivery?.forEach(stdHour => {
      const currentHour = new Date(stdHour.hour);
      const xValue = new Date(stdHour.hour).valueOf();
      const hourlyConsumptionWh = hourlyResultsByHour[stdHour.hour]?.consumption_wh || 0;

      const subscriptionResults = hourlyResultsByHour[stdHour.hour]?.subscription_program_generation || [];
      const totalProgramsWh = sum(subscriptionResults.map(s => s.allocated_wh));

      if (totalProgramsWh < hourlyConsumptionWh) {
        const percentMissing = (hourlyConsumptionWh - totalProgramsWh) / hourlyConsumptionWh;
        const totalStdMWh = sum(Object.values(stdHour.mix).map(d => d.mwh));
        if (!totalStdMWh) { // prevent divide by 0
          return;
        }

        let totalGridCiLbsPerMWh = 0;
        Object.entries(stdHour.mix).forEach(([fuel, { mwh, sum_co2e_lbs }]) => {
          const percentOfMix = mwh / totalStdMWh;
          const fuelCiForHour = sum_co2e_lbs / (mwh || 1); // if mwh is 0 so is sum_co2e_lbs
          totalGridCiLbsPerMWh += fuelCiForHour * percentOfMix;
        });

        const emissionsLbs = totalGridCiLbsPerMWh * percentMissing * hourlyConsumptionWh / 1_000_000;
        const programCi = (emissionsLbs / (hourlyConsumptionWh / 1_000_000)) / lbsConversion;
        carbonIntensityHourlyResults[stdHour.hour] = programCi;
        if (currentHour <= endDate && currentHour >= startDate) {
          carbonIntensityTimeSeries.push([xValue, programCi]);
        }
      } else {
        carbonIntensityHourlyResults[stdHour.hour] = 0;
        if (currentHour <= endDate && currentHour >= startDate) {
          carbonIntensityTimeSeries.push([xValue, 0]);
        }
      }
    });

    const grouping = resolution === AssetEventResolution.DAY ? 'day' : 'hour';
    const groupedCarbonIntensity = groupData(carbonIntensityTimeSeries.map(d => ({ epoch: d[0], value: d[1] })), grouping, 'mean');
    return [groupedCarbonIntensity, carbonIntensityHourlyResults];
  }, [stdDelivery, hourlyResultsByHour, hourlyResults.length, lbsConversion, resolution, startDate, endDate]);

  const onParamsChange = (params: { name: string, value: string }[]) => {
    setParams(newParams => {
      params.forEach(p => {
        newParams.set(p.name, p.value);
      });
      return newParams;
    });
  };

  const onResolutionChange = (newResolution: string | null) => {
    if (!newResolution) {
      return;
    }
    onParamsChange([{ name: 'r', value: newResolution }]);
  };

  const onBarTypeChange = (newType: string | null) => {
    if (!newType) {
      return;
    }
    onParamsChange([{ name: 'bt', value: newType }]);
  }

  const onExportToCsv = () => {
    const trackingKey = isReport ? 'reportId' : 'runId'
    tracker.track(TrackEventNames.DCRC, {[trackingKey]: runId});
    const allFuels: Set<string> = new Set();
    stdDelivery?.forEach(stdHour => {
      Object.keys(stdHour.mix).forEach(key => allFuels.add(key));
    });

    // always loop over the allFuels set when adding to a row so fuels always get the same column
    const headerRow = ['Timestamp', 'Consumption Wh', 'Program Dedicated Gen Wh', 'Program Allocated Gen Wh', `Grid Carbon Intensity ${isMetric ? 'kgs' : 'lbs'} CO2e/MWh`];
    allFuels.forEach(fuel => headerRow.push(`Grid Mix ${fuel} MWh`));
    const rows: (string | number)[][] = [headerRow];

    stdDelivery?.forEach(stdHour => {
      const subscriptionResults = hourlyResultsByHour[stdHour.hour]?.subscription_program_generation || [];
      const dedicatedWh = sum(subscriptionResults.map(s => s.dedicated_wh));
      const allocatedWh = sum(subscriptionResults.map(s => s.allocated_wh));

      const row = [
        stdHour.hour,
        hourlyResultsByHour?.[stdHour.hour]?.consumption_wh,
        dedicatedWh,
        allocatedWh,
        ciResultsByHour?.[stdHour.hour] || 'unknown',
      ];

      allFuels.forEach(fuel => row.push(stdHour.mix[fuel]?.mwh || 0));
      rows.push(row);
    });

    let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  const customerHasSubscription = hourlyResults.filter(hr => Object.keys(hr.subscription_program_generation).length > 0).length > 0;
  const programSeries: Highcharts.SeriesColumnOptions[] = [{
    type: 'column',
    name: 'Excess Programs Certificates',
    color: '#A9DACE',
    data: excessGenSeriesMwh || [],
    visible: customerHasSubscription && chartBarType === 'cfe',
    showInLegend: customerHasSubscription && chartBarType === 'cfe',
  }, {
    type: 'column',
    name: 'Programs Generation',
    color: '#62C1AB',
    data: programGenSeriesMwh || [],
    visible: customerHasSubscription && chartBarType === 'cfe',
    showInLegend: customerHasSubscription && chartBarType === 'cfe',
  }];
  const nonProgramSeries: (Highcharts.SeriesColumnOptions | Highcharts.SeriesLineOptions)[] = [{
    type: 'column',
    name: 'Grid CFE',
    color: '#3F9493',
    data: gridCfeSeriesMWh || [],
    visible: chartBarType === 'cfe',
    showInLegend: chartBarType === 'cfe',
  }, {
    type: 'column',
    name: 'Grid Non-CFE',
    color: '#B6BEC5',
    data: gridNonCfeSeriesMWh || [],
    visible: chartBarType === 'cfe',
    showInLegend: chartBarType === 'cfe',
  }];
  const consumptionSeries: (Highcharts.SeriesLineOptions)[] = [{
    type: 'line',
    name: 'Consumption',
    color: '#72B3FF',
    data: consumptionSeriesMwh || [],
  }];
  const ciSeries: (Highcharts.SeriesColumnOptions)[] = [{
    type: 'column',
    name: 'Carbon Intensity',
    color: '#B6BEC5',
    yAxis: 1,
    data: ciTimeSeries || [],
    visible: chartBarType === 'ci',
    showInLegend: chartBarType === 'ci',
  }];

  const consumptionMatchingChartOptions: Highcharts.Options = {
    chart: {
      height: '300px',
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      valueDecimals: 1,
      pointFormatter: function () {
        const formattedNum = this.y?.toLocaleString() || '';
        return `<span style="color:${this.color}">●</span> <b>${formattedNum}</b> ${this.series.name === 'Carbon Intensity' ? `${isMetric ? 'kgs' : 'lbs'} CO<sub>2</sub>e/MWh` : 'MWh'}`;
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      }
    },
    yAxis: [{
      title: {
        text: `<span style="color: #72B3FF">\u25CF</span> MWh consumed`,
        useHTML: true,
      },
      min: 0,
    }, {
      opposite: true,
      visible: chartBarType === 'ci',
      title: {
        text: `<span style="color: #B6BEC5">\u25CF</span>${isMetric ? 'kgs' : 'lbs'} CO<sub>2</sub>e/MWh`,
        useHTML: true,
      },
      min: 0,
    }],
    series: [...programSeries, ...nonProgramSeries, ...ciSeries, ...consumptionSeries],
  };

  return (
    <BasePaper
      titleContent={<Group justify="space-between" w="100%">
        <Select
          className="allocation-results-customer--bar-type-selector"
          w="250px"
          rightSection={<IconChevronDown color="var(--color-blue-2)" size={16} />}
          value={chartBarType}
          onChange={onBarTypeChange}
          allowDeselect={false}
          data={[
            { label: 'Consumption CFE Matching', value: 'cfe' },
            { label: 'Carbon Intensity Matching', value: 'ci' }
          ]}
        />
        <Group fw={400}>
          <Select
            value={resolution}
            data={resolutionOptions}
            onChange={onResolutionChange}
            rightSection={<IconChevronDown size={20} />}
            allowDeselect={false}
            w="180px"
          />
          <UtcDatePicker
            value={startDate}
            isStartDate={true}
            maxDate={endDate}
          />
          -
          <UtcDatePicker
            value={endDate}
            minDate={startDate}
          />
          <Menu position='bottom-end'>
            <Menu.Target>
              <IconDots className="program-outlet--context-menu" size={16} />
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item p={0} onClick={onExportToCsv}>
                <Group p={8}>
                  <IconDownload size={12} />
                  Download as CSV
                </Group>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>}>
      <BaseChart overrideOptions={consumptionMatchingChartOptions} />
    </BasePaper>
  );
};

export default ConsumptionMatchingTimeSeries;