import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGeneratorAssignment } from "shared/types/assignment";
import { IGenerator } from "shared/types/generator";
import { IGenerationSource, isGenerator } from "shared/types/source";

interface IGeneratorSlice {
  byId: Record<string, IGenerator>
  sourceById: Record<string, IGenerationSource>
  assignmentsById: Record<string, IGeneratorAssignment>
  assignmentIdsByGenId: Record<string, string[]>
}

const initialState: IGeneratorSlice = {
  byId: {},
  sourceById: {},
  assignmentsById: {},
  assignmentIdsByGenId: {},
}


const generatorsSlice = createSlice({
  name: 'amp__generators',
  initialState,
  reducers: {
    receiveGenerators: (state, action: PayloadAction<IGenerator[]>) => {
      action.payload.forEach(gen => {
        state.byId[gen.id] = gen;
      })
    },

    receiveSources: (state, action: PayloadAction<IGenerationSource[]>) => {
      action.payload.forEach(source => {
        state.sourceById[source.id] = source;
        if (isGenerator(source)) {
          state.byId[source.id] = source;
        }
      });
    },

    receiveGeneratorAssignments: (state, action: PayloadAction<{assignments: IGeneratorAssignment[], generatorId: string}>) => {
      action.payload.assignments.forEach(assignment => {
        state.assignmentsById[assignment.id] = assignment
      });

      state.assignmentIdsByGenId[action.payload.generatorId] = action.payload.assignments.map(d => d.id);
    },

  },
});


export const { receiveGenerators, receiveGeneratorAssignments, receiveSources } = generatorsSlice.actions;
export default generatorsSlice.reducer;