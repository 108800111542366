import { useMemo } from "react";
import { NavigateOptions, To, useNavigate, useSearchParams } from "react-router-dom";
import { getLastYearEnd, getLastYearStart, getThisYearEnd, getThisYearStart } from "shared/utils/dates";
import { useAppSelector } from "store";
import { getLatestLoadAndGenDataDates, getViewingOpCoId, getViewingTimeZone } from "./store/ui/selectors";


// When navigating between links in amp we want to preserve the oci query param
// while removing teh rest of the query params, this does that logic for us.
export const useAmpNav = () => {
  const nav = useNavigate();
  const oci = useAppSelector(getViewingOpCoId);

  return (to: To, options?: NavigateOptions | undefined) => {
    if (typeof to === 'string') {
      const path = to.split('?')[0] || '';
      const qs = to.split('?')[1] || '';
      const query = new URLSearchParams(qs);
      if (!query.has('oci') && oci) {
        query.set('oci', oci);
      }
      const newTo = `${path}?${query}`;
      nav(newTo, options);
    } else {
      const query = new URLSearchParams(to.search || '');
      if (!query.has('oci') && oci) {
        query.set('oci', oci);
      }
      const newTo = {
        ...to,
        search: `${query}`,
      };
      nav(newTo, options);
    }
  }
}

export const usePageParams = ({defaultPage=1, defaultPerPage=10}: {defaultPage?: number, defaultPerPage?: number}) => {
  const [params] = useSearchParams();

  const page = isNaN(parseInt(params.get('p') || defaultPage?.toString())) ? defaultPage : parseInt(params.get('p') || defaultPage.toString());
  const perPage = isNaN(parseInt(params.get('ps') || defaultPerPage.toString())) ? defaultPerPage : parseInt(params.get('ps') || defaultPerPage.toString());

  return {
    page,
    perPage,
  }
}

export const useStartAndEndParams = ({startParam='s', endParam='e', genOrLoad}: {startParam?: string, endParam?: string, genOrLoad: 'generation' | 'load'}) => {
  const [params] = useSearchParams();
  const tzName = useAppSelector(getViewingTimeZone);
  const { latestGen, latestLoad } = useAppSelector(getLatestLoadAndGenDataDates);
  const startDefaultFn = useMemo(() => genOrLoad === 'generation' ? getLastYearStart : getThisYearStart, [genOrLoad]);
  const endDefaultFn = useMemo(() => genOrLoad === 'generation' ? getLastYearEnd : getThisYearEnd, [genOrLoad]);
  const defaultEnd = genOrLoad === 'generation' ? latestGen : latestLoad;

  const [start, end] = useMemo(() => {
    let startDateStr = params.get(startParam) || startDefaultFn(tzName).toISOString();
    let endDateStr = params.get(endParam) || endDefaultFn(tzName).toISOString();
    if (defaultEnd && !params.get(endParam)) {
      endDateStr = defaultEnd;
      const endDate = new Date(defaultEnd);
      const startDate = new Date(defaultEnd);
      if (!isNaN(endDate.valueOf())) {
        startDate.setUTCMonth(0, 1);
        startDate.setUTCHours(0, 0, 0, 0);
        if (!params.get(startParam)) {
          // TODO: should we convert to tzName here?
          startDateStr = startDate.toISOString();
        }
      }
    }
    return [new Date(startDateStr), new Date(endDateStr)];
  }, [params, tzName, startParam, endParam, startDefaultFn, endDefaultFn, defaultEnd]);

  return {
    start,
    end,
  }
}