import { ComboboxItem, Group, Select, Skeleton, Text, TextInput } from '@mantine/core';
import { IconArrowsExchange, IconBolt, IconCheck, IconSearch } from '@tabler/icons-react';
import { isEmpty, sum } from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useFetchGenerationQuery } from 'amp/api/generators';
import { usePrefetch } from 'amp/api/sources';
import BasePaper from 'shared/components/Paper/basePaper';
import BaseTable, { IBaseTableColumn } from 'shared/components/Table/baseTable';
import { IUtilityGenerationData } from 'shared/types/assetEvents';
import { GeneratorFuelCategories, IGenerator, stateValueToName, usStates } from 'shared/types/generator';
import { numberToString, snakeToTitle } from 'shared/utils/strings';

import { useAmpNav, useStartAndEndParams } from 'amp/hooks';
import { useUtilitySourceFacets, useUtilitySources } from 'amp/store/generators/hooks';
import { getGeneratorById } from 'amp/store/generators/selectors';
import { isGenerator } from 'shared/types/source';
import { tracker, TrackEventNames } from 'shared/utils/tracker';
import { useAppSelector } from 'store';
import './style.css';


const LastYearGenerationCell = ({ generatorId } : { generatorId: string }) => {
  const generator = useAppSelector(s => getGeneratorById(s, generatorId));

  const {
    start,
    end
  } = useStartAndEndParams({ genOrLoad: 'generation' });

  const res = useFetchGenerationQuery({ startDate: start.toISOString(), endDate: end.toISOString(), resolution: '1d', generatorIds: [generatorId], customerIds: [generator.customer_id] }, {skip: !generator});

  const totalGeneration = sum(res.data?.data.map(ae => (ae.data as IUtilityGenerationData).sum_generated_wh) || []);
  const totalGenerationMWh = totalGeneration / 1_000_000;
  return (
    <Skeleton visible={res.isLoading}>
      <Text fz={10} fw={700}>{numberToString(Math.round(totalGenerationMWh))} MWh</Text>
    </Skeleton>
  );
}

const generatorTableColumns: IBaseTableColumn[] = [
  { key: 'name', displayValue: 'Name' },
  { key: 'description', displayValue: 'Description' },
  { key: 'location', displayValue: 'Location' },
  { key: 'fuelCategory', displayValue: 'Fuel Category' },
  { key: 'lastYearGenerationMWh', displayValue: 'Most Recent Year Generation' },
];

const renderSelectOption = ({ option, checked }: {checked?: boolean, option: ComboboxItem & {count?: number}}) => (
  <Group flex="1" gap="xs">
    {checked &&
      <IconCheck
        stroke={1.5}
        color='currentColor'
        opacity={0.6}
        size={18}
      />
    }
    {option.label}
    {Number.isFinite(option.count) && <span className="sources-list-facet-item--count">({option.count})</span>}
  </Group>
);

const SourcesListView = () => {
  const [params, setParams] = useSearchParams();
  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const perPage = isNaN(parseInt(params.get('ps') || '10')) ? 10 : parseInt(params.get('ps') || '10');
  const fuelCategory = params.get('fc');
  const usState = params.get('us');
  const nameSearch = params.get('ns') || '';
  useEffect(() => {
    tracker.track(TrackEventNames.VISP, {page, perPage, fuelCategory, usState, nameSearch});
  }, [page, perPage, fuelCategory, usState, nameSearch]);
  const [nameSearchStr, setNameSearchStr] = useState<string>(nameSearch || '');
  const [typingTimer, setTypingTimer] = useState<ReturnType<typeof setTimeout> | undefined>();
  const nav = useAmpNav();
  const fetchSourcePrefetch = usePrefetch('fetchSource');
  const res = useUtilitySources({ page, perPage, fuelCategory, usState, nameSearch });
  const facetsRes = useUtilitySourceFacets();

  const onPageChange = (newPage: number) => {
    setParams((params) => {
      params.set('p', newPage.toString());
      return params;
    });
  };

  const onTableRowClick = (sourceId: string) => {
    const clickedSource = res.data?.find(s => s.id === sourceId);
    if (isGenerator(clickedSource)) {
      nav(`/dashboard/inventory/sources/${sourceId}?oci=${clickedSource.customer_id}`);
    }
  };

  const onSearchChange = (value: string) => {
    setNameSearchStr(value);

    if (!value) {
      clearTimeout(typingTimer);
      setParams(newParams => {
        newParams.delete('ns');
        return newParams;
      });
    } else {
      clearTimeout(typingTimer);
      setTypingTimer(setTimeout(() => {
        setParams(newParams => {
          newParams.set('ns', value);
          newParams.set('p', '1');
          return newParams;
        });
      }, 400));
    }
  };

  const onFuelCategoryChange = (newCategory: string | null) => {
    if (!newCategory) {
      setParams(newParams => {
        newParams.delete('fc');
        return newParams;
      });
    } else {
      setParams(newParams => {
        newParams.set('p', '1');
        newParams.set('fc', newCategory);
        return newParams;
      });
    }
  };

  const onUsStateChange = (newState: string | null) => {
    if (!newState) {
      setParams(newParams => {
        newParams.delete('us');
        return newParams;
      });
    } else {
      setParams(newParams => {
        newParams.set('us', newState);
        newParams.set('p', '1');
        return newParams;
      });
    }
  };

  const rows = res.data?.map((row) => {
    const builtRow = {
      id: row.id,
      name: <></>,
      description: row.description || '',
      location: stateValueToName[(row.location.us_state) || ''] || 'Unknown',
      fuelCategory: '',
      lastYearGenerationMWh: <></>,
      clickable: true,
    }

    let nameIcon = <IconBolt size={12} color="var(--color-blue-1)" />;
    if (isGenerator(row)) {
      builtRow.lastYearGenerationMWh = <LastYearGenerationCell generatorId={row.id} />;
      const asGenerator = row as IGenerator;
      builtRow.fuelCategory = snakeToTitle(asGenerator.meta.fuel_category || 'Unspecified');
    } else {
      builtRow.fuelCategory = 'N/A';
      builtRow.description = 'Market Counterparty';
      builtRow.clickable = false;
      nameIcon = <IconArrowsExchange size={12} color="var(--color-blue-1)" />;
    }

    builtRow.name = <Group gap={8} miw={250}>{nameIcon}{row.name}</Group>;
    return builtRow;
  }) || [];

  const onRowMouseover = (sourceId: string) => {
    const source = res.data.find(d => d.id === sourceId);
    if (source) {
      fetchSourcePrefetch({id: source.id, customerId: source.customer_id});
    }
  }

  const searchIcon = <IconSearch style={{ width: 16, height: 16 }} />;
  const fuelOptions = useMemo(() => {
    if (facetsRes.data && !isEmpty(facetsRes.data.fuel_categories)) {
      return Object.entries(facetsRes.data.fuel_categories).map(([fuelCategory, count]) => ({
        label: snakeToTitle(fuelCategory),
        value: fuelCategory,
        count,
      }));
    }
    return Object.values(GeneratorFuelCategories).sort().map(category => ({ label: snakeToTitle(category), value: category }))
  }, [facetsRes]);

  const stateOptions = useMemo(() => {
    if (facetsRes.data && !isEmpty(facetsRes.data.us_states)) {
      return Object.entries(facetsRes.data.us_states).map(([state, count]) => ({
        label: stateValueToName[state] || state,
        value: state,
        count,
      }));
    }

    return Object.values(usStates).map(state => ({ label: state.name, value: state.value }));
  }, [facetsRes]);
  return (
    <div>
      <div className="generators-page--filters-container">
        <TextInput
          placeholder="Search..."
          value={nameSearchStr}
          onChange={e => onSearchChange(e.target.value)}
          rightSection={searchIcon}
          aria-label="Generator search input"
        />
        {/* We should allow deselect for these drop downs as a way of clearing filters */}
        <Select
          placeholder="Filter by fuel..."
          value={fuelCategory}
          onChange={onFuelCategoryChange}
          data={fuelOptions}
          renderOption={renderSelectOption}
          aria-label="Generator fuel category selection"
          clearable
        />
        <Select
          placeholder="Filter by location..."
          value={usState}
          onChange={onUsStateChange}
          data={stateOptions}
          renderOption={renderSelectOption}
          aria-label="Generator US State selection"
          clearable
        />
      </div>
      <div className="generators-page--scroll-container">
        <BasePaper titleContent="Sources">
          <BaseTable
            rows={rows}
            columnNames={generatorTableColumns}
            totalPages={res.pagination?.last || 0}
            currentPage={page}
            onPageChange={onPageChange}
            isLoading={res.isLoading}
            onTableRowClicked={onTableRowClick}
            onRowMouseover={onRowMouseover}
          />
        </BasePaper>
      </div>
    </div>
  );
};

export default SourcesListView;