import { isDefined } from 'shared/utils/data';
import { isDateWithinDateRange } from 'shared/utils/dates';
import { IGeneratorAssignment } from './assignment';
import { ISubscription, Subscription } from './subscription';


interface IProgramConfig {
  accounting_period: 'hourly' | 'annual'
  // all generation is "pooled" for all subscriptions, or
  // generation from a single generator is "dedicated" to a single subscription
  generation_assignment: 'pooled' | 'dedicated'
  commitment_type?: ProgramCommitmentType
}

interface IProgramData {
  program_config?: IProgramConfig
}

export enum ProgramCommitmentType {
  PERCENT_GENERATION = 'percent_generation',
  PERCENT_CONSUMPTION = 'percent_consumption',
  FIXED_COMMITMENT = 'fixed_commitment',
}

export enum ProgramStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
}

// TODO: make a class that implements dataToDisplayValues?
export interface IProgram {
  id: string
  customer_id: string
  priority: number
  name: string
  status: ProgramStatus
  description?: string | null
  is_hypothetical: boolean | null
  data: IProgramData
  assignments?: IGeneratorAssignment[] | null,
  subscriptions?: ISubscription[] | null
  created_at: string
  updated_at?: string | null
  created_by: string
  updated_by?: string | null
}

export interface Program extends IProgram { }

export class Program {
  constructor(data: IProgram){
    Object.assign(this, data);
  }

  get commitmentType(): ProgramCommitmentType {
    return this.data?.program_config?.commitment_type || ProgramCommitmentType.PERCENT_GENERATION
  }

  get isFixedCommitment(): boolean {
    return this.commitmentType === ProgramCommitmentType.FIXED_COMMITMENT;
  }

  get isPercentGeneration(): boolean {
    return this.commitmentType === ProgramCommitmentType.PERCENT_GENERATION;
  }
}

export const dataToDisplayValues = (program?: IProgram) => {

  return {
    name: program?.name || '',
    description: program?.description || '',
    priority: program?.priority || 1.0,
    status: program?.status || ProgramStatus.ACTIVE,
    accountingPeriod: program?.data.program_config?.accounting_period || 'annual',
    generationAssignment: program?.data.program_config?.generation_assignment || 'dedicated',
  };
};

export const displayValuesToRequestData = (values: ReturnType<typeof dataToDisplayValues>) => {
  const data: Partial<IProgram> = {
    data: {program_config: {accounting_period: 'annual', generation_assignment: 'dedicated'}}
  };

  if (isDefined(values.name)) {
    data.name = values.name;
  }

  if (isDefined(values.description)) {
    data.description = values.description;
  }

  if (isDefined(values.priority)) {
    data.priority = values.priority;
  }

  if (isDefined(values.status)) {
    data.status = values.status;
  }

  if (isDefined(values.accountingPeriod) && !!data.data?.program_config) {
    data.data.program_config.accounting_period = values.accountingPeriod;
  }

  if (isDefined(values.generationAssignment) && !!data.data?.program_config) {
    data.data.program_config.generation_assignment = values.generationAssignment;
  }

  return data;
};

type ApiErrorType<T> = Record<string, string[] | T>;
interface IApiError extends ApiErrorType<IApiError> {}

// TODO: move this nested logic to the other "errorResponseToDisplayErrors"
// OR make this function a utility
export const errorResponseToDisplayErrors = (errs: IApiError) => {
  const displayErrors: Partial<ReturnType<typeof dataToDisplayValues>> = {};

  const fieldMap: Record<string, keyof ReturnType<typeof dataToDisplayValues>> = {
    name: 'name',
    description: 'description',
    priority: 'priority',
    status: 'status',
    generation_assignment: 'generationAssignment',
    accounting_period: 'accountingPeriod',
  };

  const assignErrorToDisplay = (field: string, errors: IApiError | string[]) => {
    if (Array.isArray(errors)) {
      if (errors.length !== 0) {
        const displayField = fieldMap[field];
        (displayErrors[displayField] as unknown) = errors[0];
      }
    } else {
      Object.entries(errors).forEach(([nextField, nextErrs]) => assignErrorToDisplay(nextField, nextErrs));
    }
  };

  Object.entries(errs).forEach(([field, errors]) => assignErrorToDisplay(field, errors));

  return displayErrors;
};


export const filterActiveSubscriptions = (subscriptions: Subscription[]) => {
  const now = new Date();
  return subscriptions?.filter(s => {
    if (!s.data?.configuration?.subscription_start && !s.data?.configuration?.subscription_end) {
      return true;
    } else if (s.data.configuration.subscription_start) {
      // start exists but end doesn't - always active after start
      const start = new Date(s.data.configuration.subscription_start);
      return isDateWithinDateRange(now, start, new Date('2099-01-01T00:00:00Z'));
    } else if (s.data.configuration.subscription_end) {
      // end exists but start doesn't - always active before end
      const end = new Date(s.data.configuration.subscription_end);
      return isDateWithinDateRange(now, new Date('1990-01-01T00:00:00Z'), end);
    } else if (s.data.configuration.subscription_start && s.data.configuration.subscription_end) {
      // both are present
      const start = new Date(s.data.configuration.subscription_start);
      const end = new Date(s.data.configuration.subscription_end);
      return isDateWithinDateRange(now, start, end);
    } else {
      return false
    }
  });
}

export const filterActiveAssignments = (assignments: IGeneratorAssignment[]) => {
  const now = new Date();
  return assignments?.filter(a => {
    if (!a.data.configuration.assignment_start && !a.data.configuration.assignment_end) {
      // assignments without date ranges set are considered "always active"
      return true;
    } else if (!a.data.configuration.assignment_end && a.data.configuration.assignment_start) {
      // start exists but end doesn't - always active after start
      const start = new Date(a.data.configuration.assignment_start);
      return isDateWithinDateRange(now, start, new Date('2099-01-01T00:00:00Z'));
    } else if (!a.data.configuration.assignment_start && a.data.configuration.assignment_end) {
      // end exists but start doesn't - always active before end
      const end = new Date(a.data.configuration.assignment_end);
      return isDateWithinDateRange(now, new Date('1990-01-01T00:00:00Z'), end);
    } else if (a.data.configuration.assignment_start && a.data.configuration.assignment_end) {
      // both are present
      const start = new Date(a.data.configuration.assignment_start);
      const end = new Date(a.data.configuration.assignment_end);
      return isDateWithinDateRange(now, start, end);
    } else {
      return false
    }
  });
}