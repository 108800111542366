import { createSelector } from 'reselect';
import { Program } from 'shared/types/program';
import { Subscription } from 'shared/types/subscription';

import { RootState } from 'store';

const root = (state: RootState) => state.amp__program;

const byId = createSelector(
  root,
  (state) => state.byId,
);

export const getProgramById = createSelector(
  byId,
  (_: RootState, programId: string) => programId,
  (progsById, programId) => {
    if (progsById[programId]) {
      return new Program(progsById[programId])
    }
    return undefined
  }
);

export const getProgramsById = createSelector(
  byId,
  (_: RootState, programIds: string[]) => programIds,
  (progsById, programIds) => programIds.map(programId => progsById[programId]).filter(x => !!x).map(program => new Program(program))
);


const subscriptionsById = createSelector(
  root,
  (state) => state.subscriptionsById,
);

export const getSubscriptionsById = createSelector(
  subscriptionsById,
  (_: RootState, subscriptionIds: string[]) => subscriptionIds,
  (subsById, subIds) => subIds.map(subId => subsById[subId]).filter(x => !!x).map(sub => new Subscription(sub))
)

export const getSubscriptionById = createSelector(
  subscriptionsById,
  (_: RootState, subscriptionId: string) => subscriptionId,
  (subsById, subId) => {
    if (subsById[subId]) {
      return new Subscription(subsById[subId])
    }
    return undefined
  }
)


const assignmentsById = createSelector(
  root,
  (state) => state.assignmentsById,
);

export const getAssignmentsById = createSelector(
  assignmentsById,
  (_: RootState, assignmentIds: string[]) => assignmentIds,
  (assignmentsById, assignmentIds) => assignmentIds.map(assignmentId => assignmentsById[assignmentId]).filter(x => !!x)
);
