import * as amplitude from '@amplitude/analytics-browser';
import { IUser } from 'shared/types/user';
import { AMPLITUDE_API_KEY, DEBUG } from '../constants/resources';


// Central place to show all events that we track
export enum TrackEventNames {
  VIO = 'View inventory overview',
  VISP = 'View inventory sources page',
  VG = 'View generator',

  VCL = 'View customers list',
  VC = 'View customer page',
  EC = 'Edit customer',
  VSAL = 'View sub-accounts list',
  VSA = 'View sub-account page',

  VP = 'View projections page',

  VPL = 'View programs list',
  CP = 'Create program',
  VPP = 'View program page',
  EP = 'Edit program',
  VPH = 'View program history',

  CS = 'Create subscription',
  VS = 'View subscription',
  DS = 'Delete subscription',
  ES = 'Edit subscription',

  CA = 'Create assignment',
  DA = 'Delete assignment',

  VAL = 'View allocations list',
  VARS = 'View allocation review summary',
  DAR = 'Delete allocation run',
  RAR = 'Release allocation run report',
  EARN = 'Edit allocation run note',
  CAR = 'Create allocation run',
  VAOP = 'View allocation overview page',
  VAOPD = 'View allocation overview program details',
  VACP = 'View allocation customer page',
  VADP = 'View allocation diagnostic page',

  VCRL = 'View customer reports list',
  ECRN = 'Edit customer report name',
  ECRD = 'Edit customer report description',
  VCR = 'View customer report',
  DCRP = 'Download customer report PDF',
  DCRC = 'Download customer report CSV',
  DCR = 'Delete customer report',

  VER = 'View emissions report page',

  VUP = 'View user preferences',
  CMDP = 'Change measurement display preference',
  VALL = 'View audit logs list',
  VCPDL = 'View certificate property definitions list',
  VCPD = 'View certificate property definition',
  ECPD = 'Edit certificate property definition',
  CCPD = 'Create certificate property definition',

  CARR = 'Create allocation run reviews',
  AARR = 'Approve allocation run review',
  DARR = 'Deny allocation run review',
  RARR = 'Rescind allocation run review',
  DCARR = 'Decline allocation run review',
  SAR = 'Settle allocation run',
}

class Tracker {

  constructor() {
    if (DEBUG) return;
    try {
      amplitude.init(AMPLITUDE_API_KEY, {autocapture: false});
    } catch (err) {
      console.warn('[Singularity.AMP] failed to initialize amplitude', err);
    }
  }

  identify(user: IUser) {
    if (DEBUG) return;
    try {
      amplitude.setUserId(user.email);
      const evt = new amplitude.Identify();
      evt.set('name', user.name);
      evt.set('id', user.id);
      evt.set('customer_id', user.customer_id);
      evt.set('role', user.role);
      evt.set('status', user.status);
      amplitude.identify(evt);
    } catch (err) {
      console.warn('[Singularity.AMP] failed to identify user with tracker', err);
    }
  }

  track(event: TrackEventNames, properties: Record<string, unknown> = {}) {
    if (DEBUG) return;
    try {
      amplitude.track(event, properties);
    } catch (err) {
      console.warn('[Singularity.AMP] failed to track user action', err);
    }
  }
}

export const tracker = new Tracker();