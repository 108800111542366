import { Alert, Button } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useCreateForecastForAssetMutation } from "amp/api/assetForecasts";
import { useBulkFetchGeneratorsQuery } from "amp/api/generators";
import { getViewingOpCoId } from "amp/store/ui/selectors";
import { useEffect, useState } from "react";
import { useAppSelector } from "store";


const MissingForecasts = ({missing}: {missing: {generatorId: string, customerId: string, year: number}[]}) => {
  const oci = useAppSelector(getViewingOpCoId);
  const [createForecast, createRes] = useCreateForecastForAssetMutation();
  const [isDismissed, setIsDismissed] = useState(false);

  useEffect(() => {
    setIsDismissed(false);
  }, [missing]);

  const generatorsRes = useBulkFetchGeneratorsQuery({ids: missing.map((({generatorId}) => generatorId)), customerId: oci})

  const onClickCreate = () => {
    missing.forEach(({generatorId, year, customerId}) => {
      createForecast({
        year, asset_id: generatorId, customerId
      });
    });
  };

  if (missing.length === 0 || isDismissed) return null;

  return <Alert variant="light" color="orange" title="Missing projections" icon={<IconInfoCircle />} withCloseButton onClose={() => setIsDismissed(true)}>
    <div>Missing projections for generators: {generatorsRes.data?.data.map((gen) => `${gen.name} (${missing.filter(d => d.generatorId === gen.id).map(d => d.year).join(', ')})`).join('; ')}</div>
    <Button mt={8} variant="light" size="xs" disabled={!createRes.isUninitialized} loading={createRes.isLoading} onClick={onClickCreate}>Create projections</Button>
    {!createRes.isUninitialized && <div className="projections-missing-forecast-create--help">Creating projections. This may take a few minutes, try refreshing the page periodically.</div>}
  </Alert>
};

export default MissingForecasts;