import { Box, Button, createTheme, Group, LoadingOverlay, MantineProvider, Select, Skeleton, Stack, Switch, TextInput, Title } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useReplaceProgramMutation } from 'amp/api/programs';
import { AmpLink } from 'amp/components/Link';
import { useAmpNav } from 'amp/hooks';
import { useProgram } from 'amp/store/programs/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { usePaginateTeammatesQuery } from 'shared/api/users';
import FeatureGate from 'shared/components/FeatureGate/featureGate';
import BasePaper from 'shared/components/Paper/basePaper';
import { ProgramCommitmentType, ProgramStatus } from 'shared/types/program';
import { getErrorMessagesFromApiError } from 'shared/utils/data';
import { tracker, TrackEventNames } from 'shared/utils/tracker';
import { useAppSelector } from 'store';
import './style.css';


const statusOptions = [
  { label: 'Active', value: ProgramStatus.ACTIVE },
  { label: 'Inactive', value: ProgramStatus.INACTIVE },
  { label: 'Archived', value: ProgramStatus.ARCHIVED },
  { label: 'Deleted', value: ProgramStatus.DELETED },
];

// This is the only way mantine recommends to do this
const cursorPointerTheme = createTheme({ cursorType: 'pointer' });

export default function EditProgramView() {
  const oci = useAppSelector(getViewingOpCoId);
  const { programId = '' } = useParams<{ programId: string }>();
  const navigate = useAmpNav();
  const [edit, editResp] = useReplaceProgramMutation();
  const [editedName, setEditedName] = useState('');
  const [editedStatus, setEditedStatus] = useState<null | ProgramStatus>(null);
  const [editedCommitType, setEditedCommitType] = useState<ProgramCommitmentType>(ProgramCommitmentType.PERCENT_GENERATION);
  const [editedPeriod, setEditedPeriod] = useState<null | 'annual' | 'hourly'>();
  const [editedIsHypothetical, setEditedIsHypothetical] = useState<null | boolean>(null);
  const [errorsByField, setErrorsByField] = useState<Record<string, string[]>>({});

  const programRes = useProgram(programId);
  const program = programRes.data.program;

  const teammatesRes = usePaginateTeammatesQuery({ page: 1, perPage: 50 });
  const teammates = teammatesRes.data?.data;

  useEffect(() => {
    if (program) {
      setEditedName(program.name);
      setEditedStatus(program.status);
      setEditedPeriod(program.data.program_config?.accounting_period);
      setEditedCommitType(program.data.program_config?.commitment_type || ProgramCommitmentType.PERCENT_GENERATION);
      // if a program has null is_hypothetical, editing it will set it to true.
      setEditedIsHypothetical(program.is_hypothetical === null ? true : program.is_hypothetical);
    }

  }, [program]);

  const onSubmit = async () => {
    setErrorsByField({});
    try {
      await edit({
        id: programId,
        customerId: oci,
        body: {
          name: editedName,
          status: editedStatus || undefined,
          is_hypothetical: editedIsHypothetical,
          priority: 1, // TODO: this field isn't used but is required by the backend
          data: {
            program_config: {
              generation_assignment: 'pooled', // TODO: this field isn't used but is required by the backend
              accounting_period: editedPeriod || program?.data.program_config?.accounting_period || 'hourly',
              commitment_type: editedCommitType,
            }
          }
        },
      }).unwrap();
      tracker.track(TrackEventNames.EP, { programId, customerId: oci, programName: editedName });
      notifications.show({
        title: 'Success',
        message: 'Successfully updated the program',
        color: 'teal',
        icon: <IconCheck size={20} />,
      });
      navigate(`/dashboard/programs/${programId}`);
    } catch (err) {
      const errMsgs = getErrorMessagesFromApiError(err);
      if (errMsgs) {
        setErrorsByField(errMsgs);
      }
      notifications.show({
        title: 'Error',
        message: 'There was an issue updating the program',
        color: 'red',
        icon: <IconX size={20} />,
      });
    }
  };

  if (!program) {
    return <Box pos="relative" w="100%" h={600}>
      <LoadingOverlay visible={true} />
    </Box>
  }

  const creator = teammates?.find(p => p.id === program?.created_by);
  const actPeriodChanged = program.data.program_config?.accounting_period !== editedPeriod;
  const commitTypeChanged = program.data.program_config?.commitment_type !== editedCommitType;
  const hasChange = (program.name !== editedName) || (program.status !== editedStatus) || (program.is_hypothetical !== editedIsHypothetical) || actPeriodChanged || commitTypeChanged;
  const isSaveEnabled = editedName && hasChange;
  const dataErrors = errorsByField.data as unknown as Record<string, Record<string, string[]>>;
  const configErrors = dataErrors?.program_config;
  const countProgramSubscriptions = programRes.data.subscriptions.filter(({retail_program_id}) => program.id === retail_program_id).length;
  return (
    <>
      <div className="program-edit--title-container">
        <Skeleton visible={programRes.isLoading} width="fit-content">
          <Title size="24px">{programRes.data?.program?.name || 'Unknown Program'}</Title>
        </Skeleton>
        <Group>
          <AmpLink to={`/dashboard/programs/${programId}`}>
            <Button className="program-edit--button">
              Cancel
            </Button>
          </AmpLink>
          <Button
            className={`program-edit--button ${isSaveEnabled ? '' : 'is-disabled'}`}
            onClick={onSubmit}
            disabled={!isSaveEnabled}
            loading={editResp.isLoading}
          >
            Save Changes
          </Button>
        </Group>
      </div>
      <div className="program-edit--fields-container">
        <BasePaper>
          <Group gap={60} align="flex-start">
            <Stack w="calc(50% - 30px)">
              <TextInput
                error={errorsByField.name && errorsByField.name[0]}
                placeholder="Program name"
                label="Name"
                value={editedName}
                onChange={e => setEditedName(e.target.value)}
              />
              <Select
                error={errorsByField.status && errorsByField.status[0]}
                placeholder="Program Status"
                label="Status"
                data={statusOptions}
                clearable={false}
                allowDeselect={false}
                value={editedStatus}
                onChange={e => setEditedStatus(e as ProgramStatus)}
              />
              <FeatureGate propertyName="amp_program_configuration_demo_access">
                {/* this property defaults to PERCENT_GENERATION if user doesn't have feature gate */}
                <Select
                  label="Commitment Type"
                  error={configErrors?.commitment_type}
                  data={[
                    { label: 'Percentage of Generation', value: ProgramCommitmentType.PERCENT_GENERATION },
                    { label: 'Percentage of Consumption', value: ProgramCommitmentType.PERCENT_CONSUMPTION, disabled: true },
                    // TODO: add UI tooltip or flag explaining why hourly fixed commitments are disabled
                    { label: 'Fixed MWh', value: ProgramCommitmentType.FIXED_COMMITMENT, disabled: editedPeriod === 'hourly' },
                  ]}
                  disabled={countProgramSubscriptions > 0}
                  clearable={false}
                  allowDeselect={false}
                  value={editedCommitType}
                  onChange={e => {
                    setEditedCommitType(e as ProgramCommitmentType)
                    setErrorsByField({})
                    if (e === ProgramCommitmentType.FIXED_COMMITMENT) {
                      setEditedPeriod('annual')
                    }
                  }}
                />
              </FeatureGate>
              <Select
                error={configErrors?.accounting_period}
                label="Accounting period"
                data={[
                  { label: 'Annual', value: 'annual' },
                  // TODO: add UI tooltip or flag explaining why hourly fixed commitments are disabled
                  { label: 'Hourly', value: 'hourly', disabled: editedCommitType === ProgramCommitmentType.FIXED_COMMITMENT }
                ]}
                clearable={false}
                allowDeselect={false}
                value={editedPeriod}
                onChange={e => {
                  setEditedPeriod(e as ('annual' | 'hourly'))
                  setErrorsByField({})
                  if (e === 'hourly') {
                    setEditedCommitType(ProgramCommitmentType.PERCENT_GENERATION)
                  }
                }}
              />
              {editedIsHypothetical !== null && <MantineProvider theme={cursorPointerTheme}>
                <Switch
                  error={errorsByField.is_hypothetical && errorsByField?.is_hypothetical[0]}
                  label="Hypothetical Program"
                  description="Hypothetical programs cannot be included in settleable allocation runs. Programs not given a status of Hypothetical must be included for an allocation to be settleable."
                  checked={editedIsHypothetical === null ? true : editedIsHypothetical}
                  onChange={e => setEditedIsHypothetical(e.target.checked)}
                  color="var(--color-blue-2)"
                />
              </MantineProvider>}
            </Stack>
            <Stack w="calc(50% - 30px)">
              <TextInput
                disabled
                label="Program ID"
                value={program.id || 'unknown'}
              />
              <Group>
                <TextInput
                  disabled
                  label="Created by"
                  value={creator?.name || program.created_by}
                />
                <DateInput
                  disabled
                  label="Created at"
                  value={new Date(program.created_at)}
                />
              </Group>
            </Stack>
          </Group>
        </BasePaper>
      </div>
    </>
  );
}