import { Group, Title } from '@mantine/core';
import { Outlet } from 'react-router-dom';

import Tabs from 'amp/components/Tabs';
import './style.css';


export default function SettingsOutlet() {
  return (
    <div>
      <div className="user-settings-outlet--container">
        <Group justify="space-between">
          <Title fz={24} fw={700}>Account</Title>
        </Group>
        <Tabs tabs={[
          { title: 'Details', path: '/dashboard/settings/preferences' },
          { title: 'Users', path: '/dashboard/settings/users', disabled: true }, // TODO: users page needs to be implemented

          // TODO: these both work currently but we've decided to hide them from users until we're sure where they belong
          // {title: 'Audit Logs', path: '/dashboard/settings/audit-logs'},
          // {title: 'Certificate Management', path: '/dashboard/settings/certificate-props'},
        ]} />
      </div>
      <Outlet />
    </div>
  );
}