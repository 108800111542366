import { Group, Loader, Pagination, PaginationControl, Progress, Table, Text, Title } from '@mantine/core';
import React, { ReactNode, useEffect, useState } from 'react';

import { usePagination } from '@mantine/hooks';
import { IconDatabaseX } from '@tabler/icons-react';
import { normalize } from 'shared/utils/math';
import './baseTable.css';

export interface IBaseTableColumn {
  displayValue: ReactNode
  key: string
  color?: string
  width?: string // the width of the column, percentages are best
}

interface ITableRow {
  id: string
  clickable?: boolean
  [key: string]: ReactNode
}

export interface IBaseTableProps {
  title?: ReactNode,
  columnNames: IBaseTableColumn[]
  rows: ITableRow[] | ReactNode[]
  noDataMessage?: string
  onTableRowClicked?: (rowId: string) => void
  onRowMouseover?: (rowId: string) => void
  onTableCellClicked?: (row: ITableRow, key: string) => void
  isLoading?: boolean
  loadingMessage?: ReactNode

  // Pagination props
  totalPages: number
  totalItems?: number
  currentPage: number
  onPageChange: (newPageNum: number) => void
  onPageMouseover?: (pageNum: number) => void
}

function PaginationDots() {
  return (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: 'calc(var(--pagination-control-size) / 1.8)',
        height: 'calc(var(--pagination-control-size) / 1.8)',
      }}
    >
      <path d="M2 8c0-.733.6-1.333 1.333-1.333.734 0 1.334.6 1.334 1.333s-.6 1.333-1.334 1.333C2.6 9.333 2 8.733 2 8zm9.333 0c0-.733.6-1.333 1.334-1.333C13.4 6.667 14 7.267 14 8s-.6 1.333-1.333 1.333c-.734 0-1.334-.6-1.334-1.333zM6.667 8c0-.733.6-1.333 1.333-1.333s1.333.6 1.333 1.333S8.733 9.333 8 9.333 6.667 8.733 6.667 8z" fill="currentColor" />
    </svg>
  );
}

export const CustomPaginationItem = ({ page, index, onMouseEnter, active, onClick }: { page: number | 'dots', index: number, onMouseEnter: () => void, active: boolean, onClick: (page: number) => void }) => {

  if (page === 'dots') {
    return <PaginationDots />;
  }

  return (
    <PaginationControl
      key={index}
      onMouseEnter={onMouseEnter}
      active={active}
      aria-current={active ? 'page' : undefined}
      onClick={() => onClick(page)}
    >
      {page}
    </PaginationControl>
  );
};

const NoDataMessage = ({ noDataMessage }: { noDataMessage: string | undefined }) => {
  return (
    <div className="base-table--no-data-message">
      <IconDatabaseX />
      {noDataMessage || 'No Data to Display...'}
    </div>
  );
};

const LoadingDataMessage = ({ loadingMessage }: { loadingMessage?: ReactNode }) => {
  return (
    <div className="base-table--no-data-message">
      <Loader />
      {loadingMessage || 'Loading Data...'}
    </div>
  );
};


const getTextAlignFromCellIdx = (idx: number, arrLength: number) => {
  switch(idx) {
    case 0:
      return "left";
    case arrLength - 1:
      return "right";
    default:
      return "center";
  }
}

const BaseTable = (props: IBaseTableProps) => {
  const onTableRowClicked = props.onTableRowClicked || (() => { });
  const onTableCellClicked = props.onTableCellClicked || (() => { });
  const onPageMouseover = props.onPageMouseover || (() => { });
  const onRowMouseover = props.onRowMouseover || (() => { });
  const pagination = usePagination({ total: props.totalPages, page: props.currentPage })
  const [loadingProgress, setLoadingProgress] = useState(0);

  // TODO: when done loading, transition to 100% complete and fade to opacity: 0
  useEffect(() => {
    let loadingTimeoutId: null | ReturnType<typeof setTimeout>= null;

    if (props.isLoading) {
      // random milliseconds between 120 and 350
      const timeoutMs = normalize(Math.random(), 1, 0, 400, 180);
      // random progress addition between 5 and 15 percent
      const addToProgress = normalize(Math.random(), 1, 0, 5, 15);
      loadingTimeoutId = setTimeout(() => {
        setLoadingProgress(Math.min(loadingProgress + addToProgress, 100));
      }, timeoutMs);
    } else {
      setLoadingProgress(0);
    }
    return () => {
      if (loadingTimeoutId) {
        clearTimeout(loadingTimeoutId);
      }
    }
  }, [loadingProgress, props.isLoading]);

  const rows = props.rows?.map(row => {
    if (React.isValidElement(row)) {
      return row;
    }
    const rowObject = row as ITableRow;
    const className = rowObject.clickable === false ? 'base-table--table-row' : 'base-table--table-row clickable';
    return (
      <Table.Tr aria-disabled={!rowObject.clickable} className={className} key={rowObject.id} onMouseEnter={() => onRowMouseover(rowObject.id)} onClick={() => onTableRowClicked(rowObject.id)}>
        {props.columnNames.map((columnDefinition, idx, arr) => {
          const prop = rowObject[columnDefinition.key];
          if (!(columnDefinition.key in rowObject)) {
            // TODO: only log this in dev mode
            console.warn(`Warning: no ${columnDefinition.key} property found in row with keys ${JSON.stringify(Object.keys(rowObject))}`);
          }

          return (
            <Table.Td fz={idx === 0 ? "12px" : "10px"} key={columnDefinition.key} onClick={() => onTableCellClicked(rowObject, columnDefinition.key)} ta={getTextAlignFromCellIdx(idx, arr.length)} className="base-table--cell-container">
              {prop || ''}
            </Table.Td>
          );
        })}
      </Table.Tr>
    );
  });

  return (
    <div className="base-table--container">
      {props.title && <Title order={4}>{props.title}</Title>}
      {props.isLoading && <Progress transitionDuration={100} radius="xs" size="xs" value={loadingProgress} />}
      <Table className="base-table--table-container">
        <Table.Thead className="base-table--table-header-container">
          <Table.Tr>
            {props.columnNames.map((col, idx, arr) => (
              <Table.Th key={col.key} ta={getTextAlignFromCellIdx(idx, arr.length)} c="var(--color-blue-2)" fw="600" fz="12px" w={col.width}>
                {col.displayValue}
              </Table.Th>
            ))}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {rows}
        </Table.Tbody>
      </Table>
      {!props.isLoading && props.rows.length === 0 &&
        <NoDataMessage noDataMessage={props.noDataMessage} />
      }
      {props.isLoading && props.rows.length === 0 &&
        <LoadingDataMessage loadingMessage={props.loadingMessage} />
      }
      <div className="base-table--footer-container">
        {props.totalItems !== undefined && <Text pos="absolute" left={0} c="var(--color-grey-4)" fz="12px">{props.totalItems.toLocaleString()} Total rows</Text>}
        <Pagination.Root
          className="base-table--pagination-container"
          color="rgb(var(--singularity-green-rgb))"
          total={props.totalPages}
          value={props.currentPage}
          onChange={props.onPageChange}
          ta="center"
        >
          <Group gap={5}>
            {props.totalPages !== 0 && <Pagination.Previous onMouseEnter={() => onPageMouseover(props.currentPage - 1)} />}
            {pagination.range.map((page, index) => <CustomPaginationItem key={`${page}${index}`} onClick={props.onPageChange} active={pagination.active === page} page={page} index={index} onMouseEnter={() => page !== 'dots' && onPageMouseover(page)} />)}
            {props.totalPages !== 0 && <Pagination.Next onMouseEnter={() => onPageMouseover(props.currentPage + 1)} />}
          </Group>
        </Pagination.Root>
      </div>
    </div>
  );
};

export default BaseTable;