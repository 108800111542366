import { Group, Select, Skeleton, Stack, Text } from '@mantine/core';
import Highcharts from 'highcharts';
import { sum } from 'ramda';
import { ReactNode, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useFetchEmissionsQuery, useFetchGenerationQuery } from 'amp/api/generators';
import { useGenerator } from 'amp/store/generators/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import BaseChart from 'shared/components/Chart/baseChart';
import BasePaper from 'shared/components/Paper/basePaper';
import { getMeasurementPreference } from 'shared/store/user/selectors';
import { IEmissionsFromGenerationData } from 'shared/types/aggregatedEvents';
import { IAssetEvent, IUtilityGenerationData } from 'shared/types/assetEvents';
import { MeasurementDisplayPreference } from 'shared/types/user';
import { numberToSiFormat } from 'shared/utils/strings';
import { useAppSelector } from 'store';

// Uses logic stated in the GRETA README for how to calculate biogenic / nonbiogenic co2e
// https://github.com/singularity-energy/greta?tab=readme-ov-file#calculating-biogenic-and-nonbiogenic-co2-emissions
const getPollutantMassFromEvent = (pollutant: string, emissionsData: IEmissionsFromGenerationData) => {
  switch (pollutant) {
    case 'co2e':
      return emissionsData.sum_co2e_mass_lb;
    case 'ch4':
      return emissionsData.sum_ch4_mass_lb;
    case 'n2o':
      return emissionsData.sum_n2o_mass_lb;
    // TODO: biogenic and nonbiogenic info not in data, add in once they're in events
    // case 'biogenic':
    //   return 0;
    // case 'nonbiogenic':
    //   return 0;
    default:
      return 0;
  }
}

const getPollutantRateFromEvent = (pollutant: string, emissionsData: IEmissionsFromGenerationData) => {
  switch (pollutant) {
    case 'co2e':
      return emissionsData.mean_co2e_rate_lb_per_mwh;
    case 'ch4':
      return emissionsData.mean_ch4_rate_lb_per_mwh;
    case 'n2o':
      return emissionsData.mean_n2o_rate_lb_per_mwh;
    // TODO: biogenic and nonbiogenic info not in data, add in once they're in events
    // case 'biogenic':
    //   return 0;
    // case 'nonbiogenic':
    //   return 0;
    default:
      return 0;
  }
}

const pollutantOptions = {
  'co2e': `Carbon dioxide equivalent`,
  'n2o': 'Nitrous oxide',
  'ch4': 'Methane',
  // TODO: biogenic and nonbiogenic info not in data, add in once they're in events
  // 'biogenic': `Biogenic carbon dioxide`,
  // 'nonbiogenic': `Non-Biogenic carbon dioxide`,
}

const pollutantLabels: Record<string, string> = {
  'co2e': 'CO<sub>2</sub>e',
  'n2o': 'N<sub>2</sub>O',
  'ch4': 'CH<sub>4</sub>',
  // TODO: biogenic and nonbiogenic info not in data, add in once they're in events
  // 'biogenic': <>Biogenic CO<sub>2</sub></>,
  // 'nonbiogenic': <>Non-Biogenic CO<sub>2</sub></>,
}

const pollutantReactNodes: Record<string, ReactNode> = {
  'co2e': <>CO<sub>2</sub>e</>,
  'n2o': <>N<sub>2</sub>O</>,
  'ch4': <>CH<sub>4</sub></>,
  // TODO: biogenic and nonbiogenic info not in data, add in once they're in events
  // 'biogenic': <>Biogenic CO<sub>2</sub></>,
  // 'nonbiogenic': <>Non-Biogenic CO<sub>2</sub></>,
}

export default function GenerationAndEmissionsCharts({
  generatorId, startDate, endDate, resolution,
}: { generatorId: string, startDate: Date, endDate: Date, resolution: string }) {
  const [params, setParams] = useSearchParams();
  const selectedPollutant = params.get('pol') || 'co2e';

  const oci = useAppSelector(getViewingOpCoId);
  const measurement = useAppSelector(getMeasurementPreference);
  const isMetric = measurement === MeasurementDisplayPreference.METRIC;
  const tonsConversion = isMetric ? 1.10231 : 1;
  const lbsConversion = isMetric ? 2.20462 : 1;

  const generatorRes = useGenerator(generatorId);
  const generator = generatorRes.data;

  const generationRes = useFetchGenerationQuery({
    startDate: startDate.toISOString(), endDate: endDate.toISOString(), generatorIds: [generatorId], resolution, customerIds: [generator?.customer_id || oci],
  }, { skip: !generatorId });
  const generation = generationRes.data?.data || [];

  const emissionsRes = useFetchEmissionsQuery({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    resolution,
    generatorIds: [generatorId],
    customerIds: [generator?.customer_id || oci],
  });

  const [pollutantTonsSeriesData, pollutantRateLbsPerMWhSeriesData] = useMemo(() => {
    if (!emissionsRes.data) return [[], []];

    const emissionsSeriesData: number[][] = [];
    const emissionRateSeriesData: number[][] = [];


    emissionsRes.data.data.forEach((event: IAssetEvent) => {
      const eventData = event.data as IEmissionsFromGenerationData;

      const pollutantMass = getPollutantMassFromEvent(selectedPollutant, eventData);
      emissionsSeriesData.push([
        new Date(event.start_date).valueOf(), (pollutantMass / 2_000) / tonsConversion
      ]);

      const pollutantRate = getPollutantRateFromEvent(selectedPollutant, eventData);
      emissionRateSeriesData.push([
        new Date(event.start_date).valueOf(), pollutantRate / lbsConversion
      ]);
    });

    return [emissionsSeriesData, emissionRateSeriesData];
  }, [emissionsRes.data, selectedPollutant, lbsConversion, tonsConversion]);

  const onParamsChange = (params: { name: string, value: string }[]) => {
    setParams(newParams => {
      params.forEach(p => {
        newParams.set(p.name, p.value);
      });
      return newParams;
    });
  };

  const chartOptions: Highcharts.Options = {
    tooltip: {
      valueSuffix: ' MWh',
      valueDecimals: 1,
    },
    series: [
      {
        type: 'column',
        name: 'Generation',
        color: 'var(--color-green-3)',
        data: generation.map(ae => {
          const generationData = ae.data as IUtilityGenerationData;
          return [new Date(ae.start_date).getTime(), generationData.sum_generated_wh / 1_000_000];
        }),
      }
    ]
  };

  const emissionsChartOptions: Highcharts.Options = {
    series: [
      {
        name: `Total Emissions (${isMetric ? 'metric ' : ''}tons of ${pollutantLabels[selectedPollutant]})`,
        data: pollutantTonsSeriesData,
        type: 'area',
        color: '#4E566D',
      },
      {
        name: `Generated Pollutant Intensity (${isMetric ? 'kgs' : 'lbs'} ${pollutantLabels[selectedPollutant]}/MWh)`,
        data: pollutantRateLbsPerMWhSeriesData,
        type: 'line',
        color: '#7FAAD1',
        yAxis: 1,
      }
    ],
    legend: {
      enabled: true,
      useHTML: true,
    },
    tooltip: {
      valueDecimals: 2,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgba(180, 180, 180, 0.5)'], // start
            [1, 'rgba(180, 180, 180, 0)'] // end
          ]
        },
      },
    },
    yAxis: [
      {
        title: {
          text: `<span style="color: #4E566D">\u25CF</span> ${isMetric ? 'metric ' : ''}tons of ${pollutantLabels[selectedPollutant]}`,
          useHTML: true,
        },
        min: 0,
      },
      {
        opposite: true,
        title: {
          text: `<span style="color: #7FAAD1">\u25CF</span> ${pollutantLabels[selectedPollutant]} ${isMetric ? 'kgs' : 'lbs'}/MWh`,
          useHTML: true,
        },
        min: 0,
      }
    ]
  };

  const asGenerationData = generation.map(ae => ae.data as IUtilityGenerationData);
  const totalGenerationMWh = sum(asGenerationData.map(aeData => aeData.sum_generated_wh)) / 1_000_000.0;
  const totalEmissionsTons = sum(pollutantTonsSeriesData.map(([x, y]) => y)) / tonsConversion;
  const avgEmissionsLbsPerMWh = ((totalEmissionsTons * 2000) / (totalGenerationMWh || 1)) / lbsConversion;
  const formattedTotalGen = numberToSiFormat(totalGenerationMWh * 1_000_000);
  const numPollutantFigs = selectedPollutant === 'co2e' ? 0 : 3;
  return (
    <Stack gap={16}>
      <BasePaper titleContent="Energy Generation">
        <Group p="16px" pt="0px">
          <Stack gap={4}>
            <Skeleton visible={generationRes.isLoading} width="fit-content">
              <Group gap="xs" align="baseline">
                <Text fz={32} fw={700}>{formattedTotalGen.value}</Text>
                <Text c="var(--color-grey-4)" fz={20}>{formattedTotalGen.unitPrefix}Wh</Text>
              </Group>
            </Skeleton>
            <Text c="var(--color-grey-4)" fz={12}>Total Generation</Text>
          </Stack>
        </Group>
        <BaseChart yAxisTitle="MWh Generated" overrideOptions={chartOptions} />
      </BasePaper>

      <BasePaper titleContent={
        <Group w="100%" justify="space-between" align="flex-start">
          <Text fz={14} fw={600} c="var(--color-blue-2)">Emissions</Text>
          <Select
            fw={400}
            miw={250}
            data={Object.entries(pollutantOptions).map(([key, label]) => ({ value: key, label }))}
            value={selectedPollutant}
            onChange={val => onParamsChange([{ name: 'pol', value: val || '' }])}
            allowDeselect={false}
          // label="Select pollutant"
          />
        </Group>
      }>
        <Group p={0} gap={40}>
          <Stack gap={4}>
            <Skeleton visible={emissionsRes.isLoading} width="fit-content">
              <Group gap="xs" align="baseline">
                <Text fz={32} fw={700}>
                  {totalEmissionsTons.toLocaleString('en-US', { minimumFractionDigits: numPollutantFigs, maximumFractionDigits: numPollutantFigs })}
                </Text>
                <Text c="var(--color-grey-4)" fz={20}>{isMetric && 'm'}Tons {pollutantReactNodes[selectedPollutant]}</Text>
              </Group>
            </Skeleton>
            <Text c="var(--color-grey-4)" fz={12}>Total Emissions</Text>
          </Stack>
          <Stack gap={4}>
            <Skeleton visible={emissionsRes.isLoading} width="fit-content">
              <Group gap="xs" align="baseline">
                <Text fz={32} fw={700}>
                  {isNaN(avgEmissionsLbsPerMWh) ? 'N/A' : avgEmissionsLbsPerMWh.toLocaleString('en-US', { minimumFractionDigits: numPollutantFigs, maximumFractionDigits: numPollutantFigs })}
                </Text>
                <Text c="var(--color-blue-1)" fz={20}>{isMetric ? 'kg' : 'lb'} {pollutantReactNodes[selectedPollutant]}/MWh</Text>
              </Group>
            </Skeleton>
            <Text c="var(--color-grey-4)" fz={12}>Generated Pollutant Intensity</Text>
          </Stack>
        </Group>
        <BaseChart overrideOptions={emissionsChartOptions} />
      </BasePaper>
    </Stack>
  );
}