
import { Avatar, Button, Group, Modal, Pill, Stack, Text, Tooltip } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { IconCalendar, IconTimeline, IconUser, IconX } from '@tabler/icons-react';
import { useFetchAuditLogsPageQuery } from 'amp/api/auditLogs';
import { useProgram } from 'amp/store/programs/hooks';
import { useBulkFetchUsersQuery } from 'shared/api/users';
import BasePaper from 'shared/components/Paper/basePaper';
import BaseTable from 'shared/components/Table/baseTable';
import { getUserById } from 'shared/store/user/selectors';
import { receiveUsers } from 'shared/store/user/slice';
import { AuditLogSubjectType, auditLogSubjectTypeToDisplayName, IAuditLog } from 'shared/types/auditLog';
import { timestampToLongDate, timestampToNumericDate } from 'shared/utils/dates';
import { snakeToTitle } from 'shared/utils/strings';
import { tracker, TrackEventNames } from 'shared/utils/tracker';
import { useAppDispatch, useAppSelector } from 'store';
import './style.css';

const auditLogTableColumns = [
  { displayValue: 'Description', key: 'description' },
  { displayValue: '', key: 'seeDetails' },
  { displayValue: 'Modified By', key: 'actor' },
  { displayValue: 'Date modified', key: 'modifiedAt' },
];

const auditLogToDescription = (auditLog: IAuditLog) => {
  // takes an audit log and returns a description string based on the type of action and
  // the data that has been changed on the subject

  const subject = auditLogSubjectTypeToDisplayName(auditLog.subject_type);

  if (auditLog.action === 'create') {
    return `Created the initial ${subject}`;
  } else if (auditLog.action === 'update') {
    const changes = auditLog.data.changes || {};
    const updatedFields = Object.keys(changes as Record<string, unknown>);
    const firstField = updatedFields[0];
    const firstFieldName = ['data', 'meta'].includes(firstField) ? 'configuration' : firstField;
    if (updatedFields.length === 1) {
      return `Updated the ${firstFieldName} of the ${subject}`;
    } else if (updatedFields.length > 1) {
      return `Updated the ${firstFieldName} (and more) of the ${subject}`;
    } else {
      return `Updated the ${subject}`;
    }
  } else {
    // deleted
    return `Deleted the ${subject}`;
  }
};

const SystemBadge = () => <Pill>SYSTEM</Pill>;
const AdminBadge = () => <Pill>Singularity team</Pill>;

const allowedTypes = ['string', 'number', 'boolean'];

const AuditLogActor = ({ actorId, isLoading }: { actorId: string, isLoading?: boolean }) => {
  const [actorType, userId] = actorId.split(':');
  const user = useAppSelector(s => getUserById(s, userId));

  if (actorType === 'user') {
    if (isLoading) {
      return <em>Loading user...</em>;
    } else if (!user) {
      return <em>Unknown user</em>;
    } else {
      return <Group gap={8} justify="center">
        <Avatar color="white" bg="black" size={24}>{user.name.split(' ').map(nameSeg => nameSeg.slice(0, 1).toLocaleUpperCase())}</Avatar>
        <Text c="var(--color-blue-2)" fz={14}>{user.name || user.email}</Text>
      </Group>
    }

  } else if (actorType === 'admin') {
    return <AdminBadge />;
  } else {
    return <SystemBadge />;
  }
};

// A simple update record showing a change from and to.
const FieldChangeRecord = ({ c }: { c: { key: string, change: { from: any, to: any } } }) => {
  return (
    <Stack align="flex-start" gap={8}>
      <Text fz={18} fw={600} c="var(--color-blue-2)">{snakeToTitle(c.key)}: </Text>
      <Group gap={8} pl={40}>
        <Text fz={16} fw={600} c="var(--color-blue-2)">From:</Text>
        <Text c="var(--color-blue-2)">{c.change?.from || 'unknown'}</Text>
      </Group>
      <Group gap={8} pl={40}>
        <Text fz={16} fw={600} c="var(--color-blue-2)">To:</Text>
        <Text c="var(--color-blue-2)">{c.change?.to || 'unknown'}</Text>
      </Group>
    </Stack>
  )
}

const AuditLogModal = ({ isOpen, onClose, auditLog }: { isOpen: boolean, onClose: VoidFunction, auditLog: IAuditLog | null }) => {
  const [fieldChanges, dataChanges] = useMemo(() => {
    const fieldLogs: { key: string, change: { from: any, to: any } }[] = [];
    const dataLogs: { key: string, change: { from: any, to: any } }[] = [];
    Object.entries(auditLog?.data?.changes || {}).forEach(([key, change]) => {
      if (key === 'data') {
        // Some audit logs have JSON data some have JSON data as a string...
        let jsonFrom = change.from;
        let jsonTo = change.to;
        if (typeof change.from === 'string' && typeof change.to === 'string') {
          try {
            jsonFrom = JSON.parse(change.from);
            jsonTo = JSON.parse(change.to);
          } catch (e) {
            console.warn('Failed to parse JSON while generating audit logs for an object')
          }
        }

        // loop over the records in the "data" field, special logic for many cases
        Object.keys(jsonFrom).forEach((innerKey) => {
          if (innerKey === 'program_config') {
            dataLogs.push({ key: innerKey, change: { from: jsonFrom[innerKey], to: jsonTo[innerKey] } });
          } else if (allowedTypes.includes(typeof jsonFrom[innerKey]) && allowedTypes.includes(typeof jsonTo[innerKey])) {
            // if the from and to values for a record in the data object are primitives handle the simple case
            if (jsonFrom[innerKey] !== jsonTo[innerKey]) {
              fieldLogs.push({ key: innerKey, change: { from: jsonFrom[innerKey], to: jsonTo[innerKey] } });
            }
          }
        });
      } else {
        // fields not found under the "data" category should be top level fields
        fieldLogs.push({ key, change });
      }
    });
    return [fieldLogs, dataLogs];
  }, [auditLog]);

  return (
    <Modal opened={isOpen} onClose={onClose} withCloseButton={false} size="lg">
      <Stack p={40}>
        <Group justify="space-between">
          <Text fz={32} fw={700}>Details</Text>
          <IconX onClick={onClose} size={40} className="program-history--modal-close-icon" />
        </Group>
        {auditLog && <>
          <Text fz={18} c="var(--color-blue-2)">
            {auditLogToDescription(auditLog)}
          </Text>
          <Stack gap={8}>
            <Group>
              <IconTimeline color="var(--color-blue-2)" />
              <Text fz={14} c="var(--color-blue-2)">
                Log Type: {auditLogSubjectTypeToDisplayName(auditLog.subject_type)}
              </Text>
            </Group>
            <Group>
              <IconUser color="var(--color-blue-2)" />
              <Text fz={14} c="var(--color-blue-2)">Modified by:</Text>
              <AuditLogActor actorId={auditLog.actor_id} />
            </Group>
            <Group>
              <IconCalendar color="var(--color-blue-2)" />
              <Text fz={14} c="var(--color-blue-2)">Date: {timestampToLongDate(auditLog.timestamp)}</Text>
            </Group>
          </Stack>
          {fieldChanges.map(c => <FieldChangeRecord c={c} key={c.key} />)}
          {dataChanges.map(c => <div key={c.key}>
            {Object.keys(c.change.from || {}).filter(key => c.change.to?.[key] !== c.change.from?.[key]).map(key => <div key={key}>
              <FieldChangeRecord key={key} c={{ key, change: { from: c.change.from?.[key], to: c.change.to?.[key] } }} />
            </div>)}
          </div>)}
        </>}
        {!auditLog && <Text>No information available about this record</Text>}
      </Stack>
    </Modal>
  )
}

const ProgramHistoryView = () => {
  const { programId = '' } = useParams<{ programId: string }>();
  const [params, setParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [viewingLog, setViewingLog] = useState<IAuditLog | null>(null);
  const programPage = isNaN(parseInt(params.get('pp') || '1')) ? 1 : parseInt(params.get('pp') || '1');
  const assignmentsPage = isNaN(parseInt(params.get('ap') || '1')) ? 1 : parseInt(params.get('ap') || '1');
  const subscriptionsPage = isNaN(parseInt(params.get('sp') || '1')) ? 1 : parseInt(params.get('sp') || '1');
  const programRes = useProgram(programId);
  const customerId = programRes.data.program?.customer_id;

  useEffect(() => {
    if (programRes.data?.program) {
      tracker.track(TrackEventNames.VPH, {programId, programName: programRes.data.program.name})
    }
  }, [programId, programRes]);

  const progLogsRes = useFetchAuditLogsPageQuery(
    { page: programPage, perPage: 5, customerId: customerId, subjectType: AuditLogSubjectType.PROGRAM, subjectIds: [programId] },
    { skip: !customerId }
  );
  const programLogs = progLogsRes.data?.data || [];

  const assignments = programRes.data?.assignments || [];
  const assignmentIds = assignments.map(a => a.id);
  const assignmentLogsRequest = useFetchAuditLogsPageQuery(
    { page: assignmentsPage, perPage: 5, customerId: customerId, subjectType: AuditLogSubjectType.PROGRAM_ASSIGNMENT, subjectIds: assignmentIds },
    { skip: !programRes.data || !assignments.length }
  );
  const assignmentLogs = assignmentLogsRequest.data?.data || [];

  const subscriptions = programRes.data?.subscriptions || [];
  const subscriptionIds = subscriptions.map(s => s.id);
  const subscriptionLogsRequest = useFetchAuditLogsPageQuery(
    { page: subscriptionsPage, perPage: 5, customerId: customerId, subjectType: AuditLogSubjectType.PROGRAM_SUBSCRIPTION, subjectIds: subscriptionIds },
    { skip: !programRes.data || !subscriptions.length }
  );
  const subscriptionLogs = subscriptionLogsRequest.data?.data || [];

  const allLogs = [...programLogs, ...assignmentLogs, ...subscriptionLogs];
  const userIds = allLogs.filter(log => log.actor_id.startsWith('user')).map(log => log.actor_id.split(':')[1]) || [];
  const usersRes = useBulkFetchUsersQuery(userIds, { skip: !userIds.length });

  useEffect(() => {
    if (usersRes.data) {
      dispatch(receiveUsers(usersRes.data.data));
    }
  }, [usersRes, dispatch]);

  const onParamChange = (paramName: string, paramValue: string) => {
    setParams(newParams => {
      newParams.set(paramName, paramValue);
      return newParams;
    })
  };

  const onLogRowClicked = (logId: string) => {
    const log = allLogs.find(l => l.id === logId);
    setViewingLog(log || null);
  }

  const programIsLoading = programRes.isLoading || programRes.isFetching;
  const programLogsAreLoading = progLogsRes.isLoading || progLogsRes.isFetching || programIsLoading;
  const assignmentLogsAreLoading = assignmentLogsRequest.isLoading || assignmentLogsRequest.isFetching || programIsLoading;
  const subscriptionLogsAreLoading = subscriptionLogsRequest.isLoading || subscriptionLogsRequest.isFetching || programIsLoading;
  return (
    <div className="program-history--scroll-container">
      <Stack m={0} p={0} gap={16}>
        <BasePaper titleContent="Program history">
          <BaseTable
            isLoading={programLogsAreLoading}
            loadingMessage="Loading program history..."
            columnNames={auditLogTableColumns}
            rows={programLogs.map(auditLog => ({
              id: auditLog.id,
              description: auditLogToDescription(auditLog),
              modifiedAt: <Tooltip label={timestampToLongDate(auditLog.timestamp)} transitionProps={{ transition: 'fade', duration: 200 }}>
                <div>{timestampToNumericDate(auditLog.timestamp)}</div>
              </Tooltip>,
              actor: <AuditLogActor actorId={auditLog.actor_id} isLoading={usersRes.isFetching} />,
              seeDetails: <Button size='xs' className="program-history-row--see-details">View details</Button>,
            })) || []}
            onTableRowClicked={onLogRowClicked}
            currentPage={programPage}
            totalPages={programLogs.length !== 5 ? programPage : programPage + 1}
            onPageChange={e => onParamChange('pp', e.toString())}
          />
        </BasePaper>

        <BasePaper titleContent="Subscription history">
          <BaseTable
            isLoading={subscriptionLogsAreLoading}
            loadingMessage="Loading subscription history..."
            noDataMessage="No existing subscriptions for this program"
            columnNames={auditLogTableColumns}
            rows={subscriptionLogs.map(auditLog => ({
              id: auditLog.id,
              description: auditLogToDescription(auditLog),
              modifiedAt: <Tooltip label={timestampToLongDate(auditLog.timestamp)} transitionProps={{ transition: 'fade', duration: 200 }}>
                <div>{timestampToNumericDate(auditLog.timestamp)}</div>
              </Tooltip>,
              actor: <AuditLogActor actorId={auditLog.actor_id} isLoading={usersRes.isFetching} />,
              seeDetails: <Button size='xs' className="program-history-row--see-details">View details</Button>,
            })) || []}
            onTableRowClicked={onLogRowClicked}
            currentPage={subscriptionsPage}
            totalPages={subscriptionLogs.length !== 5 ? subscriptionsPage : subscriptionsPage + 1}
            onPageChange={e => onParamChange('sp', e.toString())}
          />
        </BasePaper>

        <BasePaper titleContent="Assignment history">
          <BaseTable
            isLoading={assignmentLogsAreLoading}
            loadingMessage="Loading assignment history..."
            noDataMessage="No existing assignments for this program"
            columnNames={auditLogTableColumns}
            rows={assignmentLogs.map(auditLog => ({
              id: auditLog.id,
              description: auditLogToDescription(auditLog),
              modifiedAt: <Tooltip label={timestampToLongDate(auditLog.timestamp)} transitionProps={{ transition: 'fade', duration: 200 }}>
                <div>{timestampToNumericDate(auditLog.timestamp)}</div>
              </Tooltip>,
              actor: <AuditLogActor actorId={auditLog.actor_id} isLoading={usersRes.isFetching} />,
              seeDetails: <Button size='xs' className="program-history-row--see-details">View details</Button>,
            })) || []}
            onTableRowClicked={onLogRowClicked}
            currentPage={assignmentsPage}
            totalPages={assignmentLogs.length !== 5 ? assignmentsPage : assignmentsPage + 1}
            onPageChange={e => onParamChange('ap', e.toString())}
          />
        </BasePaper>
      </Stack>
      <AuditLogModal isOpen={viewingLog !== null} onClose={() => setViewingLog(null)} auditLog={viewingLog} />
    </div>
  );
};

export default ProgramHistoryView;